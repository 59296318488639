.overlaying-icons-wrapper {
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  .counter {
    background: #e5e8e3 !important;

    .icon-name {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .icon-wrappers {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 3px solid #fff;
    display: flex;
    align-items: center;
    background: #bfff0b;
    justify-content: center;
    margin: 0 -5px;
  }

  .icon-img {
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .icon-name {
    padding: 0;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
  }
}
