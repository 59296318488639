.nav-item-wrap {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &.closing {
    opacity: 0.9;
    transform: scale(0.98);
  }

  .isMenu-active {
    font-weight: 600 !important;
    color: #292524 !important;
    display: flex !important;
    appearance: none !important;
    text-decoration: none !important;
    padding: 6px 8px !important;
    border-radius: 6px;
    background: #ececeb;
    margin-top: 4px;
  }

  .isMenu-not-active {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #79716b;
    flex-direction: row;
    margin-top: 4px;
    padding: 8px 12px !important;
    text-decoration: none;
    position: relative;

    &:hover {
      border-radius: 6px;
      background: #ececeb;
    }

    @media (min-width: 768px) {
      font-size: 0.85rem /* 14px */;
      line-height: 1.25rem /* 20px */;
    }
  }

  list-style: none;
  appearance: none;

  .pl-14 {
    padding-left: 2rem !important;
  }

  .ml-40 {
    margin-left: 40px !important;
  }

  .get-started-percentage {
    border-radius: 16px;
    min-width: 2rem;
    border: 1px solid #dbdbdb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 4px;
    height: 22px;
    color: #57534e;
    background: linear-gradient(180deg, #ebebeb 0%, #dbdbdb 100%);
    font-size: 12px;
    font-weight: 500;
    transition-duration: 300ms;

    &.is-active {
      box-shadow: 0px 1px 1px 0px #00000014;
      border: 1px solid #fafafa14;
      background: linear-gradient(180deg, #ffffff 0%, #f7f7f6 100%);
    }

    &.is-pulse {
      animation: pulse 1.5s ease-out 4;
      position: relative;

      .floating-ball {
        position: absolute;
        bottom: 6px;
        left: 78%;
        width: 6px;
        height: 6px;
        background: linear-gradient(180deg, #ff003b 0%, #f00105 100%);
        border-radius: 50%;
        opacity: 0;
        animation: float-ball 1s ease-out 1 forwards;
        animation-delay: 5.5s;
      }
    }
  }
}

.user__menu-name {
  padding: 10px 12px;

  &.company {
    max-height: 168px;
    overflow: auto;
    cursor: pointer;

    &.last-item {
      border-bottom: 1px solid #e7e5e4;
    }

    &:hover {
      background: whitesmoke;
    }
  }
}

.inner-dialog-holder {
  overflow: auto;
  max-height: 168px;
}

.plan-holder {
  height: 110px;
  background-color: #ecfdf3;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;

  .plan {
    color: #1c1917;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .duration {
    color: #57534e;
    font-size: 0.75rem;
    margin-left: auto;
  }

  .upgrade {
    background-color: white;
    border: 1px solid #d7d3d0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    color: #57534e;
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 8px;
    animation-duration: 300ms;

    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 0.6;
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.role-pill {
  color: #44403c;
  font-size: 0.75rem;
  font-weight: 500;
  height: 16px;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d7d3d0;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #1c19170d;
  margin-left: 10px;
  margin-top: 2px;
}

.c-gap-10 {
  gap: 10px;
}

.nav-item-list {
  h4 {
    display: flex;
    flex-direction: column;
    width: 100%;
    // align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 0 8px;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    font-weight: 500;
    height: fit-content;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.reduce-menu-height {
  @media (min-width: 768px) {
    height: calc(100vh - 60px) !important;
  }

  .desktop-menu-wrap {
    height: calc(100vh - 60px) !important;
  }
}

.header-section {
  display: flex;
  // height: fit-content !important;
  min-width: 280px;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  background: #fafaf9;

  &.blurred {
    filter: blur(3px);
    pointer-events: none;
  }

  @media (min-width: 768px) {
    border-right: 1px solid #eeedec;
    height: 100vh;
  }

  .desktop-menu-wrap {
    display: none;
    height: 100%;
    flex-direction: column;
    transition-duration: 500ms;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

    @media (min-width: 768px) {
      display: flex;
    }

    .logo-link {
      display: none;
      width: 100%;
      align-items: start;
      gap: 8px;
      // padding-top: 24px;
      // padding-left: 24px;

      @media (min-width: 768px) {
        display: flex;
      }
    }

    .desktop-menu {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 0px;
      white-space: nowrap;
      padding: 0 14px 0 10px;
      padding-top: 40px;

      @media (min-width: 768px) {
        padding-top: 0;
      }

      .desktop-menu-list {
        margin: 0px;
        margin-top: 1.5rem;
        list-style-type: none;
        padding: 0px;
        padding-bottom: 60px;
        width: 100%;
        // overflow-y: scroll;
        // max-height: 50%;
      }
    }
  }

  .mobile-menu-nav {
    display: inline-grid;
    justify-content: flex-end;
    padding: 16px;
    @media screen and (max-width: 480px) {
      position: absolute;
      right: 0px;
    }
    @media screen and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 0) {
      position: initial;
    }
    @media (min-width: 768px) {
      display: none;
    }

    .menu-nav-section {
      left: 0;
      margin: auto 0;
      display: flex;
      width: 100%;
      transform: translateX(8px);
      align-items: center;
      justify-content: space-between;
    }
  }

  .mobile-menu-wrap {
    position: fixed;
    inset: 0;
    z-index: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    background-color: rgb(0 0 0 / 0.6);
  }

  .mobile-menu-content {
    position: absolute;
    inset: 0;
    z-index: 50;
    margin-left: auto;
    display: block;
    min-height: 100vh;
    padding-bottom: 60px;
    max-width: 320px;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    background: #fff;

    @media (min-width: 1024px) {
      display: none;
    }

    .logo-wrap {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 16px 20px;
    }
  }

  .mobile-menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 4px;
    white-space: nowrap;
    padding-top: 16px;

    @media (min-width: 768px) {
      padding-top: 24px;
    }

    .mobile-menu-list {
      margin: 0px;
      display: block;
      overflow-y: scroll;
      list-style-type: none;
      padding: 0px;
      padding-bottom: 80px;
      width: 100%;
    }
  }

  .logout-section {
    display: flex;

    padding: 14px 0px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-top: 1px solid #e8e8e7;
    justify-content: space-between;
  }
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(1rem * 0);
  margin-left: calc(1rem * calc(1 - 0));
}

.translateX-0 {
  transform: translateX(0);
}

.translateX-100 {
  transform: translateX(100%);
}

.mini-tab-bar {
  margin: 4px 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  position: relative;
  padding-left: 20px;
  isolation: isolate;

  &::before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #e7e5e4;
    border-radius: 1px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 9px;
    top: 10px;
    width: 4px;
    height: 12px;
    background-color: #c47f03;
    transition: transform 300ms ease;
    transform: translateY(calc(var(--active) * 44px));
    border-radius: 2px;
  }
}

.tab-item {
  padding: 6px 8px;
  height: 32px;
  cursor: pointer;
  color: #79716b;
  font-size: 0.875rem;
  width: 100%;
  font-weight: 500;
  box-sizing: border-box;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 12px;

  &.active {
    color: #1c1917;
    border-radius: 6px;
    background-color: #ececeb;

    &::before {
      content: '';
      position: absolute;
      left: -11px;
      top: 50%;
      transform: translateY(-50%);
      height: 12px;
      width: 4px;
      background-color: transparent;
      border-radius: 2px;
      z-index: 1;
    }
  }

  &.isLast {
    margin-bottom: 0;
  }
}

/* Managing the active tab position */
.mini-tab-bar:has(.tab-item:nth-child(1).active) {
  --active: 0;
}

.mini-tab-bar:has(.tab-item:nth-child(2).active) {
  --active: 1;
}

.mini-tab-bar:has(.tab-item:nth-child(3).active) {
  --active: 2;
}

.mini-tab-bar:has(.tab-item:nth-child(4).active) {
  --active: 3;
}

.mini-tab-bar:has(.tab-item:nth-child(5).active) {
  --active: 4;
}

// .mini-tab-bar {
//   opacity: 0;
//   transform: translateY(-100%);
//   transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
//   will-change: opacity, transform;
// }

// .mini-tab-bar.open {
//   animation: slideInFromTop 0.5s ease-in-out forwards;
// }

// .mini-tab-bar.close {
//   animation: slideOutToTop 0.5s ease-in-out forwards;
// }

// .mini-tab-bar[data-closing-index] {
//   animation: slideOutToTop 0.5s ease-in-out forwards;
// }
