.transaction-wrapper {
  p {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
    margin: 0;
    font-weight: 400;
  }
  h3 {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
  }
}
.transaction-icon {
  background: #f2f4f1;
  border-radius: 30px;
  padding: 3px 10px;
  cursor: pointer;
}
.removeIconBg {
  background: transparent;
}

.assign-budget {
  background-color: #fff;
  border: none;
  color: #d28b28;
  width: 100%;
  height: 10px;
  font-size: 15px;
  cursor: pointer;
}
.assign-budget:hover {
  color: #586068;
}

.change-budget {
  display: flex;
  margin-right: 9px;
  margin-left: auto;
  margin-top: -14px;
}

.remove-width {
  width: 0;
  margin-left: auto;
  margin-right: 18px;
}

.reimbursement-footer {
  position: fixed;
  width: 500px;
  padding: 10px 40px 20px 40px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  background-color: #fff;
}

.reimbursements-wrapper {
  .information-wrapper {
    padding-bottom: 20px;
  }
  .remove-spacing {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .decline-request-container {
    padding: 24px 32px 125px 32px;
  }
}

.request-more-wrapper {
  .title-padding {
    padding: 25px 32px !important;
  }

  .details-wrap {
    background: #fff;

    .details {
      padding: 0px 32px;
      h6 {
        color: #79716b;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px;
      }
      p {
        color: #1c1917;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
