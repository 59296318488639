.slider-container {
  width: 100%;
  max-width: 100%;
  height: 550px;
  padding: 20px;
  border-radius: 10px;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.mySwiper .swiper-slide img {
  inset: 0 !important;
  margin: auto !important;
  overflow: hidden !important;
  width: 500px !important;
  height: 352px !important;
  object-fit: contain !important;
  padding-bottom: 20px !important;
}

@media (max-width: 1168px) {
  .mySwiper .swiper-slide img {
    width: 100% !important;
    height: 100% !important;
  }
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.slide-content {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.slide-content h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #1c1917;
}

.slide-content p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #79716b;
  text-align: center;
  text-wrap: wrap;
  max-width: 480px;
}

.swiper-pagination {
  bottom: 20px;
}

.swiper-pagination-bullet {
  background: #333;
  opacity: 0.6;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}
