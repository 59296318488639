@import '../../scss/typography.scss';
@import '../../scss/abstracts/variables.scss';
@import '../../scss/base/colors.scss';

.card-modal-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  .modal-pagination {
    color: #898989;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      margin: 0 3px;
    }
    .active-text {
      font-weight: 600;
      color: #000;
    }
  }
}

.frequency-label {
  h3 {
    font-size: 15px;
  }
  p {
    font-size: 12px;
    line-height: 10px;
  }
}

.edit-policy-notice {
  font-size: 14px;
  color: #dc6803;
}

.custom-dialog {
  &.pin-dialog {
    .modal-content {
      width: unset;
    }
  }
  .modal-content {
    background: transparent !important;
    border: none !important;
  }
}

.policy-detail-wrapper {
  background: #f2f4f1;
  border-radius: 12px;
  padding: 24px;
  .policy-detail-text {
    h3 {
      font-size: 18px;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
    }
  }
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(1rem * calc(1 - 0));
  margin-bottom: calc(1rem * 0);
}

.policy-type-button {
  display: flex;
  gap: 8px;
  align-items: center;

  padding: 8px 14px 8px 14px;
  background-color: transparent;
  border: 1px solid #d9ddd5;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
}

.policy-type-actionbtn {
  display: flex;
  gap: 22px;
  align-items: center;
  span,
  button {
    font-size: 14px;
    cursor: pointer;
  }
  button {
    padding: 8px 14px 8px 14px;
    border: 1px solid #d9ddd5;
    background-color: transparent;
    border-radius: 8px;
  }
}

.receipt-policy-wrapper {
  h6,
  p {
    font-size: $text-sm;
  }
  h6 {
    color: $grey-700;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  p {
    font-size: $text-sm;
    color: $grey-600;
  }
}

.policy-page {
  .subText {
    font-size: 14px;
    color: #79716b;
  }
}

.progress,
.progress-stacked {
  height: 8px !important;
  border-radius: 8px !important;
}
.progress-bar {
  background-color: #12b76a !important;
}

.policy-form {
  background-color: #fff !important;
  border-radius: 12px;
  // max-width: 650px;
}

.policy-actions.actions-dialog {
  min-width: 180px !important;
}

.popover {
  max-width: 350px !important;
}

.policy-rule-styles {
  h6 {
    font-size: 16px;
    font-weight: 600;
  }
  span {
    font-size: 14px;
    font-weight: 300;
    color: #586068;
  }
}

.employee-handbook-section {
  max-height: 311px;
  overflow-y: auto;
}

.policy-page-body {
  width: 70%;
  .dark-button {
    background-color: #000000 !important;
    border: 1px solid #000000 !important;
  }
  .add-button {
    min-width: 100px !important;
    width: fit-content;
  }
}

.modal-dialog-scrollable .modal-content.custom-content {
  overflow: auto;
  .asset-viewer {
    margin-top: 0;
    height: 93vh;
  }
}

.policy-table {
  .table-wrapper {
    .table-responsive {
      max-height: calc(100vh - 23rem) !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .policy-page-body {
    width: 80%;
  }
}

@media screen and (max-width: 992px) {
  .policy-page-body {
    width: 100%;
  }
}
