@import '../../scss/abstracts/variables.scss';
@import '../../scss/base/colors.scss';
@import '../../scss/typography.scss';

.notice-container {
  .border-b {
    border-bottom: 1px solid #e7e5e4;
  }
  .bg-new-info {
    background: #fefbf7;
  }
  .notice-item {
    display: flex;
    align-items: start;

    .user-initial {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      background: #039855;
      border-radius: 100%;
      color: #faffec;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }

    .notice-info {
      font-size: 15px;
      font-weight: 300;
      padding-left: 14px;

      .warning-icon {
        margin: auto;
        width: 15px;
      }

      h6 {
        color: #1c1917;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      p {
        color: #79716b;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .text-xs {
        color: #a9a29d;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }

      .reject-message {
        display: flex;
        margin-top: 14px;
        flex-direction: column;
        gap: 5px;
        label {
          font-size: 15px;
        }
        input {
          border: 1px solid #e5e5e5;
          font-size: 15px;
          padding: 15px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          font-weight: 300;
        }
        input:focus {
          outline: none;
        }
        .action-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 11px;
          gap: 8px;
          cursor: pointer;
          .notification-btn {
            width: 63px;
            height: 32px;
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .notice-btn {
    font-size: 15px;
    color: #d28b28;
    appearance: none;
    border: none;
    padding: 0;
    background: transparent;
    cursor: pointer;
  }

  .danger-button {
    @include borderStyle(full, 1px, solid, $error-300);
    background-color: transparent !important;
    @include shadow(xs);
    color: $error-700;

    &:hover {
      background-color: $error-700;
      color: white !important;
    }
  }

  .black-transparent {
    border: 1px solid $grey-300 !important;
    background: $base-white !important;
    color: $grey-700 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .button-dimension-fit-content {
    width: fit-content !important;
    min-width: 50px;
  }
}

.align-border {
  .nav-tabs {
    border-bottom: 0;
  }

  .h-100-custom {
    height: 85vh !important;
  }
}
