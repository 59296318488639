.modal-wrapper {
  position: fixed;
  top: 25px;
  right: 20px;
  display: flex;
  background: #ffffff;
  padding: 20px;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 10px;
  width: 388px;
  z-index: 999999;
  .close-icon-notification {
    position: absolute;
    top: 2px;
    right: 11px;
    cursor: pointer;
  }
  .icon-color {
    color: #586068;
  }
  .notification-title {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
  }
  .notification-sub-title {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
  }
  .actions-list {
    span {
      cursor: pointer;
      padding: 0 10px;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      color: #d28b28;
      position: relative;
      &:first-child {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        background: #586068;
        left: -1px;
        top: 45%;
        border-radius: 50%;
      }
    }
  }
}
