.nav-tabs .nav-item:first-child .new-tab {
  padding-left: 0px;
}

.new-tag {
  background: #eff8ff;
  margin-left: 8px;
  padding: 0px 10px;
  border-radius: 100px;
  color: #175cd3;
}
