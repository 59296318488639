.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.dialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  position: relative;
  width: 100%;
  height: 100%;

  .dialog-body {
    background: #ffffff;
    box-shadow: 0 12px 40px rgba(16, 24, 64, 0.24);
    border-radius: 8px;
    width: 400px;
    padding: 32px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    .cancel-icon {
      position: absolute;
      top: 32px;
      right: 32px;
      cursor: pointer;
    }
    .delete-icon {
      width: 48px;
      height: 48px;
      background: #fee4e2;
      border: 8px solid #fef3f2;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      svg {
        width: 18px;
        height: 20px;
        path {
          stroke: #d92d20;
        }
      }
    }
    h2 {
      font-size: 18px;
      line-height: 35px;
      margin: 0 0 5px;
      text-align: center;
      color: #242628;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      text-align: center;
      color: #586068;
      padding-bottom: 0.5rem;
    }
    .remove-btn {
      font-size: 1rem !important;
      background: #dc2626 !important;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06) !important;
      border-radius: 8px !important;
      height: 38px !important;
      color: #fff !important;
    }
    .cancel-btn {
      font-size: 1rem !important;
      background: transparent !important;
      box-shadow: none !important;
      color: #586068 !important;
      height: 40px !important;
      padding-bottom: 0 !important;
      border: 1px solid #e5e8e3 !important;
      box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05) !important;
      border-radius: 8px !important;
      padding-top: 0 !important;
    }
  }
}

.custom-modal_footer {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
  border-top: 1px solid #e7e5e4;

  .custom-button {
    height: 40px !important;
    padding: 10px !important;
    width: 87px;
    min-width: 100px;
    font-weight: 500;
    font-size: 14px !important;

    &.ghost-button {
      background-color: #fff !important;
      border: 1px solid #e1e4dd !important;
      box-shadow: 0 1px 2px rgba(22, 22, 21, 0.05) !important;
      color: #57534e !important;
    }

    &.primary-button {
      padding: 0 12px !important;
      height: 40px !important;
      width: max-content;
    }
  }
}
