.billing-checkout-wrapper {
  .title {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #161615;
    margin: 0;
    padding: 0;
  }
  .body {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #575855;
    margin: 0;
    padding: 0;
    margin-top: 4px;
  }
  .back {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #d28b28;
    margin-top: 48px;
    cursor: pointer;
  }
  .header {
    margin-top: 24px;
  }
  .payment-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #161615;
    margin: 0;
    padding-bottom: 24px;
  }
  .details-wrapper {
    border-top: 1px solid #e8ebe6;
    border-bottom: 1px solid #e8ebe6;
    padding: 24px 0;
    margin: 24px 0;
  }
  .order-summary {
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #414240;
    }
    .body {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #575855;
    }
    .total {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #414240;
    }
  }

  .payment-options {
    border-bottom: 1px solid #e1e4dd;
    padding-bottom: 20px;
  }
  .payment-item {
    border: 1px solid #e8ebe6;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 12px;
    cursor: pointer;
    h6 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
      color: #242628;
    }
  }
  .active {
    background: #fbf5ec;
    border: 2px solid #d28b28;
    h6 {
      color: #885a1a;
    }
  }
  .radio {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
  }
  .unchecked {
    border: 1px solid #e1e4dd;
  }
  .checked {
    background-color: #d28b28;
  }
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e8ebe6;
    width: 34px;
    height: 24px;
    box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
    border-radius: 4px;
    margin-top: 8px;
    background-color: white;
  }
  .border-right {
    border-right: 1px solid #eaecf0;
  }
  .billing-address {
    padding-right: 32px;
  }

  .billing-footer-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6d6f6b;
    a {
      color: #6d6f6b;
      font-weight: 600er;
    }
  }
  .payment-subtext {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6d6f6b;
  }
}
