.delete-modal-settlement {
  .modal-content {
    width: 400px;
    .cancel-modal-wrapper {
      padding: 24px;
      header {
        display: flex;
      }
    }
    .cancel {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #161615;
        margin: 0;
        padding: 0;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #575855;
        margin: 4px 0 0 0;
        text-align: center;
      }
      .reason-list {
        margin-top: 20px;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      .list-item {
        font-size: 14px;
        line-height: 20px;
        color: #414240;
        cursor: pointer;
      }
      .active {
        background: #fbf5ec;
        border: 2px solid #d28b28;
        h6 {
          color: #885a1a;
        }
      }
    }
    footer {
      margin-top: 32px;
      display: flex;
      flex-direction: row !important;
      gap: 12px;
      button {
        flex: 1;
        border-radius: 8px;
        padding: 10px 18px;
        font-size: 16px;
        line-height: 24px;
      }
      button:first-child {
        background: #ffffff;
        border: 1px solid #e1e4dd;
        box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
        color: #414240;
      }
      button:last-child {
        background: #d92d20;
        color: #fff;
        border: 1px solid #d92d20;
      }
    }
  }
}
