@import '../../scss/typography.scss';
@import '../../scss/base/colors.scss';
@import '../../scss/abstracts/variables.scss';

.timeline-container {
  width: 100%;
  margin: auto;
  display: block;
  position: relative;
}

.timeline {
  margin: 0;
  padding: 0;
  display: inline-block;
  li:last-child {
    border-left: 0;
    min-height: 40px;
  }
  li:before {
    position: absolute;
    left: -11px;
    top: 0px;
    content: '';
    border-radius: 500%;
    background: $grey-200;
    height: 24px;
    width: 24px;
    border: 8px solid $grey-50;
    transition: all 500ms ease-in-out;
  }
  .active:before {
    content: '';
    background: url('../../assets/icons/TimelineCheckIcon.svg') no-repeat center center; /* Link to the SVG file */
    background-size: contain;
    border: $grey-100;
    height: 24px;
    width: 24px;
  }
  .hide-dot:before {
    display: none;
  }
  .item-wrapper {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: max-content;
    margin-bottom: 8px;
    .timeline-or {
      color: $grey-500;
      font-size: 14px;
      margin: 0 8px;
      box-sizing: border-box;
    }
  }
  .item-wrapper:last-child {
    .timeline-or {
      display: none;
    }
  }
}

.timeline-item {
  list-style: none;
  margin: auto;
  min-height: 80px;
  border-left: 1px solid $grey-200;
  padding: 0 0 0px 24px;
  position: relative;
}
.hide-dot {
  padding: 0;
}

.timeline-title {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: $grey-500;
  font-weight: $font-medium;
  margin-bottom: 8px;
  .timeline-rulename {
    font-size: $text-sm;
    color: $secondary-500;
    font-weight: $font-medium;
    margin-left: 4px;
    cursor: pointer;
    text-decoration: none;
  }
}
.timeline-item-name {
  color: $grey-900;
  font-size: 14px;
  margin: 0;
  padding: 0;
  span {
    font-size: $text-xs;
    color: $grey-500;
    margin-left: 8px;
  }
}

.timeline-item-description {
  font-size: $text-sm;
  color: $grey-600;
  margin: 0;
  padding: 0;
  margin-top: 2px;
}

.timeline-details {
  gap: 8px;
  // max-width: max-content;
  box-sizing: border-box;
}

.name-wrapper {
  flex-wrap: wrap;
}
.center {
  align-items: center;
}

.self {
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.self .msg {
  order: 1;
  width: 100%;
  border-radius: 0px 6px 6px 6px;
  box-shadow: 1px 2px 0px #d4d4d4;
}
.avatars {
  margin-top: -20px !important;
}

.self .avatars:after {
  content: '';
  position: relative;
  display: inline-block;
  bottom: 4px;
  right: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  border: 6px solid #fff;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 0px 0px 0px 0px #d4d4d4;
}

.msg {
  color: #79716b;
  margin-top: -12px !important;
  background: white;
  min-width: 50px;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
}
