.chartWrapper {
  border: 1px solid #e5e8e3;
  margin-top: 40px;
  padding: 0 20px 20px;
  border-radius: 12px;

  .light {
    line {
      stroke: #e9e9e9;
    }
  }

  .relative {
    position: relative;
  }

  .center-data {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;

    span {
      background: #fff;
      padding: 8px 8px;
    }
  }

  .header-region {
    width: 55%;
  }

  .chart-header {
    display: flex;
    justify-content: space-between;

    .chartActon {
      display: flex;
      align-items: center;
      padding-top: 30px;
      padding-right: 20px;
      height: max-content;

      .chartActon-link {
        background: none;
        border: none;
        padding: 2px 12px;
        color: #586068;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:first-child {
          border-right: 1px solid #e5e8e3;
        }

        &.active {
          color: #000;
        }
      }
    }
  }
}

.balance-crest {
  border-radius: 100%;
  box-shadow: 0px -1.14px 1.14px 0px #00000033 inset, 0px 0.57px 0.57px 0px #00000014,
    0px 1.43px 2.86px 0px #0000001f;
}

.account-header__holder {
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.125rem 0 1.5rem 0;
}

.account-header {
  color: #101828;
  font-weight: 600;
  font-size: 1.25rem;
}

.total-amount-wrapper {
  padding-bottom: 1rem;
  max-height: 150px;

  .card-type-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 14px;
  }

  .card-disabled-title {
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    color: #586068;
  }

  .card-title-amount {
    font-size: 24px;
    line-height: 35px;
    font-weight: 600;
    margin: 8px 0 5px;
    display: flex;
  }
}

.transactions-wrapper {
  margin-top: 30px;

  .pt-0 {
    padding-top: 0 !important;
  }

  .subtitle {
    font-size: 16px;
    color: #586068;
    position: absolute;
    bottom: -30px;
  }
}
.subText {
  color: #586068;
}

// .rdrDefinedRangesWrapper {
//   display: none;
// }

.card-disabled-link {
  color: #d28b28;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
}

.custom-tooltip {
  padding: 12px;
  border: 1px #5860685f solid;

  .label-data {
    color: #d28b28;
    margin: 0;

    span {
      margin-right: 4px;
    }
  }

  .label {
    margin: 0 0 6px 0;
  }
}

.account-x-fund {
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
}

.min-w-fit {
  width: fit-content !important;
}
