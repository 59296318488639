.overview-card-view {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 1px 2px 0px #1c19170d;
  border: 1px solid #e8e8e7;
  max-height: 424px;

  &.cashflow {
    max-height: 714px;
  }

  .text {
    font-size: 18px;
    font-weight: 600;
    color: #212120;
  }

  .small-text {
    color: #79716b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .view-all {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #d28b28;
    font-weight: 500;
    background-color: inherit;
    border: none;
    font-size: 0.875rem;
  }
}

.cashflow-break {
  margin-top: 0.75rem;
  border-top: 1px solid #e8e8e7;

  .header {
    color: #7f7f7d;
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.card-title {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  border-bottom: 1px solid #e7e5e4;
  padding: 0 0 12px 0;

  .pill {
    border: 1px solid #e7e5e4;
    font-size: 12px;
    border-radius: 16px;
    background: #f5f5f4;
    padding: 10px 6px;
    line-height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content !important;
    width: fit-content !important;
  }
}

.card-list {
  display: flex;
  width: 100%;
  flex-direction: column;

  .border-bottom {
    border-bottom: 1px solid #e7e5e4;
  }

  &.px-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  &.barred {
    height: calc(100% - 4.5rem) !important;
  }
}
