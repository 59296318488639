.invoice-create-wrapper {
  padding-bottom: 50px;

  &.customers {
    padding-bottom: 0px;

    &.blur {
      filter: blur(1.5px);
    }
  }
}

.invoice-button {
  font-weight: 600;
  font-size: 14px !important;
  padding: 10px 16px !important;
  height: 45px !important;
}

.invoice-create-wrapper .title {
  font-size: 18px;
  line-height: 28px;
  padding: 32px 0;
  font-weight: 600;
  color: #242628;
}

.payment-region {
  width: 50%;
  overflow: auto;
  height: 100vh;
  background: #fff;
  border-left: 1px solid #e7e5e4;

  .inner-holder {
    padding-top: 3rem;
    width: 35rem;
    margin: auto;

    &.single-invoice {
      height: 100vh;
      display: flex;
      flex-direction: column;

      &.portal {
        height: calc(100vh + 4rem);
      }
    }

    h1 {
      color: #1c1917;
      font-weight: 600;
      font-size: 1.875rem;
    }

    .box-holder {
      padding: 24px;
      font-size: 0.875rem;
      border-radius: 12px;
      margin-bottom: 20px;
      border: 1px solid #e7e5e4;
      box-shadow: 0px 1px 2px 0px #1c19170d;

      &.payment-method-box {
        transition: height 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        overflow: hidden;
        height: 170px;

        &.with-bank-transfer {
          height: 405px;
        }

        .payment-content {
          animation: slideDown 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateY(-15px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      .header {
        color: #44403c;
        font-weight: 600;
        font-size: 0.875rem;
        margin-bottom: 0 !important;
        padding-bottom: 2px;

        &.sub {
          font-weight: 500;
          display: block;
          padding-bottom: 0px;
        }
      }

      .text {
        color: #79716b;
        display: block;

        &.status {
          color: #a9a29d;
          text-align: right;

          &.paid {
            color: #039855;
          }
        }
      }

      .value {
        color: #1c1917;
        font-weight: 500;
      }

      @keyframes slideDown {
        from {
          opacity: 0;
          transform: translateY(-15px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .pay-btn {
      height: 44px;
      max-width: unset !important;
      font-size: 1rem !important;
      font-weight: 500 !important;

      &:disabled {
        pointer-events: unset;
        cursor: not-allowed;
      }
    }

    .security-disclaimer {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a9a29d;
      font-size: 0.875rem;
      gap: 8px;
    }
  }
}

.transfer-holder {
  margin-top: 14px;
  border: 1px solid #e7e5e4;
  background: #fafaf9;
  border-radius: 12px;
  padding: 1rem;

  .content-holder {
    display: flex;
    align-items: center;

    .caption {
      color: #a9a29d;
      font-weight: 500;
      font-size: 0.75rem;
    }

    .value {
      color: #1c1917;
      font-weight: 500;
      font-size: 0.875rem;
      padding-top: 2px;
    }
  }
}

.invoice-create-wrapper .groupWrapper label {
  margin-top: 0;
}

.invoice-create-wrapper .form-control {
  padding: 14px !important;
}

.invoice-create-wrapper .divider {
  width: 100%;
  height: 1px;
  background-color: #eaecf0;
  margin-top: 32px;
}

.breadcrumbs-list {
  display: flex;
}

.breadcrumbs span {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.breadcrumbs span:after {
  color: #d9ddd5;
  padding: 8px;
  content: '›';
}

.breadcrumbs span:last-child:after {
  content: '';
}

.breadcrumbs .active {
  color: #d28b28;
  font-weight: 600;
}

.product-items-wrapper {
  position: relative;
  display: flex;
  gap: 95px;
  align-items: center;
  margin-bottom: 32px;
}

.product-items-wrapper:last-child {
  margin-bottom: 0;
}

.w-540 {
  width: 540px;
}

.w-250 {
  width: 250px;
}

.add-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  height: 36px !important;
  border-radius: 8px !important;
  padding: 0 0.75rem !important;
  gap: 6px;

  &.unset-gap {
    gap: unset;
  }

  span {
    font-weight: 500;
  }
}

.actions-dialog.invoice {
  padding: 9px 0;
  min-width: 196px;
  border: 1px solid #e7e5e4;
  box-shadow: 0px 12px 16px -4px #1c191714;

  .actionLink {
    padding: 8px;
    color: #1c1917;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
  }
}

.ant-radio-checked .ant-radio-inner {
  border: 1px solid #d28b28 !important;

  &::after {
    background-color: #d28b28 !important;
  }
}

.ant-radio:hover .ant-radio-inner {
  border-color: #d28b28 !important;
}

.ant-radio-input:focus + .ant-radio-inner {
  border-color: #d28b28 !important;
  box-shadow: none !important;
}

.add-new-action {
  color: #d28b28;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 24px;
  cursor: pointer;
}

.add-new-action svg {
  margin-right: 8px;
}

.absolute {
  position: absolute;
  right: 0;
  bottom: 15%;
  cursor: pointer;
}

.invoice-footer {
  width: 601px;
  margin-top: 48px;
  float: right;
  padding-bottom: 100px;
}

.invoice-footer .subtotal {
  color: #586068;
  font-size: 18px;
}

.invoice-footer .amount-wrapper {
  color: #000;
  font-size: 18px;
  padding-left: 0 !important;
}

.invoice-footer .mt-32 {
  margin-top: 32px;
}

.invoice-footer .preview-btn {
  float: right;
}

.invoice-preview-wrapper {
  background-color: #fafaf9;
  padding: 45px 0;

  &.customer {
    padding: 0;
    display: flex;

    @media (max-width: 575.98px) {
      padding-top: 2rem;
      flex-wrap: wrap;

      .invoice-holder {
        height: auto;
        width: 100% !important;
        padding: 2px !important;
      }

      .invoice-preview-footer__button {
        width: 100%;

        .footer-text {
          padding: 0 16px;
          text-align: center;
        }
      }

      .payment-region {
        width: 100%;
        height: auto;
        margin-top: 2rem;

        .inner-holder {
          width: 93%;
        }
      }
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      padding-top: 2rem;
      flex-wrap: wrap;

      .invoice-holder {
        height: auto;
        width: 100% !important;
        padding: 2px !important;
      }

      .invoice-preview-footer__button {
        width: 100%;

        .footer-text {
          padding: 0 16px;
          text-align: center;
        }
      }

      .payment-region {
        width: 100%;
        height: auto;
        margin-top: 2rem;

        .inner-holder {
          width: 93%;
        }
      }
    }
  }

  .invoice-holder {
    overflow: auto;
    height: 100vh;
  }
}

.preview-header {
  padding-top: 40px;
  padding-bottom: 48px;
  width: 100%;
}

.action {
  width: 320px;
  display: flex;
  float: right;
  gap: 32px;
}

.action .action-edit {
  color: #586068;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e8e3;
  box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.action-border-text {
  gap: 8px;
  height: 32px !important;
  color: #57534e !important;
  border: 1px solid #d7d3d0 !important;

  span {
    padding-top: 2px;
    font-weight: 500 !important;
  }
}

.action button {
  height: 40px;
  width: 148px;
  background: #242628;
  border: 1px solid #242628;
  box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05);
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
}

.invoice-preview {
  background-color: #fff;
  width: 595px;
  padding: 10px;
  margin: 0 auto;
  height: 842px;
  transform: scale(0.8);
  color: #1c1917;

  &.customer-view {
    transform: scale(1);
  }

  &.is-demo {
    transform: scale(0.52);
    transform-origin: top center;
  }

  &.detail {
    text-align: left;
    overflow: auto;
    height: calc(100vh - 8rem);
  }

  &.template-classic {
    color: #000000;

    .caption {
      display: block;
      color: #525866;
      font-size: 0.625rem;
    }

    .value {
      display: block;
      color: #000;
      font-weight: 600;
      font-size: 0.625rem;
    }
  }

  &.template-foundation {
    color: #181818;

    .diagonal-slash {
      position: absolute;
      width: 100%;
      height: 12px;
      left: 0;
      background: linear-gradient(135deg, #72b800 75%, #d4d4d4 25%);

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }
    }
    .caption {
      display: block;
      color: #5a5a5a;
      font-size: 0.75rem;
    }

    .value {
      display: block;
      color: #181818;
      font-weight: 600;
      font-size: 0.75rem !important;

      &.currency {
        font-size: 1rem !important;
      }

      &.invoice-no {
        text-align: right;
        margin-bottom: 1rem;
      }
    }
  }
}

.bg-secondary-50 {
  background: #fbf5ec;
}

.invoice-preview header {
  padding: 20px 20px 10px 20px;
}

.border-12 {
  border-radius: 12px;
}

.invoice-preview header span:first-child {
  color: #586068;
  font-size: 12px;
}

.invoice-preview header span:last-child {
  color: #586068;
  font-size: 12px;
  text-align: right;
  font-weight: 500px;
}

.invoice-preview header p,
.invoice-preview header h1 {
  padding: 0;
  margin: 0;
}

.invoice-preview .header-title {
  font-weight: 600;
  font-size: 10px;
  color: #1c1917;
}

.invoice-preview .rhs .header-title {
  text-align: right;
}

.invoice-preview .header-sub {
  font-size: 10px;
  font-weight: 500;
  color: #242628;
}

.invoice-preview header .header {
  margin-bottom: 24px;
}

.invoice-preview .lhs .text {
  color: #586068;
  font-size: 10px;

  &.preview {
    color: #57534e;
  }
}

.preview-table {
  margin-top: 45px;
  padding: 0 20px;
  overflow: auto;
  max-height: 16rem;

  &.customer {
    margin-top: 12px;

    &.padded {
      margin-top: 60px;
    }
  }

  &.template-classic {
    .table {
      margin-bottom: 0;

      tr {
        border-top: 1px solid #e7e5e4 !important;
        border-bottom: 1px solid #e7e5e4 !important;

        th {
          font-weight: 400 !important;
          color: #79716b;
          padding: 8px !important;
          background-color: #f5f5f4;
        }

        td {
          font-weight: 600;
          color: #000 !important;
          padding: 8px !important;
        }
      }
    }
  }

  &.template-foundation {
    .table {
      margin-bottom: 12px;

      tr {
        border: 1px solid #e3ebf6 !important;

        th {
          font-weight: 500 !important;
          color: #181818;
          padding: 8px !important;
          background-color: #f2f2f2;
        }

        td {
          font-size: 0.75rem;
          font-weight: 600;
          color: #181818 !important;
          padding: 8px !important;
        }
      }
    }
  }
}

.balance-region {
  margin: 0 18px;
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: end;

  .balance {
    margin-bottom: 4px;
    color: #79716b;
    font-size: 0.625rem;
    font-weight: 600;
  }

  .amount {
    color: #1c1917;
    font-weight: 500;
  }
}

.preview-table th {
  color: #1c1917;
  font-size: 0.625rem;
  padding: 10px 0px !important;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1;
}

.preview-table tr {
  border: white;
}

.preview-table td {
  font-size: 0.625rem;
  padding: 8px 0px !important;
  color: #586068 !important;
}

.preview-table td:first-child {
  color: #1c1917 !important;
  font-weight: 500;
}

.preview-table tr:last-child td {
  border: none;
}

.report-table {
  padding: 0 20px;
}

.report-table tr:last-child td {
  border: none;
}

.account-info {
  padding: 14px 16px 0px 5px;
  width: 356px;
  margin-top: 40px;

  &.template-dawn {
    .info:first-child {
      border-top: 1px solid #1c1917;
    }
    .info:nth-child(3) {
      border-bottom: 1px solid #1c1917;
    }

    .total {
      padding-top: 4px;
    }
  }

  &.template-foundation {
    padding: 0;
    margin-top: 4px;
    width: 220px;

    .info {
      span {
        color: #181818;
        font-weight: 700;
      }
    }

    .total {
      h1 {
        color: #5a5a5a;
        font-weight: 450;
      }
    }
  }

  &.template-classic {
    padding: 0;
    margin-top: 4px;
    width: 220px;

    .info {
      h1 {
        color: #79716b;
      }

      span {
        color: #000000;
        font-weight: 600;
      }

      &:nth-child(3) {
        border-bottom: 1px solid #e7e5e4;
      }
    }

    .total {
      margin-top: 8px;

      h1 {
        color: #000;
        font-weight: 700;
      }

      span {
        color: #000;
        font-weight: 700;
      }
    }
  }
}

.info {
  margin-bottom: 3px;
}

.instalment-holder {
  display: flex;
  font-size: 0.625rem;

  p {
    margin-bottom: 0;
  }

  .payment {
    color: #1c1917;
    padding-bottom: 2px;
  }

  .due-date {
    color: #79716b;
  }

  .amount {
    color: #57534e;
  }
}

.account-info .info h1 {
  font-size: 0.625rem;
  font-weight: 400;
  color: #1c1917;
}

.account-info .info p {
  margin: 0;
  padding: 0;
  font-size: 0.625rem;
  color: #57534e;
  font-weight: 400;
}

.total h1,
.total p {
  color: #1c1917;
  font-size: 0.625rem;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.footer-divider {
  background: #eaecf0;
  width: 100%;
  height: 1px;
}

.invoice-preview-footer {
  margin-top: 32px;
  padding: 0 20px;
}

.invoice-preview-footer__button {
  padding: 4px;
  height: 84px;
  margin-top: 32px;
  background-color: #fff;
  width: 595px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-text {
    padding-top: 8px;
    color: #79716b;
    font-size: 0.875rem;

    a {
      color: #79716b;
      text-decoration: none;
    }
  }
}

.invoice-preview-footer .details {
  margin-top: 32px;
}

.invoice-preview-footer .details img {
  width: 32px;
  height: 14px;
  margin-bottom: 8px;
}

.invoice-preview-footer .details .lhs {
  width: 170px;
}

.invoice-preview-footer .lhs p {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  padding: 0;
  color: #121722;
}

.invoice-preview-footer .lhs a {
  display: block;
  margin: 8px 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #334bc8;
  text-decoration: none;
}

.invoice-preview-footer .rhs h1 {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #121722;
}

.invoice-preview-footer .rhs p {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #60737d;
}

@media (max-width: 480px) {
  .invoice-preview {
    width: 100%;
  }
}

.customers .action {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.payment-method header {
  padding: 25px 40px;
  background-color: #f2f4f1;
}

.payment-method .link {
  gap: 20px;
}

.payment-account-details {
  width: 480px;
  background-color: #fff;
  margin: 62px auto;
  padding: 50px;
}

.payment-account-details .back-arrow {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #242628;
  cursor: pointer;
}

.payment-account-details .back-arrow svg {
  margin-right: 10px;
}

.payment-account-details h1 {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  margin-top: 50px;
}

.payment-account-details .list-items {
  padding: 25px 0;
}

.payment-account-details .list-items h6 {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #586068;
  margin: 0;
  padding: 0;
}

.payment-account-details .list-items p {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.03em;
  color: #000000;
  margin: 0;
  padding: 0;
}

.payment-account-details .list-items .copy {
  background: #f2f4f1;
  border-radius: 30px;
  padding: 7px 10px;
  cursor: pointer;
}

.content .showmore {
  position: relative;
}

.content .showmore span {
  cursor: pointer;
}

.content .showmore .showmore-content {
  background-color: white;
  padding: 8px 16px;
  position: absolute;
  right: 0px;
  width: 200px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.delete-content {
  color: red;
}

.share-invoice-modal-wrapper {
  padding: 24px;
}

.share-invoice-modal-wrapper main {
  padding-top: 20px;
}

.share-invoice-modal-wrapper main h1 {
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
  color: #242628;
  font-weight: 600;
  font-size: 18px;
}

.share-invoice-modal-wrapper main p {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #586068;
}

.share-invoice-modal-wrapper main .divider {
  background-color: #eaecf0;
  width: 100%;
  height: 1px;
  margin: 20px 0;
}

.svg-container {
  background: #f2f4f1;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-invoice-modal-wrapper footer .footer-actions-wrapper {
  display: flex;
  gap: 28px;
}

.share-invoice-modal-wrapper footer .footer-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.share-invoice-modal-wrapper footer .footer-actions p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  color: #586068;
}

.share-via-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #586068;
}

.disabled-date {
  background: #f2f4f1;
  opacity: 0.5;
  border: 1px solid #e5e8e3;
  box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05);
  border-radius: 8px;
  margin-top: 8px;
  padding: 14px;
}

.disabled-date p {
  padding: 0;
  margin: 0;
}

.float-right {
  text-align: right;
}

.add-customers-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.customer-Phone__input-holder {
  label {
    font-size: 0.875rem;
    color: #44403c !important;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    color: unset !important;
  }

  .customer-Phone__input {
    .PhoneInputDiv {
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px #1c19170d;
      padding: 2px 12px;
      height: 40px;
      font-size: 0.875rem;
    }

    .PhoneInputCountry {
      gap: 6px;
      .PhoneInputCountryIcon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: unset;

        img {
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .PhoneInputCountrySelectArrow {
        width: 7px;
        height: 7px;
      }
    }

    .PhoneInputInput {
      height: 32px;
      padding-left: 12px;
      margin-left: 6px;
    }
  }
}

.customer-modal_footer {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
  border-top: 1px solid #e7e5e4;

  .custom-button {
    height: 40px !important;
    padding: 10px !important;
    width: 100px;
    font-weight: 500;
    font-size: 14px !important;

    &.ghost-button {
      background-color: #fff !important;
      border: 1px solid #e1e4dd !important;
      box-shadow: 0 1px 2px rgba(22, 22, 21, 0.05) !important;
      color: #57534e !important;

      &:active,
      &:focus {
        width: 100px;
      }
    }

    &.primary-button {
      padding: 0 12px !important;
      height: 40px !important;
      width: max-content;
    }
  }
}

.details {
  padding-top: 29px;
  width: 100%;
  gap: 2px;
}

.invoice-preview-footer .details p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #121722;
  margin: 0;
}

.rhs .header-sub {
  text-align: right;
  font-size: 10px;
  font-weight: 500;
}

.rhs .text {
  color: #57534e;
  font-size: 12px;
  text-align: right;
}

.error-wrapper {
  padding: 16px;
  background: #fee4e2;
  margin-top: 32px;
  font-size: 14px;
  border-radius: 8px;
  color: #d92d20;
}

.is-processing {
  color: #d28b28 !important;
  font-weight: 600;
}

.is-paid {
  color: #027a48 !important;
  font-weight: 600;
}

.footer-text {
  font-weight: 400;
  font-size: 12px;
  color: #575855;
  margin: 0px;
}

.max-w-300 {
  max-width: 330px;
}

.max-w-250 {
  max-width: 250px;
}
