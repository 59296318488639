.password-strength-wrapper {
  span {
    font-size: 12px !important;
  }
  .good-strength-icon {
    color: #12b76a;
  }
  .bad-strength-icon {
    color: #f04438;
  }

  .password-strength-item {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
