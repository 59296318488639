@import '../../scss/base/colors.scss';

.bank-account-wrap {
  .account-label {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px;
    background: #fbf5ec;
    border: 2px #d28b28 solid;
    border-radius: 12px;
    margin-bottom: 10px;
    .icon-wrap {
      margin-right: 16px;
    }
    .text-wrap {
      h3 {
        font-size: 16px;
        color: #885a1a;
        font-weight: 600;
        margin-bottom: 12px;
      }
      p {
        font-size: 16px;
        color: #d28b28;
        font-weight: 400;
        line-height: 0px;
      }
    }
    .check-wrap {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 14px;
      margin-top: 10px;
    }
  }

  .account-label-unset {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px;
    background: #ffffff;
    border: 2px #eff0ed solid;
    border-radius: 12px;
    margin-bottom: 10px;
    .icon-wrap {
      margin-right: 16px;
    }
    .text-wrap {
      h3 {
        font-size: 16px;
        color: #414240;
        font-weight: 600;
        margin-bottom: 12px;
      }
      p {
        font-size: 16px;
        color: #414240;
        font-weight: 400;
      }
    }
    .check-wrap {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 14px;
      margin-top: 10px;
    }
  }
}

.companyLogo-wrapper {
  display: flex;
  gap: 24px;
  img {
    width: 48px;
    height: 48px;
  }
  button {
    background: transparent;
    border: none;
    color: $secondary-500;
    font-size: 14px;
  }
}

.profile-page-wrapper {
  .back-click {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #d28b28;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}

.profile-card__img {
  box-shadow: 0px 4px 6px -2px #1c191708;
  border: 4px solid #ffffff;
  padding: 4px;
  border-radius: 100%;
  background: #f9f9f9;
}

.more-profile-information-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  & > div {
    & > span:first-child {
      display: block;
      margin-bottom: 4px;
      color: $grey-500;
    }
  }
}

.budgets-flex-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 4px;
}
.profile-nav-bar {
  border-bottom: none !important;
  background-color: $grey-100;
}

.profile-new-tab {
  font-size: 0.875rem !important;
  position: relative;
  font-weight: 600 !important;
  color: $grey-500 !important;
  border-radius: 6px;
  border: none !important;

  &.active {
    color: $grey-700 !important;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px #1c19170f;
  }

  &:hover {
    background-color: '#ffffff';
    box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);
  }
}

.date-filter-button-group {
  button {
    background-color: #ffffff;
    border-color: $grey-300 !important;
    color: $grey-700 !important;
    font-size: 14px;
    &.active {
      background-color: $grey-50 !important;
    }
    &:hover {
      background-color: $grey-50 !important;
    }
  }
}

.profile-grey-text-color {
  color: $grey-500;
}

.profile-header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profile-overview-img-card {
  height: 64px;
  width: 64px;
  background: $primary;
  padding: 20px;

  h5 {
    font-size: 24px;
  }
}

.profile-header-img-card {
  height: 96px;
  width: 96px;
  background: $primary;
  padding: 20px;

  h5 {
    font-size: 36px;
  }
}

.budget-card-title {
  font-size: 1.3rem !important;
}

.budget-card-currency {
  font-size: 1.1rem !important;
}

.profile-no-data {
  padding: 0 !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  width: 440px !important;
  .textHeader {
    font-weight: 600;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    margin: 5px 0;
  }
  .subHeaderText {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
  }
}

.loading-state {
  min-height: 35vh;
}
