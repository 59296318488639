$base-white: #fff;
$base-black: #000;
$primary: #bfff0b;

$grey-50: #fafaf9;
$grey-100: #f5f5f4;
$grey-200: #e7e5e4;
$grey-300: #d9ddd5;
$grey-500: #79716b;
$grey-600: #57534e;
$grey-700: #44403c;
$grey-900: #1c1917;

$secondary-50: #fbf5ec;
$secondary-100: #f4e0c5;
$secondary-200: #eccc9e;
$secondary-300: #e5b777;
$secondary-400: #dea250;
$secondary-500: #d28b28;
$secondary-600: #af7421;
$secondary-700: #885a1a;
$secondary-800: #614013;
$secondary-900: #3a270b;

$error-50: #fef3f2;
$error-100: #fee4e2;
$error-300: #fda29b;
$error-700: #b42318 !important;

$yellow-50: #fefbe8;
$yellow-100: #fef7c3;

$grayblue-100: #eaecf5;

$blue-50: #eff8ff;
$blue-100: #d1e9ff;

$teal-50: #f0fdf9;
$teal-100: #ccfbef;

$purple-50: #f4f3ff;
$purple-100: #ebe9fe;

$cyan-100: #cff9fe;

$fuchsia-50: #fdf4ff;
$violet-50: #f5f3ff;

$orange-100: #fdead7;
