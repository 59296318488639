.confirm-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  position: relative;

  .confirm-modal-body {
    background: #ffffff;
    box-shadow: 0 12px 40px rgba(16, 24, 64, 0.24);
    border-radius: 8px;
    width: 380px;
    padding: 32px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .cancel-icon {
      position: absolute;
      top: 12px;
      right: 26px;
      cursor: pointer;
    }

    .back-btn {
      position: absolute;
      top: 12px;
      left: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      color: #d28b28;
    }

    .children {
      max-height: 684px;
      overflow-y: scroll;
    }

    .title {
      &.small {
        max-width: 432px;
      }

      &.large {
        max-width: 592px;
      }
      h5 {
        color: #1c1917;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-align: left !important;
      }

      p {
        color: #79716b;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
      }
    }
  }
}
