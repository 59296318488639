.component_page-section {
  max-width: 894px;
  padding: 32px 0px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 32px 16px;
  }

  .elements-section {
    .back-btn {
      display: flex;
      width: fit-content;
      justify-content: center;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      div {
        color: #d28b28;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .section-title {
      padding: 20px 0 8px 0;
      border-bottom: 1px solid #e7e5e4;

      h1 {
        color: #1c1917;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      p {
        color: #79716b;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 150% */
      }

      @media (max-width: 768px) {
        h1 {
          font-size: 18px !important;
          margin: 0px !important;
        }

        p {
          margin: 0px !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

// quick action
.alert_action-banner {
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  padding: 24px;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: #f5f5f4;
  box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);
  .banner-text {
    h1 {
      color: #1c1917;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    p {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  @media (max-width: 1224px) {
    .image-banner {
      display: none;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 12px;

    button {
      color: #d28b28;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    a {
      &.btn {
        color: #d28b28 !important;
      }
    }

    .btn {
      padding: 0px !important;
      width: fit-content !important;
      background: transparent !important;
      border: none !important;
    }
  }
}
