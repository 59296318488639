.beneficiaries-wrapper {
  .infinite-scroll-component {
    display: flex;
    padding-top: 24px;
    padding-bottom: 24px;
    row-gap: 50px;
    column-gap: 24px;
    flex-wrap: wrap;
    height: 100%;
    width: 100% !important;
  }
}

.no-data {
  padding: 0 !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  .textHeader {
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    margin: 5px 0;
  }
  .subHeaderText {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
  }
}

.custom-table-dimension {
  min-width: 1300px;
}

.uploaded-file-container {
  border: 1px solid #e7e5e4;
}

.uploaded-file-container-name,
.uploaded-file-container-details {
  font-size: 12px;
}

.uploaded-file-container-name {
  font-weight: 500;
  color: #242628;
}

.uploaded-file-container-details {
  color: #586068;
}

.placeholder-loading > div:first-child {
  width: 280px; /* Adjust width and height as needed */
}

.placeholder-loading > div:last-child {
  width: 200px; /* Adjust width and height as needed */
}

.placeholder-loading > div {
  height: 20px;
  background-color: #b4afaf; /* Light gray color */
  animation: pulse 1.5s infinite ease-in-out; /* Animation */
}

.form-table {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 1.7rem;
  .input-container {
    padding-right: 1.5rem;
  }
}

.custom-table-dimension {
  tr.position-relative {
    position: relative;
  }

  td.position-sticky {
    position: sticky;
    z-index: 1;
  }

  .cancel.position-sticky {
    right: 0;
    background-color: white;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
}

.members-table th {
  color: #586068 !important;
  font-weight: 500;
  font-size: 14px;
}

.custon-form-field {
  color: #79716b !important;
  border: 1px solid #79716b;
  font-size: 14px !important;
}

.members-page {
  .subtext {
    color: #586068;
    font-weight: 400;
  }
  .button-dimension-fit-content {
    font-size: 14px !important;
    min-width: fit-content !important;
  }
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #e7e5e4 !important;
  border-radius: 4px;
}

.header-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #212120;
}

.upgrade-subscription-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;

  .main-section {
    height: 50%;
    .info-section {
      padding: 0px 24px;

      h4 {
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        color: #212120;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #7f7f7d;
        span {
          font-weight: 600;
          color: #0d0d0c;
        }
      }
    }

    .seat-number-section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .arc-container {
        position: relative;
        width: 308px;
        // height: 75px;

        .arc {
          width: 100%;
          height: 100%;

          .arc-path {
            fill: none;
            stroke: #e0e0e0;
            stroke-width: 4;
          }

          .arc-progress {
            fill: none;

            stroke: url(#gradient);
            stroke-width: 4;
            stroke-dasharray: 125.6; // Full arc length
            stroke-dashoffset: 125.6; // Initially hidden
            stroke-linecap: square;
            transition: stroke-dashoffset 0.5s ease; // Smooth transition
          }
        }

        .arc-value {
          position: absolute;
          top: 35%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #0d0d0c;
          font-size: 80px;
          font-weight: 700;
          line-height: 46px;
          text-align: center;
        }

        .arc-label {
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 0.9rem;
          text-align: center;
          font-weight: 600;
          color: #7f7f7d;
        }
      }
    }
  }

  .plan-section {
    display: flex;
    gap: 10px;
    align-items: center;
    background: #fafaf9;
    padding: 24px;
    height: 50%;
    // margin-top: auto;
    div {
      border: 1px solid #e8e8e7;
      padding: 16px;
      border-radius: 16px;
      width: 100%;
      transition: transform 0.2s;
      cursor: pointer;

      h5 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #0d0d0c;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #7f7f7d;
      }
      h3 {
        font-size: 36px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: -0.04em;
        color: #0d0d0c;
      }

      &:hover {
        transform: scale(1.02);
      }
    }

    div:first-of-type {
      background: #ffffff;
    }

    div:nth-of-type(2) {
      background: #ffffff;
      background: radial-gradient(
        80.22% 35.04% at 19.78% 13.51%,
        #ddff75 0%,
        #f2ffcd 35%,
        #ffffff 100%
      );

      border: 1px solid rgba(114, 114, 114, 0.24); // Use a solid border color

      box-shadow: 0px 0.59px 1.44px 0px #0000002e, 0px 3.3px 4.45px 0px #0000001f,
        0px 8.66px 10.64px 0px #00000019, 0px 18.26px 25.25px 0px #00000013,
        0px 42px 83px 0px #0000000c;
    }
  }

  .header-icon {
    background: #f5f5f4;
    border: 1px solid rgba(0, 0, 0, 0.06);

    display: flex;
    align-items: center;
    justify-content: center;

    &.bg-green {
      background: #d8ff62 !important;
    }

    &.lg {
      height: 64px;
      width: 64px;
      box-shadow: 0px -2.13px 2.13px 0px #00000033 inset, 0px 1.07px 1.07px 0px #00000014,
        0px 2.67px 5.33px 0px #0000001f !important;
      border-radius: 100%;
    }

    &.md {
      height: 40px;
      width: 40px;
      box-shadow: 0px -0.8px 0.8px 0px #00000033 inset, 0px 0.4px 0.4px 0px #00000014,
        0px 1px 2px 0px #0000001f;
      border-radius: 100%;
    }

    &.sm {
      height: 24px;
      width: 24px;
      box-shadow: 0px -0.8px 0.8px 0px #00000033 inset, 0px 0.4px 0.4px 0px #00000014,
        0px 1px 2px 0px #0000001f;
      border-radius: 100%;
    }
  }
}
