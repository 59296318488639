// .modal-content {
//   background-color: rgba(65, 66, 64, 0.6) !important;
//   border: none !important;
//   overflow: hidden;
//   overflow-y: scroll;
// }

.asset-viewer {
  background-color: rgba(65, 66, 64, 0.6) !important;
  color: #fff;
  width: 742px !important;
  height: 944px;
  max-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1000000;
  margin: 40px auto;
  @media (max-width: 1520px) {
    height: 810px;
  }
  .head-region {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 742px;
    background-color: rgba(65, 66, 64, 0.6) !important;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    z-index: 111111;
    position: fixed;
    margin-bottom: 40px;
    h6 {
      color: #fff;
      font-weight: 400;
      font-size: 16px;
    }
    span {
      cursor: pointer;
    }

    .download-icon {
      cursor: pointer;
    }
  }
  .asset-container {
    margin: auto 30px;
    height: 880px;
    position: relative;
    overflow: scroll;

    img {
      height: inherit;
      object-fit: contain;
      width: 100%;
    }
    .swiper {
      height: inherit;

      .swiper-slide {
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
  }
}

.doc-viewer {
  #proxy-renderer {
    height: 100% !important;
  }
  #msdoc-renderer {
    height: 90% !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 742px !important;
  }
}

.navigation {
  position: absolute;
  top: 50%;
  display: flex;
  width: 100%;
  .navigation-postion {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 11111;

    span {
      width: 36px;
      height: 36px;
      background-color: white;
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      cursor: pointer;
    }
  }
}

.loader-holder {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(31, 32, 41, 0.75);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  .display-text {
    padding-top: 1.25rem;
    font-weight: 600;

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
      animation: ellipsis steps(4, end) 1500ms infinite;
      content: '\2026';
      width: 0px;
    }
  }
}

.loader {
  position: relative;
  width: 10vw;
  height: 5vw;
  padding: 1.5vw;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: absolute;
    height: 0.8vw;
    width: 0.8vw;
    border-radius: 50%;
    background-color: #beff0b;
  }

  span:nth-child(1) {
    animation: loading-dotsA 1200ms infinite linear;
  }

  span:nth-child(2) {
    animation: loading-dotsB 1200ms infinite linear;
  }
}

@keyframes loading-dotsA {
  0% {
    transform: none;
  }
  25% {
    transform: translateX(2vw);
  }
  50% {
    transform: none;
  }
  75% {
    transform: translateY(2vw);
  }
  100% {
    transform: none;
  }
}

@keyframes loading-dotsB {
  0% {
    transform: none;
  }
  25% {
    transform: translateX(-2vw);
  }
  50% {
    transform: none;
  }
  75% {
    transform: translateY(-2vw);
  }
  100% {
    transform: none;
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
