.card-modal-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  .modal-pagination {
    color: #898989;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      margin: 0 3px;
    }
    .active-text {
      font-weight: 600;
      color: #000;
    }
  }
}
.card-modal-body {
  padding-bottom: 50px;

  &.invoice {
    padding-bottom: 0;
  }
}
.link-text {
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  font-weight: 600;
}

.text-gold {
  color: #d28b28;
}

.card__typeSelector {
  position: relative;
  .card__typeSelector--header {
    span {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      background: rgba(210, 139, 40, 0.1);
      border-radius: 100%;
    }
    .info {
      flex: 2;
      h6 {
        font-size: 24px;
        margin: 0;
        padding: 0;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        color: #586068;
        margin: 0;
        padding-top: 4px;
      }
    }
  }
  .card__types {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 12px;

    .card__types--items {
      width: 100%;
      background: #ffffff;
      border: 1px solid #f2f4f1;
      border-radius: 12px;
      header {
        width: 100%;
        justify-content: space-between;
        padding: 10px 16px;
        border-bottom: 1px solid #f2f4f1;

        h6 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          margin: 0;
        }
        .radio {
          width: 16px;
          height: 16px;
          background: #ffffff;
          border: 1px solid #e5e8e3;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .isActive {
          background: #242628;
          border: 1px solid #242628;
          border-radius: 8px;
        }
      }
      .header-active {
        border-bottom: 1px solid #d9ddd5;
      }
      body {
        padding: 16px;
        gap: 20px;
        border-radius: 12px;
        h6 {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #242628;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          color: #586068;
        }
        img {
          height: 104px;
        }
      }
    }
    .active-card {
      border: 1px solid #d9ddd5;
    }
  }
  footer {
    padding-top: 30px;
    background-color: white;
    justify-content: flex-end;
    bottom: 0;
    padding: 16px 0px;
    position: fixed;
    width: 500px;
    right: 0px;
  }

  .footer__action {
    display: flex;
    align-self: flex-end;
    gap: 12px;
    .button {
      padding: 8px 18px !important;
      height: 48px;
      font-size: 16px;
    }
    .cancel {
      color: #586068 !important;
      border: 1px solid #e5e8e3 !important;
      background-color: white !important;
      box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05) !important;
      &:hover {
        color: #586068 !important;
      }
    }
  }
  @media (max-width: 1520px) {
    padding-bottom: 60px;
  }
}

.card__types--items:hover {
  border: 1px solid #d9ddd5 !important;
  border-radius: 12px;
}
.card__types--items:hover header {
  border-bottom: 1px solid #d9ddd5 !important;
}

#result {
  .css-exkdvc {
    font-family: 'Inter', sans-serif !important;
  }
}

.vgsButton {
  width: 100%;
  border: none;
  background: white;
}
.vgsButton iframe {
  height: 48px !important;
  width: 100% !important;
}

.advance__setting--cards {
  .advance__setting-heading {
    padding-top: 16px;
    h3 {
      color: #1c1917;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    p {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .advance__setting--payment {
    .heading {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }
    .icon-wrap {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      background: #f5f5f4;
    }

    .ant-switch {
      background-color: #f3f4f6 !important;
    }

    .ant-switch-checked {
      background-color: #d28b28 !important;
    }
    .payment-methods {
      gap: 12px;

      div {
        color: #44403c;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .advance__setting--label {
    margin-bottom: -16px !important;
    div {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    p {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.track__card {
  .heading {
    h3 {
      color: #1c1917;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    p {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .track__card--progress {
    position: relative;
    margin: 0;
    display: flex;
    width: 100%;
    list-style: none;
    justify-content: space-between;
    padding: 0;

    .progress-line {
      position: absolute;
      top: 0;
      z-index: 0;
      margin-top: 4px;
      width: 180px;
    }
    .red-line {
      border-top: 4px #e7e5e4 solid;
    }
    .green-line {
      border-top: 4px #d28b28 solid;
      z-index: 10;
    }
  }

  .progress-items {
    margin-bottom: 8px;
    margin-left: -8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .progress-image {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      z-index: 10;
      width: fit-content;
      transform: translate(0, -0.7rem);
      border-radius: 16px;
      background: #f5f5f4;
    }

    .progress-image-completed {
      background: #f4e0c5 !important;
    }

    .inactive-text {
      color: #79716b;
    }
  }
}

.activate-card-overview {
  .overview-card-view {
    background: #fbf5ec !important;
    border: 1px #f4e0c5 solid !important;
    background-image: url('../../../assets/images/inline-cta-bg.png') !important;
    background-position: right bottom !important;
    background-repeat: no-repeat !important;
    background-size: 400px !important;
  }
}
.activate-card {
  .activate-button {
    width: fit-content;
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #d7d3d0;
    background: #fff;
    cursor: pointer;
  }

  .activate-card-list {
    color: #44403c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    list-style: disc;
    list-style-position: outside;
  }

  .badge-label {
    display: flex;
    padding: 2px 10px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #fedf89;
    background: #fffaeb;
    mix-blend-mode: multiply;
    color: #b54708;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: fit-content;
  }

  .card-spending-bar {
    .available {
      display: flex;
      justify-content: space-between;
      align-content: center;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;

      p {
        color: #79716b;
        margin: 0px;
      }
      span {
        color: #d28b28;
      }
    }

    .available-bar {
      border-radius: 4px;
      background: #d28b28;
      height: 8px;
      width: 100%;
    }
  }
}

.card-settings {
  h3 {
    color: #1c1917;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }

  .billing-address {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    h6 {
      color: #79716b;
    }

    p {
      color: #1c1917;
    }
  }
}
.border-hidden {
  border-style: hidden !important;
}

.default__pin--box {
  display: flex;
  width: 80px;
  padding: 10px 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #d28b28;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);

  color: #d28b28;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
