h1,
p {
  margin: 0;
  padding: 0;
}

.plans-wrapper .header {
  margin-top: 32px;
}
.plans-wrapper .header h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #161615;
}
.plans-wrapper .header p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575855;
  margin-top: 4px;
}

.plan-type-selector {
  background: #f7f8f7;
  border: 1px solid #f0f1ee;
  border-radius: 8px;
  margin-top: 24px;
  padding: 6px;
  gap: 8px;
}

.plan-type-selector .items {
  padding: 10px 14px;
  text-transform: capitalize;
  cursor: pointer;
  color: #6d6f6b;
}
.active-type {
  background-color: white;
  font-weight: 600;
  box-shadow: 0px 1px 3px rgba(22, 22, 21, 0.1), 0px 1px 2px rgba(22, 22, 21, 0.06);
  border-radius: 6px;
}

.plan-type-selector .save {
  padding: 2px 10px;
  background: #f0f1ee;
  mix-blend-mode: multiply;
  border-radius: 16px;
  margin-left: 8px;
}

.plans {
  margin-top: 24px;
  width: 100% !important;
}
.plans thead tr th {
  width: 264px;
  padding: 0 20px;
}
.plans thead .header th {
  border-bottom: 1px solid #e5e8e3;
  padding-bottom: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #242628;
  text-transform: capitalize;
}
.plans thead .header-items th {
  padding-top: 24px;
}

.plan-description {
  min-width: 225px;
}
.plans .plan-description p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #586068;
  margin-top: 16px;
  margin-bottom: 24px;
}

.plan-description h1 span:first-child {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #242628;
  margin-right: 8px;
}

.plan-description h1 span {
  font-size: 16px;
  line-height: 24px;
  color: #586068;
}

.plan-description .no-amount {
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
  margin-bottom: 30px;
  color: #242628;
}
.plan-description button {
  width: 100%;
  padding: 10px 0;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
.plan-description .current {
  background: #f7f8f7;
  border: 1px solid #f7f8f7;
  box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
  color: #e1e4dd;
  cursor: default;
}

.plans .title td {
  padding-top: 32px;
  padding-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #d28b28;
  padding-left: 24px;
}

.plans tbody .plan-item td:first-child {
  padding: 22px 24px;
}

.plans tbody tr:nth-child(even) {
  background: #f7f8f7;
}

.title {
  background-color: #fff !important;
}
