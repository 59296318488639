@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.form-label {
  font-size: 0.875rem !important;
}

.bill-empty-holder {
  display: flex;
  height: calc(100vh - 24rem);
}

.review-details__holder {
  overflow: auto;
  padding-bottom: 2rem;
  height: calc(100vh - 10rem);
}

.form-holder {
  height: calc(100vh - 14rem);
  overflow: auto;

  @include mobile {
    .dates-holder {
      flex-wrap: wrap;
      gap: unset !important;

      .form-item {
        width: 100% !important;
      }
    }
  }

  &.no-scroll {
    height: auto;
    overflow: unset;
  }

  .form-item {
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: 20px;
    }

    label {
      margin: 0 0 0.5rem 0 !important;
    }

    .custom-input {
      height: 40px;
      border-radius: 8px;
      font-size: 0.875rem !important;

      &.reference {
        height: 44px;
      }
    }

    .sub-text {
      color: #57534e;
      font-size: 0.875rem;

      .link {
        color: #d28b28;
        cursor: pointer;
        border-bottom: 1px solid #d28b28;
      }
    }
  }
}

.inner-link {
  color: #d28b28;
  font-weight: 500;
  cursor: pointer;
  animation-duration: 300ms;

  &:hover {
    color: #d28b28 !important;
    opacity: 0.7;
  }
}

.section-header {
  color: #1c1917;
  font-weight: 600;
  font-size: 1.5rem;
}

.sub-header {
  color: #79716b;
  font-size: 0.875rem;
}

.ocr-image {
  width: 100%;
  height: 40rem;

  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}

.bill-asset__holder {
  margin-top: 3rem !important;
}

.banner-skeleton .ant-skeleton-element {
  width: 100% !important;
}

.create-holder {
  display: flex;
  height: 100vh;

  @include mobile {
    flex-wrap: wrap;
  }

  .lhs {
    width: 100%;
    padding: 16px 64px;
    border-radius: 24px;
    background: #fafaf9;
    border-right: 1px solid #e7e5e4;

    .drag-region {
      margin-bottom: 1.5rem;

      &.has-file {
        border-radius: 12px;
        padding: 24px;
        background: white;
        display: flex;
        height: calc(100vh - 12rem);
        flex-direction: column;
        justify-content: center;

        .rpv-core__inner-page {
          background-color: unset !important;
        }
      }

      &.no-bg {
        background: unset;
        height: calc(100vh - 5rem);
      }

      .bill-upload__container {
        display: flex;
        align-items: center;
        height: calc(100vh - 11rem);
        background-color: white !important;

        .dropzone {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          height: calc(100vh - 11rem);
          background-color: white !important;

          input {
            cursor: pointer;
          }
        }

        .uploadFileIcon svg {
          padding: 7px;
          border-radius: 50%;
          background: #f5f5f4;
          height: 34px;
          width: 34px;
          stroke-width: 2px;
        }
      }
    }

    .email-holder {
      height: 80px;
      background-color: #f5f5f4;
      padding: 14px;
      display: flex;
      gap: 12px;
      border-radius: 12px;

      @include mobile {
        height: auto;

        .send-copy {
          flex-wrap: wrap;
        }
      }

      a {
        color: #d28b28;
        text-decoration: none;

        &:hover {
          opacity: 0.7;
        }
      }

      p {
        margin-bottom: 0;
        font-size: 0.875rem;

        &:nth-child(1) {
          color: #1c1917;
          font-weight: 500;
        }

        &:nth-child(2) {
          color: #79716b;
          padding-top: 8px;

          svg {
            cursor: pointer;
            margin-left: 2px;
          }
        }
      }
    }
  }

  .rhs {
    width: 100%;
    padding: 36px;

    .back-action {
      display: flex;
      gap: 4px;
      font-weight: 500;
      color: #d28b28;
      cursor: pointer;
    }
  }

  @include mobile {
    .lhs,
    .rhs {
      padding: 16px;
    }
  }
}

.rhs-empty__state {
  margin-top: 6rem;

  .bill-loader {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 39%;
    height: 50vh;
    margin: auto;

    .loader-text {
      font-size: 0.875rem;
      color: #44403c;
      font-weight: 500;

      &.message {
        color: #79716b;
        font-weight: 400;
      }
    }
  }

  .pattern-holder {
    height: 480px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/icons/bills/bg-pattern.svg');

    .content {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      display: flex;
      height: inherit;

      .inner-holder {
        margin-top: 4.5rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;

        .icon-holder {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          background-color: #f5f5f4;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .inner-text {
          margin-top: 20px;
          text-align: center;

          p {
            margin-bottom: 0;

            &:nth-child(1) {
              color: #1c1917;
              font-weight: 600;
            }

            &:last-child {
              color: #79716b;
              font-size: 0.875rem;
              padding-top: 4px;
            }
          }
        }
      }
    }
  }
}

.details-holder {
  border-radius: 12px;
  border: 1px solid #e7e5e4;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px #1c19170d;

  &.sub-total__bill {
    width: 63%;

    .inner-text {
      color: #1c1917;
      font-weight: 500;
      font-size: 0.875rem;
    }

    .calc-region {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 0.875rem;
      margin-bottom: 1rem;

      .inner-holder {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 60%;

        .text {
          width: 30%;
          text-align: left;
          color: #79716b;
        }
      }
    }
  }

  &.deposit-funds {
    padding: 20px;
    border-radius: 0 0 12px 12px;
  }

  @include mobile {
    padding: 12px;
    .form-group {
      padding-right: 0;
      margin-bottom: 6px;

      &.items {
        width: 40%;
      }

      &.qty {
        width: 20%;
      }

      &.price {
        width: 30%;
      }
    }

    .col-md-1 {
      width: 10%;
      padding-left: 8px;
      align-items: start !important;
      margin-top: 8px !important;

      &.mt-4 {
        align-items: center !important;
        margin-top: 12px !important;
      }
    }
  }

  &.padded {
    padding: 16px;
  }

  .line-items__holder {
    overflow: auto;
    max-height: 100px;
  }

  .textareaLabel,
  .form-label {
    color: #44403c !important;
    font-size: 0.875rem !important;
    margin-bottom: 4px !important;
    line-height: 20px !important;
  }

  &.review {
    padding: 16px;
    background: #fafaf9;

    .header {
      color: #1c1917;
      font-weight: 600;
      font-size: 1.125rem;
      margin-bottom: 0.75rem;
    }

    .sub-header {
      display: block;
      color: #1c1917;
      font-weight: 600;
      font-size: 1rem;
    }

    .sub-detail {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &.last {
        margin-bottom: 0;
      }

      .caption {
        color: #44403c;
        font-size: 0.875rem;
        font-weight: 500;

        &.item {
          display: flex;
          gap: 8px;
          align-items: center;

          .item-holder {
            width: fit-content;
            align-items: center;
            display: flex;
            height: 20px;
            text-align: center;
            color: #b93815;
            font-size: 0.75rem;
            font-weight: 500;
            background: #fef6ee;
            border-radius: 16px;
            padding: 8px;
            border: 1px solid #f9dbaf;
          }
        }
      }

      .value {
        color: #57534e;
        font-size: 0.875rem;
        margin-left: auto;
      }
    }
  }
}

.bill-action-link {
  margin: 0 4px !important;
  color: #44403c !important;
  font-weight: 500 !important;
  padding: 10px 14px !important;
  right: -6px !important;
}

.bill-skeleton {
  .ant-skeleton-element {
    width: inherit !important;
  }
}

.banner {
  gap: 12px;
  height: 50px;
  display: flex;
  padding: 14px;
  align-items: center;
  border-radius: 12px;
  background-color: #f5f5f4;

  @include mobile {
    height: auto;
  }

  .text {
    color: #1c1917;
    font-weight: 500;
    font-size: 0.875rem;

    a {
      color: #d28b28;
      text-decoration: none;
      transition-duration: 300ms;

      &:hover {
        opacity: 0.65;
      }

      &:active {
        opacity: 0.5;
      }
    }

    @include mobile {
      height: auto;
    }
  }

  .close-icon {
    cursor: pointer;
    margin-left: auto;
  }
}

.w-20 {
  width: 20% !important;
  padding-left: 0 !important;

  @include mobile {
    width: 50% !important;
  }
}

.procurement-analytics-region.bill .row {
  margin-left: 0 !important;
}

.bill-detail__items {
  padding-top: 24px;
  max-height: 10rem;
  overflow: auto;
  border-top: 1px solid #e7e5e4;

  &.unset-height {
    max-height: unset;
  }

  .header {
    font-weight: 600;
    color: #1c1917;
    font-size: 1.125rem;
  }

  .item-holder {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .item {
      display: flex;
      gap: 8px;
      align-items: center;
      color: #79716b;
      font-weight: 500;
      font-size: 0.875rem;

      .badge {
        width: fit-content;
        align-items: center;
        display: flex;
        height: 20px;
        text-align: center;
        color: #b93815;
        font-size: 0.75rem;
        font-weight: 500;
        background: #fef6ee;
        border-radius: 16px;
        border: 1px solid #f9dbaf;
      }
    }

    .value {
      margin-left: auto;
      color: #1c1917;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }
}
