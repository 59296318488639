.custom-option {
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f7f8f7 !important;
    h1 {
      background-color: #f7f8f7 !important;
    }
  }
  h1 {
    font-size: 14px !important;
    font-weight: 600;
    color: #161615;
  }
  p {
    font-size: 12px !important;
    font-weight: 400;
    color: #575855;
    margin: 0;
    padding-top: 4px;
  }
}

.selected-option {
  background-color: #f7f8f7 !important;
  h1 {
    background-color: #f7f8f7 !important;
  }
}

.custom-wrapper {
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0;
    color: #79716b;
  }
}

.limit-paragraph {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
