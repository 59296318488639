.approval-wrapper {
  width: 100%;
  position: relative;
  label {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
  }
  .select {
    width: 100%;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin-top: 5px;
    z-index: 1;
    max-height: 280px;
    overflow-y: scroll;
    position: absolute;
    z-index: 999;
    .option-wrapper {
      header {
        padding: 8px 16px;
        h1 {
          font-weight: 600;
          font-size: 14px;
          color: #000;
          margin-bottom: 0;
        }
        span {
          font-size: 14px;
          color: #d28b28;
          cursor: pointer;
        }
      }
    }
    .options {
      width: 100%;
      display: flex;
      padding: 0 16px;
      height: 44px;
      gap: 10px;
      justify-content: flex-start;
      align-items: center;
      transition: 0.6s ease-in-out all;
      span {
        font-size: 14px;
        color: #000;
        padding: 0;
      }
      input {
        height: 16px;
        width: 16px;
        border: 1px solid #e5e8e3;
        border-radius: 4px;
      }
    }
    .option-action {
      display: none;
      gap: 20px;
      span {
        cursor: pointer;
        margin: 0;
        padding: 0;
      }
    }
    .options:hover {
      background-color: #f2f4f1;
      cursor: pointer;
    }
    .newCategory-bg {
      background-color: #f2f4f1;
    }
    .options:hover .option-action {
      display: flex;
    }
    .no-option-container {
      color: #999999;
    }
  }
  .search {
    margin: 16px;
    border: 1px solid #d9ddd5;
    border-radius: 5px;
    display: flex;
    height: 36px;
    span {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: #d9ddd5;
    }
    input {
      border: none;
      color: #586068;
      padding-left: 16px;
      font-size: 14px;
    }
    input:focus {
      outline: none;
    }
  }
}

.approval-input {
  height: 44px !important;
}

.flow-chart {
  width: 42%;

  .caption {
    color: #5c5c5a;
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }

  .ant-timeline-item-head-custom {
    border-radius: unset !important;
    background: transparent !important;
  }

  .ant-timeline-item-content {
    margin: 0 0 0 46px !important;

    .timeline-header {
      top: 2px;
      color: #212120;
      font-weight: 600;
      font-size: 0.875rem;
      position: relative;
    }

    .timeline-text {
      gap: 4px;
      display: flex;
      align-items: center;

      .inner-text {
        color: #a0a09e;
        font-size: 0.875rem;
      }

      .review-region {
        gap: 8px;
        width: 86%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .reviewer-holder {
          display: flex;
          align-items: center;
          gap: 8px;

          .name {
            color: #5c5c5a;
            font-weight: 500;
          }
        }
      }

      .icon-pill {
        width: fit-content;
        height: 24px;
        background: rgba(232, 232, 231, 0.8);
        padding: 6px;
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 8px;

        .icon {
          width: 16px;
          display: flex;
          align-items: center;
        }

        .text {
          font-size: 0.875rem;
          color: #5c5c5a;
        }
      }

      .condition-two span {
        color: #a0a09e;
        font-size: 0.875rem;
      }

      .condition-three {
        gap: 8px;
        display: flex;
        align-items: center;

        .text {
          font-size: 0.875rem;
          font-weight: 500;
          color: #5c5c5a;
        }
      }
    }
  }
}

.approval-condition .form-group {
  .dropdown-select__control {
    min-height: 40px !important;
    padding: 1px 2px !important;
    border-color: #d7d3d0 !important;
    box-shadow: 0px 1px 2px 0px #1c19170d !important;

    .dropdown-select__value-container {
      padding: unset !important;

      .dropdown-select__input-container {
        margin: unset !important;
      }

      .dropdown-select__single-value {
        margin: unset !important;
        padding: 0 10px !important;
      }

      .dropdown-select__input {
        padding: 0 10px !important;
      }
    }

    .dropdown-select__placeholder {
      font-weight: 500;
      padding: 0 10px;
      margin: unset !important;
    }
  }
}

.approval-wrapper .selectInput-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  padding: 6px 12px;
  min-height: 40px;
  max-height: 200px;
  border: 1px solid #d7d3d0;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1c19170d;
  align-items: center;
  overflow-y: scroll;

  .selectedApproval-wrapper {
    width: 80%;
    cursor: pointer;
    border-radius: 4px;
    overflow-x: scroll;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex-wrap: wrap;
    column-gap: 0.35rem;
    row-gap: 0.4rem;

    span:not(.img-card) {
      position: relative;
      height: 24px;
      color: #44403c;
      font-weight: 500;
      font-size: 0.875rem;
      border: 1px solid #d7d3d0;
      border-radius: 6px;
      padding: 2px 4px;
      gap: 5px;

      svg {
        position: static;
        margin-top: 2px;
      }
    }

    .img-card {
      position: relative;
      top: 2px;
    }
  }
  .selectedApproval-wrapper::-webkit-scrollbar {
    display: none;
  }

  .dropdownIcon {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  .loading-style {
    font-size: 10px;
    position: absolute;
    top: 20px;
    right: 50px;
  }
}
