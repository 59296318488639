.beneficiaries-wrapper {
  .infinite-scroll-component {
    display: flex;
    padding-top: 24px;
    padding-bottom: 24px;
    row-gap: 50px;
    column-gap: 24px;
    flex-wrap: wrap;
    height: 100%;
    width: 100% !important;
  }
}

.no-data {
  padding: 0 !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  .textHeader {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin: 5px 0;
  }
  .subHeaderText {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
  }
}

.statement-download-action {
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
  }
}
