.add-beneficiary {
  background-color: #fff;
  border-top: 2px solid #eee !important;
  border: none;
  color: #d28b28;
  border-radius: 0 0 10px 10px;
  padding: 20px 10px 25px 10px;
  display: flex;
  font-weight: 300;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 10px;
  cursor: pointer;
}

.Select__menu-list {
  max-height: 1px !important;
}

.select-all-budgets {
  background-color: white;
  border: none;
  border-bottom: 1px solid #eff0ed;
  padding: 14px;
  width: 100%;
  color: #161615;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.avatar__card-initials {
  display: flex;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 100%;
  background: #bfff0b;
  padding: 3px;

  h5 {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #57534e;
    margin: 0px;
  }
}
