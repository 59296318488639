.billing-wrapper {
  .title {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    gap: 8px;
    margin: 0;
    padding: 0;
    align-items: center;
    margin-bottom: 4px;
    text-transform: capitalize;
    span {
      font-size: 14px;
      line-height: 20px;
      color: #414240;
      padding: 2px 10px;
      background: #f0f1ee;
      mix-blend-mode: multiply;
      border-radius: 16px;
    }
  }
  .body,
  .next-billing {
    font-size: 14px;
    line-height: 20px;
    color: #575855;
  }
  .next-billing {
    display: block;
    margin: 18px 0;
  }
  .padding-bottom {
    padding-bottom: 53px;
  }
  .billing-card-style {
    border: 1px solid #eaecf0;
    border-radius: 12px;
    gap: 38px;
    main {
      padding: 24px 24px 16px 24px;
    }
    footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaecf0;
      padding: 16px 24px;
      button {
        padding: 10px 16px;
        border-radius: 8px;
      }
      button:first-child {
        background: #ffffff;
        border: 1px solid #e1e4dd;
        box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
      }
      button:last-child {
        background: #000000;
        border: 1px solid #000000;
        color: #fff;
      }
    }
    .active-method {
      padding: 16px;
      border: 1px solid #eaecf0;
      border-radius: 8px;
      margin-top: 20px;
    }
    .lhs {
      display: flex;
      align-items: center;
      gap: 16px;
      span {
        display: flex;
        width: 58px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border: 1px solid #e8ebe6;
        box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
        border-radius: 8px;
      }
      h6 {
        margin: 0px;
      }
    }
    .rhs {
      button {
        padding: 10px 16px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #414240;
      }
    }
  }

  .price {
    h1 {
      display: flex;
      margin: 0;
      padding: 0;
      span:first-child {
        font-size: 24px;
        margin-right: 8px;
        color: #242628;
        padding: 0;
      }
      span:last-child {
        font-size: 16px;
        color: #575855;
        margin: 0;
        padding: 0;
        align-self: flex-end;
      }
    }
  }
  .billing-email-address {
    margin-top: 32px;
    h1 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #161615;
      margin: 0;
      padding: 0;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #575855;
    }
    span {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 16px;
      cursor: pointer;
    }
  }

  .email-address {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #161615;
    background: #ffffff;
    border: 1px solid #e1e4dd;
    box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
    border-radius: 8px;
    width: 512px;
    padding: 10px 16px;
  }
  .new-email-container {
    display: flex;
    gap: 16px;
    .email-address {
      width: 394px;
      outline: none;
    }
    .email-addressBtn {
      padding: 10px 18px;
      display: flex;
      gap: 8px;
      align-items: center;
      background: #ffffff;
      border: 1px solid #e5e8e3;
      box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05);
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #e5e8e3;
      cursor: pointer;
    }
  }
  .invoice-history-table {
    margin: 38px 0;
    .header {
      h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #161615;
      }
    }
  }
}
.modal-content {
  .cancel-modal-wrapper {
    padding: 24px;
    header {
      display: flex;
    }
  }
  .cancel {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e8ebe6;
    box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
    border-radius: 10px;
  }
  main {
    margin-top: 5px;
    h1 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #161615;
      margin: 0;
      padding: 0;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #575855;
      margin: 4px 0 0 0;
    }
    .reason-list {
      margin-top: 20px;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .list-item {
      font-size: 14px;
      line-height: 20px;
      color: #414240;
      cursor: pointer;
    }
    .active {
      background: #fbf5ec;
      border: 2px solid #d28b28;
      h6 {
        color: #885a1a;
      }
    }
    .checkbox {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 8px;
    }
    .unchecked {
      border: 1px solid #e1e4dd;
    }
    .checked {
      background-color: #d28b28;
    }
  }
  footer {
    display: flex;
    gap: 12px;
    button {
      flex: 1;
      border-radius: 8px;
      padding: 8px 18px;
      font-size: 16px;
      line-height: 24px;
    }
    button:first-child {
      background: #ffffff;
      border: 1px solid #e1e4dd;
      box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
      color: #414240;
    }
    button:last-child {
      background: #000000;
      color: #fff;
      border: 1px solid #000000;
    }
  }

  .danger {
    button:last-child {
      background: #d92d20;
      color: #fff;
      border: 1px solid #d92d20;
    }
  }
}
