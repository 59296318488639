.import-component_table th {
  background: #f5f5f4 !important;
  color: #79716b !important;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 8px !important;
  border-top: 1px solid #e7e5e4;

  &.first-column {
    padding-left: 24px !important;
  }

  &.last-column {
    padding-right: 16px;
  }
}

.import-component_table td {
  color: #79716b !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  vertical-align: middle;

  &.first-column {
    padding-left: 24px;
    color: #1c1917 !important;
    font-weight: 500 !important;
  }

  &.last-column {
    padding-right: 16px;
  }
}

.upload-preview_table {
  .table-wrapper__border {
    border-radius: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;

    th {
      border-radius: 0px !important;

      &:first-child {
        padding-left: 24px !important;
      }
      &:last-child {
        padding-left: 16px !important;
      }
    }

    td {
      &:first-child {
        padding-left: 24px !important;
      }

      &:last-child {
        padding-right: 16px !important;
      }
    }
  }
}
