@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.metrics__wrapper {
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  min-width: 0;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #e7e5e4;
  box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);

  @include mobile {
    margin-bottom: 0.75rem !important;
  }

  .metrics__wrapper-flex {
    padding: 16px;
    flex: 1;
  }

  .label {
    p {
      white-space: break-spaces;
      color: #57534e;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .value {
    h2 {
      color: #1c1917;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      margin: 0px;
    }
  }

  @media (min-width: 1280px) {
    margin-bottom: 0;
  }
}
