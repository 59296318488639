.amount {
  padding-left: 12px !important;
}
.hideLabel {
  top: 16px !important;
}
.active-action {
  background: #e8f0fe;
}

.not-select {
  cursor: not-allowed;
}
.selector {
  outline: none !important;
  border-width: 0;
  :focus-visible {
    outline: none !important;
  }
}

.currency-region {
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-left: 1px solid #e8e8e7;
  position: absolute;
  height: 40px;

  &.bujeti-pay {
    padding: 0 24px;
  }

  &.has-label {
    top: 30px;
  }
}

.customer-Phone__input-holder {
  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    color: unset !important;
  }

  &.customer-Phone__input {
    label {
      font-size: 0.875rem;
      color: #44403c !important;
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 0.5rem;
      margin-top: 0rem;
    }

    .PhoneInputDiv {
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px #1c19170d;
      padding: 2px 12px;
      height: 40px;
      font-size: 0.875rem;
    }

    .PhoneInputCountry {
      gap: 6px;
      .PhoneInputCountryIcon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: unset;

        img {
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .PhoneInputCountrySelectArrow {
        width: 7px;
        height: 7px;
      }
    }

    .PhoneInputInput {
      height: 32px;
      padding-left: 12px;
      margin-left: 6px;
    }
  }
}
