.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #12b76a;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 0.5em;
  font-weight: bold;
  fill: #12b76a;
}
