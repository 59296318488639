.card-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 10px;

  h1 {
    color: #161615;
    font-weight: 600;
    font-size: 20px;
    margin-top: 24px;
  }
}

.custom-roles {
  padding-bottom: 50px;
  overflow: scroll;

  .permissions {
    h1 {
      font-size: 14px;
      font-weight: 600;
      color: #242628;
      text-transform: capitalize;
    }

    .persmissions-list,
    .permissions-list {
      p {
        margin-bottom: 0px;
        font-size: 14px;
        color: #242628c7;
        // text-transform: capitalize;
      }

      padding-top: 14px;
      padding-bottom: 14px;
      border-bottom: 1px solid #eff0ed;
    }
  }

  .ant-switch {
    background-color: #f3f4f6 !important;
  }

  .ant-switch-checked {
    background-color: #242628 !important;
  }
}

.modal--footer {
  background-color: white;
  justify-content: flex-end;
  display: flex;
  position: fixed;
  bottom: 0px;
  padding: 16px 0;
  width: 420px;
  gap: 12px;

  .custom-button {
    padding: 10px !important;
    width: 87px;
    height: 40px !important;
  }

  .ghost-button {
    background-color: white !important;
    border: 1px solid #e1e4dd !important;
    box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05) !important;
    color: #242628 !important;
  }
}

.roles_permissions td:nth-child(3) .string-type-font {
  display: flex;
  white-space: normal;
  text-overflow: ellipsis;
}

.roles_permissions th,
.roles_permissions td {
  padding: 12px 16px !important;
}

.roles_permissions tbody tr:last-child td {
  border-bottom: 0 !important;
}

.ant-switch-loading,
.ant-switch-disabled {
  opacity: 1 !important;
}

.roles-table {
  .table-wrapper {
    .table-responsive {
      max-height: calc(100vh - 19rem) !important;
    }
  }
}
