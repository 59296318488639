.spinner-1 {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #000 #0000;
  animation: s1 1s infinite;
  margin-right: 3px;
}
@keyframes s1 {
  to {
    transform: rotate(0.5turn);
  }
}

.spinner-3 {
  width: 10px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #bfff0b;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

.account-dropdown {
  position: absolute;
  // z-index: 1;
  width: 84%;
  border-radius: 10px;
  pointer-events: none;
  opacity: 0;
}

.account-list {
  padding: 6px 0;
  color: gray;
  list-style: none;
}

.account-button {
  display: inline-flex;
  padding: 12px 8px;
  width: 100%;
  font-size: 14px;
  color: black;
  appearance: none !important;
  border-radius: 6px !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.account-button:hover {
  background-color: rgb(235, 245, 255);
}

.account-visible {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  background-color: #e9ecef !important;
}
