.amount {
  padding-left: 75px !important;
}
.hideLabel {
  top: 16px !important;
}
.isDisabled {
  cursor: not-allowed;
  pointer-events: none;
}
.active-action {
  background: #e8f0fe;
}
.currencySymbol {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #212120;
  font-size: 0.875rem;

  .flag-icon img {
    box-shadow: 0px -1px 4px 0px #ffffff3d inset, 4px -8px 12px 0px #0000001f inset,
      0px 4px 8px 0px #c3ffd91f inset;
  }
}

.amount-placeholder {
  color: #bfbfbd;
  font-size: 0.875rem;
  position: absolute;
  top: 0px;
  left: 12px;
  height: 40px;
  display: flex;
  align-items: center;

  &.bujeti-pay {
    left: 24px;
  }

  &.has-label {
    top: 31px;
  }
}

.amount-icon {
  position: absolute;
  top: 55px;
  left: 30px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  color: #606060;
  span {
    font-size: 12px;
    margin-top: 4px;
    margin-left: 6px;
  }
  .amount-sign {
    padding-right: 10px;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    text-align: center;
    margin-top: -13px;
  }
}
.not-select {
  cursor: not-allowed;
}
.selector {
  outline: none !important;
  border-width: 0;
  :focus-visible {
    outline: none !important;
  }
}
