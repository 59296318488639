@import '../../scss/base/colors.scss';
@import '../../scss/typography.scss';

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.invoice-image-holder {
  height: 48px;

  img {
    height: inherit;
    object-fit: contain;
  }
}

.invoice-attachment {
  overflow-x: auto;
  width: 45%;

  .receipt-asset-wrapper {
    div:first-child {
      margin-top: 0px !important;
      margin-bottom: 0 !important;
    }

    .fileUpload .add-more.lg {
      height: 184px;
    }
  }
}

.powered-holder {
  height: 16px;

  img {
    height: inherit;
    object-fit: contain;
  }
}

.w-35 {
  width: 35%;
}

.invoice-number {
  color: #57534e;
  font-size: 0.75rem;
  margin-top: -5px;
  margin-bottom: 0px;
  font-weight: 500;
}

.invoice-status-display {
  padding: 0 1rem;
  @include mobile {
    padding: 0;
  }
}

.invoice-settings__holder {
  width: 75%;
  display: flex;
  justify-content: center;
  overflow: auto;
  height: calc(100vh - 2rem);
  margin: 32px auto 48px auto;

  @include mobile {
    width: 100%;
    margin-bottom: 0;
  }

  .coverage-area {
    width: 620px;

    @include mobile {
      width: 100%;
    }
  }

  .header-region {
    margin-top: 24px;
    .caption {
      color: #1c1917;
      font-weight: 600;
      font-size: 1.875rem;
      margin-bottom: 0.25rem;
    }
    .sub-text {
      color: #79716b;
    }
  }

  .main-area {
    // overflow-y: auto;
    padding-top: 1rem;
    // height: calc(100vh - 16rem);

    .form-area {
      padding: 24px;
      border-radius: 12px;
      border: 1px solid #e7e5e4;
      box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);

      .companyLogo-wrapper {
        gap: 20px;
        padding-bottom: 1rem;

        button {
          color: #57534e;
          margin-top: 12px;
          padding: 0 8px !important;
        }

        .logo-holder {
          height: 64px;
          width: 64px;
          img {
            width: inherit;
            height: inherit;
            object-fit: contain;
            border-radius: 100%;
          }
        }

        p {
          margin-bottom: 0;

          &.cta-text {
            line-height: 20px;
            color: #44403c;
            font-weight: 500;
            font-size: 0.875rem;
            padding-bottom: 4px;
          }

          &.cta-sub-text {
            line-height: 16px;
            color: #79716b;
            font-size: 0.75rem;
          }
        }
      }

      .form-label {
        margin-bottom: 0.25rem !important;
      }
    }

    .template-area {
      padding-bottom: 2.5rem;
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid #e4e7ec;

      p {
        margin-bottom: 0;

        &.header {
          gap: 8px;
          display: flex;
          color: #212120;
          font-weight: 600;
          margin-bottom: 4px;
          align-items: center;
          font-size: 1.125rem;
        }

        &.sub-text {
          font-size: 0.875rem;
          color: #7f7f7d;
        }
      }

      .selector-arena {
        margin-top: 20px;
      }
    }
  }

  .action-button-region {
    gap: 12px;
    display: flex;
    justify-content: end;
    padding: 20px 0;
    border-top: 1px solid #e7e5e4;

    background: white;
    width: inherit;
    position: sticky;
    bottom: 0;
    z-index: 100;

    @include mobile {
      width: 100%;
    }

    .add-custom {
      padding: 0 1rem !important;
    }
  }
}

.template-picker__holder {
  overflow: hidden;

  .pick-area {
    gap: 12px;
    display: flex;
    margin: 4px;

    @include mobile {
      margin: 0;
    }
  }

  .template-card {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #f5f5f4;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    @include mobile {
      padding: 10px;
    }

    .template-icon {
      width: 20px;
      position: relative;
      overflow: hidden;

      .active-icon {
        z-index: 10;
        transition: ease 400ms;
        position: absolute;
      }

      .filler-icon {
        opacity: 0;
        transition: ease 400ms;
      }
    }

    .template-name {
      color: #44403c;
      font-weight: 500;
      font-size: 0.875rem;
      margin-right: auto;
      margin-bottom: 0;
    }

    &:hover:not(.is-active) {
      transform: translateY(-2px);
      background-color: white;
      border: 1px solid #e8e8e7;
      box-shadow: 0px 3.304px 4.45px 0px rgba(0, 0, 0, 0.12),
        0px 0.59px 1.435px 0px rgba(0, 0, 0, 0.18);

      .template-name {
        font-weight: 600;
      }

      .filler-icon {
        opacity: 1;
      }

      .active-icon {
        transform: translateX(20px);
      }
    }

    &.is-active {
      z-index: 10;
      transform: scale(1.02);
      transform: translateY(-2px);
      background-color: #faffec;
      border: 1px solid #edff9d;
      box-shadow: 0px 42px 83px 0px rgba(0, 0, 0, 0.05),
        0px 18.258px 25.252px 0px rgba(0, 0, 0, 0.08),
        0px 8.661px 10.637px 0px rgba(0, 0, 0, 0.1);

      .filler-icon {
        opacity: 1;
      }

      .active-icon {
        transform: translateX(20px);
      }
    }
  }

  .template-carousel-holder {
    position: relative;
    margin-top: 20px;
    height: 480px;
    border-radius: 8px;
    background-color: #f5f5f4;

    &.full-height {
      overflow-y: auto;
      height: 880px;
      padding-bottom: 5rem;
    }

    .line-union {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .carousel-container {
      position: relative;
      width: 100%;
      height: 480px;
      perspective: 2000px;

      &.is-demo {
        height: 500px;
      }

      .content-stage {
        position: relative;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;

        .template-slide {
          position: absolute;
          width: 100%;
          height: 100%;
          transform-origin: center center;
          backface-visibility: hidden;
          border-radius: 8px;
          will-change: transform;
          transform-style: preserve-3d;
          cursor: grabbing;

          &.is-demo {
            margin: 20px 0;

            .invoice-preview {
              @include mobile {
                margin-left: -70px;
                width: calc(100vw + 9rem);
              }
            }
          }
        }
      }
    }
  }
}

.recurring-holder {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #f5f5f4;
}

.recurring-text {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #44403c;

  &.alt {
    color: #57534e;
    font-weight: 400;
  }
}

.invoice-switch {
  min-width: 36px !important;

  &.ant-switch-checked {
    background-color: #d28b28;

    .ant-switch-handle {
      left: calc(100% - 18px - 0px);
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .ant-switch-handle {
    top: 3px;
    height: 16px;
    width: 16px;
  }
}

.procurement-analytics-region {
  margin-top: 24px;

  .card {
    border-radius: 12px;
    border: 1px solid #e7e5e4;
    min-height: 98px;
    padding: 1rem;
    box-shadow: 0px 1px 2px 0px #1c19170d;

    &.bill {
      min-height: 88px;
      cursor: pointer;
      transition-duration: 300ms;

      &:active {
        scale: (1.01);
      }
    }

    .caption {
      color: #57534e;
      font-weight: 500;
      font-size: 0.875rem;
      margin-bottom: 0.25rem;

      &.bill {
        margin-bottom: 0.5rem;
      }
    }

    .value {
      color: #1c1917;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0;

      &.bill {
        font-size: 1.125rem;
      }
    }
  }
}

.back-click {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #d28b28;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
}

.create__invoice-container {
  .headerText {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #1c1917 !important;
    //text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    &.large {
      padding-top: 10px;
      font-size: 35px;
    }
  }
  .subtext {
    font-weight: 400;
    font-size: 0.875rem;
    color: #79716b;
    line-height: 24px;
    padding-top: 4px;
  }

  .padding-14 {
    padding: 0px 0 !important;
  }

  .add-button {
    &.item {
      height: 32px !important;
      padding: 0 8px;
    }

    &.none {
      border: none !important;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  .uploadFileBorder {
    background-color: #ffffff;
    border: 1px dashed #d7d3d0;
  }

  .region-header {
    color: #1c1917;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .customer-holder {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 40%;

    .add-button {
      margin-top: 32px;
    }
  }

  .details-holder-width {
    width: 80%;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .details-holder {
    padding: 24px;
    margin-top: 24px;
    border-radius: 12px;
    border: 1px solid #e7e5e4;
    box-shadow: 0px 1px 2px 0px #1c19170d;

    &.product-row {
      overflow-y: auto;
      max-height: 52rem;

      .form-label {
        color: #212120;
      }

      .delete-icon {
        right: 0;
        top: 30px;
        position: absolute;
      }
    }

    .row-border {
      padding-bottom: 1rem;
      border-bottom: 1px solid #e8e8e7;

      &.is-last {
        padding-bottom: unset;
        border-bottom: unset;
      }
    }

    .line-item-description {
      textarea {
        width: 92%;
        resize: vertical !important;
        min-height: 64px !important;

        @include mobile {
          width: 100%;
        }
      }
    }

    &.padded {
      padding: 16px;
    }

    label:not(.ant-checkbox-wrapper) {
      color: #44403c;
      font-weight: 500;
      font-size: 0.875rem;
      display: block;
      margin: 0;
      margin-bottom: 4px;
      line-height: 20px;

      span {
        color: #79716b;
        font-weight: 400;
      }
    }

    &.sub-total {
      width: 63%;

      @media screen and (max-width: 480px) {
        width: initial;
      }
    }

    .inner-text {
      color: #1c1917;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  .w-55 {
    width: 55%;

    @media screen and (max-width: 480px) {
      width: initial;
    }
  }

  .w-60 {
    width: 60%;
    @media screen {
      width: 65%;
    }
  }

  .tax-disclaimer {
    width: 80%;
    margin: 0 30px;
    color: #79716b;
    font-size: 0.75rem;
  }

  .calc-region {
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 0.875rem;

    .inner-holder {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 45%;

      @include mobile {
        width: 100%;
      }
    }

    .text {
      color: #79716b;
      width: 37%;
    }

    .value {
      color: #57534e;
      margin-left: auto;
    }
  }

  .instalment {
    color: #79716b;
    font-size: 0.875rem;
    width: max-content;
  }

  .form-label,
  .textareaLabel {
    font-weight: 500;
    color: #44403c !important;
    font-size: 0.875rem !important;
    margin-bottom: 4px !important;
    line-height: 20px !important;

    &.amount {
      color: #79716b;
    }
  }

  .justify-self-end {
    justify-self: end;
  }

  .total-text {
    color: $grey-900;
    font-size: 20px;
    font-style: bold;
    font-weight: 600;
  }

  .companyLogo-wrapper {
    display: flex;
    gap: 24px;

    .img-container {
      width: 50px;
      height: 40px;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    button {
      background: transparent;
      border: none;
      color: $secondary-500;
      font-size: 14px;
    }
  }
}

.amount-holder {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid #e7e5e4;
  background-color: #f5f5f4;

  span {
    color: #a9a29d !important;

    &.value {
      color: #1c1917 !important;
    }
  }
}

.add-item {
  gap: 4px;
  background: unset;
  border: none;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: #d28b28;
}

#invoice-date {
  height: 40px;
  box-shadow: 0px 1px 2px 0px #1c19170d;
  border-radius: 8px;

  &.disabled {
    background: #f5f5f4 !important;
    box-shadow: none;
  }

  input {
    color: #1c1917;
    height: auto;
    font-size: 0.875rem;
    box-shadow: none;
  }
}

.invoice-details-pad {
  padding: 0 32px;

  @include mobile {
    padding: 0px 16px;
  }
}

.invoice-details-header {
  color: #1c1917;
  font-weight: 600;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.customer-details-region {
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatar {
    height: 56px;
    width: 56px;
    background-color: #f5f5f4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #44403c;
    font-size: 1.125rem;
    font-weight: 500;
  }

  .invoice-amount {
    font-size: 1.875rem;
    padding-top: 0.875rem;
    color: #1c1917;
    font-weight: 600;
  }

  .customer {
    font-size: 1rem;
    color: #1c1917;
    font-weight: 500;
  }

  &.invoice {
    display: block;
  }
}

.invoice-detail-holder {
  background-color: #fafaf9;
  padding: 24px 32px;

  @include mobile {
    padding: 24px 16px;
  }

  &.minHeight {
    min-height: calc(100vh - 20rem);
  }

  .detail {
    display: flex;
    margin-bottom: 1rem;

    .avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #bfff0b;
      font-weight: 500;
      font-size: 0.625rem;
      color: #1c1917;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .header {
      color: #79716b;
      font-weight: 500;
      font-size: 0.875rem;
    }

    .value {
      color: #1c1917;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
}

.action-button {
  border: 1px solid #d7d3d0;
  background: white;
  border-radius: 8px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #57534e;
  font-weight: 500;
  font-size: 0.875rem;
  width: 100%;
  gap: 8px;
  height: 32px;
  transition-duration: 300ms;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.6;
  }
}

.timeline-region {
  border-top: 1px solid #e7e5e4;
  padding-top: 1.75rem;

  &.unset {
    border-top: none;
  }

  .timeline-header {
    color: #79716b;
    font-weight: 500;
    font-size: 0.875rem;
    display: block;
    padding-bottom: 6px;
  }

  .timeline-text {
    color: #1c1917;
    font-weight: 500;
    font-size: 0.875rem;
    gap: 8px;
    display: flex;
    align-items: center;

    &.small {
      color: #79716b;
      font-weight: 400;
      font-size: 0.75rem;
    }
  }
}

.payment-schedule-region {
  border-top: 1px solid #e7e5e4;
  padding-top: 1.75rem;

  .header {
    font-weight: 600;
    color: #1c1917;
    font-size: 1.125rem;
  }

  .schedule-holder {
    display: flex;

    .pay-action {
      cursor: pointer;
      color: #d28b28;
      margin-bottom: 2px;
      align-self: end;
      text-align: center;
      font-size: 12px;
      transition-duration: 300ms;

      &:hover {
        opacity: 0.6;
      }

      &.is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .head {
      color: #1c1917;
      font-weight: 500;
      font-size: 0.875rem;
      display: block;
    }

    .due {
      color: #79716b;
      font-weight: 400;
      font-size: 0.75rem;
    }

    .status {
      color: #a9a29d;
      font-size: 0.75rem;

      &.paid {
        color: #039855;
      }
    }
  }
}

.schedule-holder {
  display: flex;

  .pay-action {
    cursor: pointer;
    color: #d28b28;
    margin-bottom: 2px;
    align-self: end;
    text-align: center;
    font-size: 12px;
    transition-duration: 300ms;

    &:hover {
      opacity: 0.6;
    }

    &.is-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .head {
    color: #1c1917;
    font-weight: 500;
    font-size: 0.875rem;
    display: block;
  }

  .due {
    color: #79716b;
    font-weight: 400;
    font-size: 0.75rem;
  }

  .status {
    color: #a9a29d;
    font-size: 0.75rem;

    &.paid {
      color: #039855;
    }
  }
}

.footer-region {
  border-top: 1px solid #e7e5e4;
  background-color: white;
  height: 72px;
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;
  position: sticky;
  z-index: 50;
  gap: 12px;

  &.unset {
    height: auto;
    position: fixed;
    padding: 12px 26px;
  }

  .add-action {
    border: 1px solid #d7d3d0 !important;
    color: #57534e !important;
    font-weight: 500;
  }
}

.ant-timeline-item-head-custom {
  border-radius: 12px !important;
  width: 24px !important;
  height: 24px !important;
  background: #f5f5f4 !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ant-timeline-item-content {
  margin: 0 0 0 30px !important;
}

.ant-timeline-item-last {
  top: 8px;
  padding-bottom: 0 !important;
}

.ant-timeline-item-tail {
  height: calc(100% - 22px) !important;
  top: 20px !important;
}

.invoice-select__control {
  border-radius: 8px !important;
  min-height: unset !important;
  height: 40px;
  padding: 0 3px !important;
  box-shadow: 0px 1px 2px 0px #1c19170d;
  cursor: pointer;

  .invoice-select__input {
    min-width: max-content !important;
    font-size: 0.875rem !important;
  }

  &.invoice-select__control--is-focused {
    box-shadow: 0 0 0 0.25rem rgba(36, 38, 40, 0.1294117647) !important;
  }

  .invoice-select__indicator {
    transition: transform 0.3s ease-in-out;
  }

  &.invoice-select__control--menu-is-open .invoice-select__indicator {
    transform: rotateX(180deg);
  }

  .invoice-select__single-value {
    color: #1c1917 !important;
    font-weight: 400;
    font-size: 0.875rem;

    &.is-placeholder {
      color: #a9a29d !important;
    }
  }

  .invoice-select__placeholder {
    color: #a9a29d;
    font-size: 0.875rem;
  }
}

.invoice-recurrence-option {
  display: flex;
  align-items: center;
  width: 100%;

  h1 {
    color: #1c1917;
    font-size: 0.875rem;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
  }

  span {
    color: #79716b;
    margin-left: auto;
    font-size: 0.875rem;
    font-weight: 400 !important;
  }
}

.invoice-select__menu {
  box-shadow: 0px 12px 16px -4px #1c191714;
  border: 1px solid #e7e5e4;
  background-color: #ffffff;
  border-radius: 8px;

  .invoice-select__option {
    font-weight: 400;
    color: #1c1917;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 8px;
    margin: 0 4px;
    border-bottom: none;

    &:hover {
      background: #f5f5f4;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  .invoice-select__option--is-disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: hsl(0, 0%, 80%);
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.analytics-region {
  margin-top: 24px;
}

.analytics-region .card {
  border-radius: 12px;
  border: 1px solid #e7e5e4;
  min-height: 98px;
  padding: 1rem;
  box-shadow: 0 1px 2px #1c19170d;
}

.analytics-region .card .caption {
  color: #57534e;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.analytics-region .card .value {
  color: #1c1917;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0;
}

.form-group {
  .form-label {
    font-size: 0.875rem;
    color: #44403c !important;
    font-weight: 500;
    cursor: pointer;
    margin: unset !important;
    margin-bottom: 0.5rem !important;

    &.grey {
      color: #79716b !important;
    }

    &.custom {
      margin-bottom: 4px !important;
    }
  }

  input {
    height: 40px;
    font-size: 0.875rem !important;
    box-shadow: 0px 1px 2px 0px #1c19170d !important;
    border-radius: 8px;

    &:disabled {
      background: #f5f5f4;
      color: #d7d3d0;
      box-shadow: none;
    }
  }

  textarea {
    box-shadow: 0px 1px 2px 0px #1c19170d;
    border-radius: 8px;
    color: #1c1917;
    font-size: 0.875rem;
    font-weight: 400 !important;
    line-height: 28px;

    &::placeholder {
      font-size: 0.875rem;
    }

    &:disabled {
      background: #ffffff;
    }
  }

  &.custom {
    input {
      height: unset !important;
    }
  }
}

.grid-col-2 {
  display: grid;
  gap: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.grid-col-3 {
  display: grid;
  gap: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.grid-col-5 {
  display: grid;
  gap: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-3 {
  grid-column-start: 3;
}
.col-start-4 {
  grid-column-start: 4;
}
.col-start-5 {
  grid-column-start: 5;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.lh-0 {
  line-height: 0px;
}
.pb-1_5 {
  padding-bottom: 8px;
}

.updload-wrap {
  padding: 0px !important;
  margin: 0px !important;
}

.mt-32 {
  margin-top: 24px !important;
}

/* Styles for dropdown button */
.dropdown-btn {
  // width: 60% !important;
  display: inline-block;
  padding: 0px 10px;
  background-color: #000;
  color: white;
  height: 36px;
  font-size: 0.875rem;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
}

.toggle-icon {
  transition: transform 0.3s ease-in-out;

  &.is-toggled {
    transition: transform 0.3s ease-in-out;
    transform: rotateX(180deg);
  }
}

.invoice-table {
  .table-wrapper {
    .table-responsive {
      max-height: calc(100vh - 18rem) !important;
    }
  }
}

.action-btn {
  display: inline-block;
  font-weight: 500;
  background-color: #000;
  color: white;
  font-size: 0.875rem;
  border-right: 1px solid #44403c !important;
  border-radius: 8px 0px 0px 8px !important;
  cursor: pointer;

  &.is-plain {
    padding: 0 24px;
    border-radius: 8px !important;
  }

  @media (max-width: 768px) {
    width: 70% !important;
  }
}

.new-template-tag {
  padding: 4px;
  height: 16px;
  display: flex;
  margin-top: 2px;
  color: #44403c;
  font-weight: 500;
  border-radius: 6px;
  font-size: 0.625rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8ff62;
  background-color: #f5ffd9;
}
