.preview-card__transfer {
  background: #fafaf7;
  height: auto;
  border-radius: 8px;
  border: 1px #eff0ed solid;
  padding: 10px 0px;

  hr {
    border: 1px #eff0ed solid;
  }
}

.items-wrapper__transfer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;

  .edit-action__transfer {
    font-weight: 500;
    color: #d28b28;
  }

  .header__transfer {
    font-weight: 600;
    font-size: 18px;
    color: #161615;
  }

  .title__transfer {
    color: #414240;
    font-size: 14px;
    font-weight: 500;
  }

  .value__transfer {
    color: #575855;
    font-size: 14px;
    font-weight: 400;
  }
}

.toggle-email {
  span {
    color: #575855;
    font-size: 13px;
    font-weight: 400;
  }

  div {
    color: #575855;
    font-size: 14px;
    font-weight: 400;
  }
}

.payment__schedule-wrapper {
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d1d3cf;
  .schedule-label {
    h6 {
      color: #414240;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 2px;
    }
    p {
      color: #575855;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 0px;
    }
  }
}

.bujeti-pay-modal {
  .modal_visible__nYsri .modal_modal__q21-u {
    @media screen and (max-width: 480px) {
      padding: 16px !important;
    }
  }
}

.modal_modal__wrap__al870 {
  z-index: 10000000000;
}
