.modal-component {
  position: relative;
  .opacity-100 {
    opacity: 1;
  }
  .pointer-events-none {
    pointer-events: none;
  }

  .opacity-0 {
    opacity: 0;
  }

  .inset-0 {
    inset: 0px;
  }

  .overlay {
    position: fixed;
    inset: 0;
    z-index: 50;
    transform: translate(0, 0) rotate(0deg) skewX(0deg) skewY(0deg) scaleX(1) scaleY(1);
    background-color: rgb(0 0 0 / 0.6);

    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    backdrop-filter: blur(0.7px);
  }

  .modal-container-side {
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    z-index: 99;
    display: flex;
    width: 100%;
    height: 100%;

    transform: translate(0, 0) rotate(0deg) skewX(0deg) skewY(0deg) scaleX(1) scaleY(1);

    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    flex-direction: column;
    align-items: center;
    overflow-x: scroll;
    overflow-y: scroll;
    background: rgba(255, 255, 255, 0.5);

    @media (min-width: 768px) {
      width: fit-content;
    }

    .modal-container-child {
      position: relative;
      margin: auto 0;
      height: 100%;
      width: 100%;
      max-width: 31rem;
      min-width: 100%;
      transform-origin: bottom;
      padding-top: 5.5rem;

      .bg-gray-200 {
        background: #f5f5f4;
      }

      @media (min-width: 768px) {
        min-width: 31rem;
      }
    }
  }

  .invoice-modal-wrapper {
    .modal-container-child {
      padding-top: 2rem;
    }
  }

  .active-container {
    transform: translate(0, 0) rotate(0deg) skewX(0deg) skewY(0deg) scaleX(1) scaleY(1);
    @media (min-width: 768px) {
      transform: translate(0px, 0) rotate(0deg) skewX(0deg) skewY(0deg) scaleX(1)
        scaleY(1);
    }
  }

  .inactive-container {
    pointer-events: none;
    transform: translate(0px, 100%) rotate(0deg) skewX(0deg) skewY(0deg) scaleX(1)
      scaleY(1);

    @media (min-width: 768px) {
      transform: translate(100%, 0) rotate(0deg) skewX(0deg) skewY(0deg) scaleX(1)
        scaleY(1);
    }
  }

  .modal-container-center {
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: scroll;
    overflow-y: scroll;
    background: rgba(229, 231, 235, 0.5);
    backdrop-filter: blur(0.7px);
    padding: 1rem 6rem;

    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    @media (min-width: 640px) {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    .modal-container-child {
      margin: auto 0;
      width: 91.666667%;
      transform-origin: bottom;
      border-radius: 10px;
      padding-top: 1.5rem;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 500ms;

      @media (min-width: 1280px) {
        padding-top: 2.5rem;
      }
    }
  }

  .stacked-modal {
    transform: translateX(-6%);
    z-index: 50;
    height: 95vh;
    top: 2.5%;
    border-radius: 8px;
    background: #f5f5f4 !important;
    box-shadow: 0px 7.67px 7.67px -3.83px #1c191708, 0px 19.17px 23px -3.83px #1c191714;
  }
}
