.lines {
  background: #eccc9e;
  height: 7px;
  position: relative;

  div {
    position: absolute;
    height: 10px;
    top: 0;
    left: 0;
  }
}

.text-uppercase.badge-inherit {
  background-color: #dbffce !important;
  font-size: 10px;
  margin: 0 5px;
  border-radius: 35px;
  line-height: 1.3;
}

.teams-header {
  h2 {
    font-size: 25px;
    line-height: 35px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  span {
    font-size: 15px;
    line-height: 20px;
  }

  position: relative;

  .more-outline {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.overdraft {
  background-color: #f55d42;
}

.available-dot {
  background-color: #eccc9e !important;
}

.spent-dot,
.spent-line {
  background-color: #af7421 !important;
}

.disbursed-dot,
.disbursed-line {
  background-color: #f38744 !important;
}

.active-line {
  background: #d28b28;
}

.silver {
  background: #bfc6b9;
}

.available-dot {
  background-color: #eccc9e !important;
}

.spent-dot,
.spent-line {
  background-color: #af7421 !important;
}

.disbursed-dot,
.disbursed-line {
  background-color: #dea250 !important;
}

.light-silver {
  background: #e6e8e3;
}

.teams-amount {
  color: #586068;
  margin-right: 8%;
  margin-bottom: 15px;

  h3 {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }

  p {
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    display: flex;
    align-items: center;

    .dots {
      width: 6px;
      height: 6px;
      display: block;
      margin-left: 5px;
    }
  }
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(0.5rem * calc(1 - 0));
  margin-bottom: calc(0.5rem * 0);
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(2rem * 0);
  margin-left: calc(2rem * calc(1 - 0));
}
