.no-data {
  padding: 0 !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;

  .textHeader {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 40px;
    text-align: center;
    margin: 5px 0;
  }
  .subHeaderText {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
  }
}

.with-two-button {
  .border-btn {
    background: transparent !important;
    border: 2px solid #f2f4f1 !important;
    box-shadow: none !important;
    color: #586068 !important;
  }
}
