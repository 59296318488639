.dashboard-section {
  .dashboard-title-highlight {
    background-color: #bfff0b;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    line-height: 0;
    border-radius: 50%;

    .dashboard-user-later {
      position: relative;
      top: -2px;
    }
  }

  .dashboard-details {
    .active-text {
      font-weight: bold;
      font-size: 45px;
    }

    .sub-active-text {
      font-weight: 400;
      font-size: 45px;
      line-height: 50px;
    }
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    color: #586068;

    span {
      margin-right: 5px;
    }

    .progress__line {
      background: #000;
      height: 8px;
      width: 60%;
      margin-right: 10px;
      background: #e6e8e3;
      position: relative;
      overflow: hidden;
      border-radius: 16px !important;

      div {
        position: absolute;
        height: 8px;
        top: 0;
        left: 0;
        background: #d28b28;
      }
    }
  }
}

.anticon {
  vertical-align: 0;
}

.page-wrapper {
  .overview-action {
    display: flex;
    list-style: none;
    align-items: center;

    li {
      padding: 5px 18px;
      border-right: 1px solid #d9ddd5;
      display: flex;
      align-items: center;

      &:last-child {
        border-right: 0;
        padding-right: 0;
      }
    }
  }

  .border-full.transactions-wrapper {
    table {
      tr:last-child {
        td {
          border-bottom: 0 !important;
        }
      }
    }
  }

  .overview-data {
    display: grid;
    grid-template-columns: 33.32% 33.32% 33.32%;

    .total-amount-wrapper {
      border: 1px solid #e6e8e3;
      border-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:last-child {
        border-right: 1px solid #e6e8e3;
      }
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: 100%;

      .total-amount-wrapper {
        border-right: 1px solid #e6e8e3 !important;
      }
    }
  }
}

.arrow-wrapper.active {
  background: #bfff0b;
}

.arrow-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background: #d9ddd5;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-wrapper-percentage.active {
  background: #ecfdf3;
  border: 1px solid #a6f4c5;
}

.arrow-wrapper-percentage {
  height: 24px;
  border-radius: 16px;
  padding: 0px 8px;
  background: #d9ddd5;
  font-size: 14px;
  line-height: 0px;
  color: #027a48;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.text {
    background: none !important;
    border: none !important;
  }

  &.loss {
    color: #ff3434;
  }

  &.left {
    text-align: left !important;
  }
}

.loss {
  color: #ff3434 !important;
}

.gain {
  color: #027a48 !important;
}

.actions-dialog {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11111;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 5px 0;
  margin-top: 10px;
  border: 1px solid gainsboro;
  max-height: 420px;
  overflow-y: scroll;
  min-width: 240px;

  &.isInput {
    margin-top: 7px;
    margin-left: -17px;
  }

  .actionLink {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      background: whitesmoke;
      border-radius: 6px;
    }

    span {
      padding: 0 10px 0 5px;
    }
  }

  .svg-danger {
    svg {
      margin-right: 8px;
    }

    svg path {
      stroke: red;
    }
  }

  .svg-danger.action-link-svg {
    svg {
      margin-right: 0 !important;
    }
  }

  .text-gray {
    color: #79716b;
  }
}

.link-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;

  .not-active {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }

  .icon-btn {
    font-size: 15px;
    background: #d9ddd5;
    margin: 4px;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.selectFundsWrapper {
  margin-top: 40px;

  .select-fund-type {
    padding: 20px 0;
    border-bottom: 1px solid #e5e8e3;
    cursor: pointer;

    &:last-child {
      border: 0px;
    }

    span {
      padding-left: 30px;
    }
  }

  .cardWrapper {
    margin-top: 50px;

    .active-card-bg {
      box-shadow: 0 3px 3px #e7e7e7;
      background: #e6e8e34f;
    }

    .balance-card-wrapper {
      border: 1px solid #e5e5e5;
      border-radius: 0.25rem;
      margin-bottom: 20px;
      padding: 20px 15px;
      align-items: center;
      display: flex;
      cursor: pointer;

      .balance-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }

      .balance-tag {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #ffffff;
        background: #d28b28;
        border-radius: 50px;
        padding: 3px 8px;
        margin-left: 12px;
      }

      .dark-bg {
        background: #586068 !important;
      }

      .balance-sub-title {
        color: #586068;
        font-size: 15px;
      }
    }
  }
}

.line-text {
  position: relative;

  .text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background: #fff;
    padding: 12px;
    z-index: 5;
    color: #586068;
    font-size: 13px;
    line-height: 15px;
  }
}

.balance-tag.dark-bg {
  background: #586068 !important;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  border-radius: 50px;
  padding: 3px 8px 4px;
  margin-left: 12px;
}

.disabled-link {
  cursor: not-allowed !important;
}

.dialog-fund-action {
  text-align: center;
  margin-top: 40px;

  .select-fund-type {
    cursor: pointer;

    .icon-style {
      width: 30px;
    }

    span {
      padding-left: 15px;
    }
  }
}

.cardInfo-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
  }
}

.overlaying-icons-wrapper {
  padding: 0;
  display: flex;
  align-items: center;

  .counter {
    background: #e5e8e3 !important;

    .icon-name {
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.icon-wrappers {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 3px solid #fff;
  display: flex;
  align-items: center;
  background: #bfff0b;
  justify-content: center;
  margin: 0 -5px;
}

.totalbalance-step {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 15px;
  font-size: 12px;
  color: #586068;
  // position-absolute top-0
}

.border-radius-10 {
  border-radius: 10px !important;
}

.report-table {
  padding: 0 20px;

  tr {
    margin: auto;
    vertical-align: middle;
  }
}

.report-table tr:last-child td {
  border: none;
}

.report-table tr:only-child td {
  border-bottom: 1px solid #dee2e6;
}

.h32px {
  height: 32px !important;
}

.fs-16 {
  font-size: 16px !important;
}
