.onboarding__layout-wrapper {
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: row;

  .onboarding__layout-main {
    width: 60%;
    height: 100%;
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;

    .mail-tag {
      appearance: none;
      color: #57534e;
      text-decoration-line: none !important;

      &:hover {
        text-decoration-line: underline !important;
      }
    }

    .link-tag {
      appearance: none;
      color: #d28b28;
      text-decoration-line: none !important;

      &:hover {
        text-decoration-line: underline !important;
      }
    }

    .link-tag-gray a {
      color: #57534e !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      text-decoration-line: none !important;

      &:hover {
        text-decoration-line: underline !important;
      }
    }

    .outline-button {
      padding: 6px 8px;
      background: #ffffff;
      border: 1px solid #000;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #414240;
    }

    .primary-button {
      padding: 10px 18px;
      background: #000;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      width: fit-content;
      height: fit-content;
    }

    .onboarding__layout-children {
      // margin: 40px auto 20px;
      // overflow-y: hidden;
      // max-width: 28rem;
      height: calc(100vh - 300px);
      padding: 0px 16px;
    }

    .header {
      padding: 24px 28px;
    }

    .footer {
      padding: 24px 28px;
    }
  }

  .onboarding__layout-slider {
    width: 40%;
    height: 100%;
    background: #fbf5ec;
    border-radius: 24px;
    border: 8px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .onboarding__step-wrapper {
    .onboarding__step-number {
      display: flex;
      padding: 2px;
      height: 20px;
      width: 20px;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      border-radius: 999px;

      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .active {
      background: #d28b28;
      color: #fff;
    }
    .inactive {
      color: #a9a29d;
      background: #f5f5f4;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .onboarding__layout-children {
      height: calc(100vh - 250px) !important;
    }

    .onboarding__layout-main {
      width: 100%;
      height: 100%;

      .header {
        padding: 24px 20px;
      }

      .footer {
        padding: 24px 20px;
        flex-direction: column-reverse;
      }
    }

    .onboarding__layout-slider {
      display: none;
    }
  }

  .text-lc {
    color: #57534e;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .form-header {
    // margin-bottom: 40px;

    h1 {
      color: #101828;
      /* Display sm/Semibold */
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 126.667% */
    }
  }

  .selection-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-radius: 8px;
    &.inactive {
      border: 1px solid #e7e5e4;
    }

    &.inactive:hover {
      border: 1px solid #d28b28;
      transition: border 0.3s ease;
    }

    &.active {
      border: 1px solid #d28b28;
    }

    .content-wrap {
      display: flex;
      align-items: center;
      gap: 16px;
      .icon {
        border: 1px solid #e7e5e4;
        padding: 8px;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .text-holder {
        h4 {
          margin: 0px;
          font-weight: 400;
          font-size: 14px;
          color: #1c1917;
          line-height: 20px;
        }
        p {
          margin: 0px;
          color: #79716b;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.format-lookup-option {
  display: flex;
  flex-direction: column;
  padding: 6px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f7f8f7;
  }

  div {
    display: flex;
    gap: 10px;
    h4 {
      font-size: 14px !important;
      font-weight: 300 !important;
      color: #1c1917 !important;
      gap: 10px !important;
      margin: 0px;
      text-transform: uppercase;
    }

    p {
      font-size: 12px !important;
      color: #79716b !important;
      margin: 0px !important;
      padding: 0px !important;
      margin: 0px;
    }
  }

  p {
    font-size: 12px !important;
    color: #79716b !important;
    font-weight: 300 !important;
    margin: 0px;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.loader-container {
  --uib-size: 40px;
  --uib-color: black;
  --uib-speed: 1.5s;
  --dot-size: calc(var(--uib-size) * 0.17);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: smoothRotate calc(var(--uib-speed) * 1.8) linear infinite;
}

.dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  animation: rotate var(--uib-speed) ease-in-out infinite;
}

.dot::before {
  content: '';
  height: var(--dot-size);
  width: var(--dot-size);
  border-radius: 50%;
  background-color: var(--uib-color);
  transition: background-color 0.3s ease;
}

.dot:nth-child(2),
.dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.835 * 0.5);
}

.dot:nth-child(3),
.dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.668 * 0.5);
}

.dot:nth-child(4),
.dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.501 * 0.5);
}

.dot:nth-child(5),
.dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.334 * 0.5);
}

.dot:nth-child(6),
.dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.167 * 0.5);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  65%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes smoothRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
