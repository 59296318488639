.empty-task-card {
  max-width: 325px;

  img {
    width: 126.5px;
    height: 105.7px;
    margin-bottom: 10px;
  }

  h4 {
    color: #1c1917;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
}
