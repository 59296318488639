.card-modal-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  .modal-pagination {
    color: #898989;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      margin: 0 3px;
    }
    .active-text {
      font-weight: 600;
      color: #000;
    }
  }

  .close-text {
    color: #57534e;
    font-size: 0.75rem;
    font-weight: 500;
  }
}
