.transaction-page-grid {
  column-gap: 25px;
  display: grid;
  width: 100%;
  grid-template-columns: 72% 26%;
}

.bulk-assignment-aside {
  margin-right: -25px;
  padding-top: 32px;
  padding-bottom: 16px;
  position: relative;

  .aside-title {
    font-size: 18px;
    margin-bottom: 32px;
  }

  .info-box {
    background-color: #f5f5f4;
    font-size: 12px;
    display: flex;
    border-radius: 8px;
    margin-bottom: 16px;
    svg {
      flex-basis: 16px;
      flex-shrink: 0;
    }
  }

  .input-section {
    margin-bottom: 32px;
    h6 {
      font-size: 14px;
      font-weight: 500;
      color: #79716b;
    }
    .aside-placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #a9a29d;
    }
  }

  .groupWrapper label,
  .select-wrapper label {
    font-size: 14px;
    color: #44403c !important;
  }

  .aside-button-container {
    position: sticky;
    bottom: 0;
    padding: 16px;
    background-color: #ffffff;
  }

  .selectInput-wrapper input::placeholder {
    font-size: 14px !important;
  }

  .selectInput-wrapper input {
    font-size: 14px !important;
  }

  .img__card-initials {
    display: flex;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 100%;
    background: #f5f5f4;
    padding: 15px;

    h5 {
      font-size: 13px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0em;
      color: #1c1917;
      margin: 0px;
    }
  }
  .beneficiary-option {
    span {
      margin-left: 8px;
    }
  }
  .aside-category-select {
    .options {
      font-size: 14px;
      color: #1c1917;
    }
  }
}

.floating-cta-container {
  min-height: 50px;
  min-width: 300px;
  max-width: fit-content;
  padding: 16px;
  position: sticky;
  bottom: 30px;
  background-color: #ffffff;
  margin-bottom: 32px;
  .cta-divider {
    width: 1px;
    background-color: #e7e5e4;
    margin: 0 1rem;
    flex-shrink: 0;
  }
  .selected-information {
    font-size: 14px;
    line-height: 20px;
    padding-right: 12px;
  }
  box-shadow: 0px 8px 8px -4px #1c191708;
  .floating-cta.add-button {
    min-width: 100px !important;
    max-width: 180px !important;
    padding-bottom: 0 !important;
    font-size: 14px !important;
  }
  .floating-cta.dark-button {
    color: #fff !important;
  }
  .floating-cta.delete-cta {
    color: #d92d20 !important;
    border: 1px solid #fda29b !important;
  }
  .custom-btn-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
  }
}
