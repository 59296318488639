@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.dashboard-section {
  .dashboard-title-highlight {
    background-color: #bfff0b;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    line-height: 0;
    border-radius: 50%;

    .dashboard-user-later {
      position: relative;
      top: -2px;
    }
  }

  .dashboard-region {
    .active-text {
      font-weight: 600;
      font-size: 2.25rem;
      color: #1c1917;
    }

    .sub-active-text {
      font-weight: 400;
      font-size: 2.25rem;
      line-height: 44px;
      color: #57534e;
    }
  }

  .dashboard-progress__wrapper {
    display: flex;
    align-items: center;
    color: #57534e;

    span {
      margin-right: 5px;
      font-size: 0.875rem;
      color: #44403c;
    }

    .progress__line {
      background: #000;
      height: 8px;
      width: 60%;
      margin-right: 10px;
      background: #e6e8e3;
      position: relative;
      overflow: hidden;
      border-radius: 16px !important;

      div {
        position: absolute;
        height: 8px;
        top: 0;
        left: 0;
        background: #d28b28;
      }
    }
  }
}

.overview-holder {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 4px 12px 4px;
  height: calc(100vh - 8.5rem);

  &.shallow {
    height: calc(100vh - 7rem);
  }

  &.medium {
    height: calc(100vh - 10rem);
  }

  @include mobile {
    overflow: unset;
    height: auto !important;
  }
}

.anticon {
  vertical-align: 0;
}

.page-wrapper {
  .overview-action {
    display: flex;
    list-style: none;
    align-items: center;

    li {
      padding: 5px 18px;
      border-right: 1px solid #d9ddd5;
      display: flex;
      align-items: center;

      &:last-child {
        border-right: 0;
        padding-right: 0;
      }
    }

    .space-x-4 > :not([hidden]) ~ :not([hidden]) {
      margin-right: calc(1rem * 0);
      margin-left: calc(1rem * calc(1 - 0));
    }
  }

  .border-full.transactions-wrapper {
    table {
      tr:last-child {
        td {
          border-bottom: 0 !important;
        }
      }
    }
  }

  .overview-balances__holder {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .text {
      color: #7f7f7d;
      font-weight: 500;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .value {
      color: #212120;
      font-size: 1.875rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .indicator-dot {
    width: 6px;

    height: 6px;
    display: block;
    background: #d8d8d6;
    border-radius: 100%;
    transition: all 0.5s ease-in;

    &.active {
      background: #212120;
    }
  }

  .inner-currency__holder {
    width: 44px;
    overflow: hidden;
    display: inline-block;
    position: relative;
  }

  .inner-currency__holder span {
    display: inline-block;
    transform: translateX(0);
    transition: transform 0.5s ease;
  }

  .inner-currency__holder.slide-in span {
    transform: translateX(0);
  }

  .inner-currency__holder.slide-out span {
    transform: translateX(100%);
  }

  .value-holder {
    display: inline-block;
    overflow: hidden;
    position: relative;
    height: 30px;
  }

  .value-holder span {
    display: inline-block;
    transform: translateY(0%);
    transition: transform 0.5s ease;
  }

  .value-holder.slide-in span {
    transform: translateY(0);
  }

  .value-holder.slide-out span {
    transform: translateY(50%);
  }

  .indicator-arrow-holder {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5c5c5a;
    transition-duration: 300ms;

    &:hover {
      opacity: 0.75;
      transform: scale(0.9);
    }

    &:active {
      transform: scale(1);
    }

    &.dormant {
      cursor: not-allowed;
      background: #e8e8e7;
    }
  }

  .overview-display__cards {
    background: #f5f5f4;
    border-radius: 12px;
    padding: 16px;
    width: 100%;
    height: 86px;

    &.plain {
      background: unset;
      width: unset;
      height: unset;
      padding: 0;

      .caption {
        line-height: 24px;
      }

      .value {
        line-height: 24px;
        font-size: 1.125rem;
      }
    }

    .caption {
      color: #7f7f7d;
      font-weight: 500;
      margin-bottom: 0;
      font-size: 0.875rem;
    }

    .value {
      font-size: 1.25rem;
      color: #212120;
      font-weight: 600;
      padding-top: 4px;
      margin-bottom: 0;
    }
  }

  .overview-top-data {
    margin-top: 1rem;
    border-radius: 12px;
    border: 1px solid #e8e8e7;
    box-shadow: 0px 1px 2px 0px #1c19170d;

    .total-amount-wrapper {
      border: 1px solid #e6e8e3;
      border-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:last-child {
        border-right: 1px solid #e6e8e3;
      }
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: 100%;

      .total-amount-wrapper {
        border-right: 1px solid #e6e8e3 !important;
      }
    }
  }
}

.arrow-wrapper.active {
  background: #bfff0b;
}

.h-0 {
  height: unset !important;
}

.arrow-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background: #d9ddd5;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-wrapper-percentage.active {
  background: #ecfdf3;
  border: 1px solid #a6f4c5;
}

.arrow-wrapper-percentage {
  height: 24px;
  border-radius: 16px;
  padding: 0px 8px;
  background: #d9ddd5;
  font-size: 14px;
  line-height: 0px;
  color: #027a48;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multi-entity > .popover-body > .actions-dialog {
  right: 6px;
  position: relative;
  min-width: 320px;
  border: 1px solid #e7e5e4;
  box-shadow: 0px 12px 16px -4px #1c191714;
  border-radius: 12px;

  .actionLink {
    margin: 6px 0;
    padding: 8px 16px;
    color: #1c1917;
    font-weight: 400;
    font-family: 'Inter';

    span {
      padding: 0;
    }
  }
}

.bill-action > .popover-body > .actions-dialog {
  min-width: 240px !important;
}

#invoice-schedule > .popover-body > .actions-dialog {
  right: -10px;
  position: relative;
  min-width: 240px;
}

#overview-fund-dropdown > .popover-body > .actions-dialog {
  top: -10px;
  right: -10px;
  position: relative;
  min-width: 240px;
}

#currency-select > .popover-body > .actions-dialog {
  right: 24px;
  position: relative;
  min-width: 176px;
}

#overview-fund-dropdown > .popover-body > .actions-dialog {
  top: -10px;
  right: -10px;
  position: relative;
  min-width: 240px;
}

.actions-dialog {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11111;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 5px 0;
  margin-top: 10px;
  border: 1px solid gainsboro;
  max-height: 420px;
  overflow-y: scroll;
  min-width: 240px;

  &.isInput {
    margin-top: 7px;
    margin-left: -17px;
  }

  .actionLink {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px !important;
    font-size: 14px;
    font-weight: 500;

    &.gap-8 {
      gap: 8px !important;
    }

    &:hover {
      background: whitesmoke;
    }

    span {
      padding: 0 10px 0 5px;
    }
  }

  .svg-danger {
    svg {
      margin-right: 8px;
    }

    svg path {
      stroke: red;
    }
  }
}

.link-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;

  .not-active {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }

  .icon-btn {
    font-size: 15px;
    background: #d9ddd5;
    margin: 4px;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.selectFundsWrapper {
  margin-top: 40px;

  .select-fund-type {
    padding: 20px 0;
    border-bottom: 1px solid #e5e8e3;
    cursor: pointer;

    &:last-child {
      border: 0px;
    }

    span {
      padding-left: 30px;
    }
  }

  .cardWrapper {
    margin-top: 50px;

    .active-card-bg {
      box-shadow: 0 3px 3px #e7e7e7;
      background: #e6e8e34f;
    }

    .balance-card-wrapper {
      border: 1px solid #e5e5e5;
      border-radius: 0.25rem;
      margin-bottom: 20px;
      padding: 20px 15px;
      align-items: center;
      display: flex;
      cursor: pointer;

      .balance-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }

      .balance-tag {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #ffffff;
        background: #d28b28;
        border-radius: 50px;
        padding: 3px 8px;
        margin-left: 12px;
      }

      .dark-bg {
        background: #586068 !important;
      }

      .balance-sub-title {
        color: #586068;
        font-size: 15px;
      }
    }
  }
}

.line-text {
  position: relative;

  .text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background: #fff;
    padding: 12px;
    z-index: 5;
    color: #586068;
    font-size: 13px;
    line-height: 15px;
  }
}

.balance-tag.dark-bg {
  background: #586068 !important;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  border-radius: 50px;
  padding: 3px 8px 4px;
  margin-left: 12px;
}

.disabled-link {
  cursor: not-allowed !important;
}

.dialog-fund-action {
  text-align: center;
  margin-top: 40px;

  .select-fund-type {
    cursor: pointer;

    .icon-style {
      width: 30px;
    }

    span {
      padding-left: 15px;
    }
  }
}

.cardInfo-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
  }
}

.overlaying-icons-wrapper {
  padding: 0;
  display: flex;
  align-items: center;

  .counter {
    background: #e5e8e3 !important;

    .icon-name {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .icon-wrappers {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 3px solid #fff;
    display: flex;
    align-items: center;
    background: #bfff0b;
    justify-content: center;
    margin: 0 -5px;
  }
}

.totalbalance-step {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 15px;
  font-size: 12px;
  color: #586068;
  // position-absolute top-0
}

.border-radius-10 {
  border-radius: 10px !important;
}
