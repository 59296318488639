.payment-header {
  padding-bottom: 20px;
  max-width: 100%;
  z-index: 99999999;
  .amount-value {
    font-size: 40px;
    color: #212120;
    font-weight: 600 !important;
    margin-bottom: 0;
    letter-spacing: 0px !important;
  }

  .receiver {
    font-size: 14px !important;
    font-weight: 500 !important;
    p {
      color: #a0a09e;
    }
    .recipient-link {
      color: #212120 !important;
    }
  }
}

.transfer-in-icon {
  background: #e3ff8f;
  border: 1px solid rgba(0, 0, 0, 0.06);

  display: flex;
  align-items: center;
  justify-content: center;

  &.lg {
    height: 64px;
    width: 64px;
    box-shadow: 0px -2.13px 2.13px 0px #00000033 inset, 0px 1.07px 1.07px 0px #00000014,
      0px 2.67px 5.33px 0px #0000001f !important;
    // border-radius: 24px;
  }

  &.sm {
    height: 24px;
    width: 24px;
    box-shadow: 0px -0.8px 0.8px 0px #00000033 inset, 0px 0.4px 0.4px 0px #00000014,
      0px 1px 2px 0px #0000001f;
    border-radius: 8px;
  }
}

.img-hover {
  position: relative;
  opacity: 0;
  background-color: rgba(144, 143, 143, 0.6);
  border: 2px #fff solid;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 12px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: inherit;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: -1;
  }

  &:hover {
    opacity: 1;
    backdrop-filter: blur(3px);
    pointer-events: auto;
  }
}

.parent-img-hover:hover .img-hover {
  opacity: 1;
  pointer-events: auto;
}
