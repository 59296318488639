.card-list__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px !important;
  transition-duration: 500ms;
  height: 56px;
  margin-bottom: 1px;

  .total-region {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      color: #212120;
      font-size: 0.875rem;
    }
  }
}

.border-bottom {
  border-bottom: 1px solid #e7e5e4;

  &:not(:first-child):hover {
    background-color: #fafaf9;
  }
}

.card-list__content {
  display: flex;
  align-items: center;
  gap: 12px !important;

  .pill {
    text-align: center;
    height: 40px;
    width: 40px;
    background-color: #fee4e2;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7a271a;
    font-weight: 500;
    overflow: hidden;
    font-size: 0.875rem;

    .count-value {
      padding-top: 16px;
      overflow-x: scroll;
      width: 30px;
      font-size: 0.875rem;

      &.smaller-text {
        font-size: 0.625rem;
      }
    }
  }

  .card-list__text {
    font-size: 14px;
    font-weight: 500;
    color: #1c1917;
  }
}
