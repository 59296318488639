.policy-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 1rem;
  align-items: start;
  justify-content: center;
  p {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
    margin: 0;
    font-weight: 400;
  }
  h3 {
    font-size: 16px;
    line-height: 25px;
    margin: 0;
    font-weight: 400;
    white-space: normal;
    word-break: break-all;
  }
}
.policy-icon {
  background: #f2f4f1;
  border-radius: 30px;
  padding: 3px 10px;
  cursor: pointer;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(0.5rem * 0);
  margin-left: calc(0.5rem * calc(1 - 0));
}
