.popoverNoPadding {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.tool-tip {
  min-width: 180px !important;
  max-width: 280px !important;
  .text {
    font-size: 13px;
    line-height: 18px;
  }
}

.popover {
  background: transparent !important;
  border: none !important;
  // margin-top: -10px;
}

.popover-arrow {
  display: none !important;
}
