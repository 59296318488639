.onboarding-section {
  .max-w {
    max-width: 894px;
  }
  padding: 32px 0px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 32px 16px;
  }

  .welcome-header {
    padding: 10px 0;
    border-bottom: 1px solid #e7e5e4;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    h1 {
      color: #1c1917;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;

      span {
        color: #d28b28;
      }
    }

    @media (max-width: 640px) {
      h1 {
        font-size: 20px;
        line-height: 25px;
      }
      // display: flex !important;
      // flex-direction: column-reverse !important;
      // align-items: start !important;
    }
  }

  .business-kyc-section {
    .back-btn {
      display: flex;
      width: fit-content;
      justify-content: center;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      div {
        color: #d28b28;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .business-kyc-title {
      padding: 20px 0;
      border-bottom: 1px solid #e7e5e4;

      h1 {
        color: #1c1917;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
      }

      p {
        color: #79716b;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }

      @media (max-width: 768px) {
        h1 {
          font-size: 24px !important;
          margin: 0px !important;
        }

        p {
          margin: 0px !important;
          font-size: 14px !important;
        }
      }
    }

    .forms-tab-wrapper {
      padding-top: 20px;
      display: flex;

      .business-tab-view {
        width: 100%;
        max-width: 492px;
        margin-left: auto;
        padding-left: 16px !important;

        @media (max-width: 1024px) {
          padding-left: 0px !important;
        }

        @media (max-width: 768px) {
          .button-container {
            button {
              width: 100%;
            }
          }
        }
      }

      @media (max-width: 768px) {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;

        .business-tab-view {
          margin-left: 0px !important;
        }
      }
    }
  }

  .business-owner-container {
    .title {
      h3 {
        color: #1c1917;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      p {
        color: #79716b;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .business-owner-action {
      border-radius: 12px;
      border: 1px solid #e7e5e4;
      background: #fff;
      display: flex;
      flex-direction: row;
      gap: 12px;
      padding: 16px;
      align-items: center;

      h6 {
        color: #1c1917;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 0px !important;
        margin: 0px !important;
      }

      p {
        color: #79716b;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        padding: 0px !important;
        margin: 0px !important;
      }

      button {
        min-width: 120px !important;
      }

      @media (max-width: 768px) {
        display: flex !important;
        flex-direction: column !important;
        button {
          margin-top: 12px !important;
          min-width: 100% !important;
        }
      }
    }
  }

  .additional-details-container {
    .title {
      h3 {
        color: #1c1917;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      p {
        color: #79716b;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .additional-detail-fields {
      border-radius: 12px;
      border: 1px solid #e7e5e4;
      background: #fff;
      gap: 12px;
      align-items: center;

      .profile-image {
        display: flex;
        gap: 18px;
        width: 100%;

        img {
          width: 64px;
          height: 64px;
          border-radius: 200px;
        }

        .upload-container {
          display: flex;
          flex-direction: column;

          h6 {
            color: #44403c;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          p {
            color: #79716b;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }
}

.cancel-business-owner {
  width: fit-content !important;
  z-index: 100;
}
