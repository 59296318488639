.budgets-wrapper {
  width: 100%;
  height: 100%;
  .infinite-scroll-component {
    display: flex;
    row-gap: 24px;
    column-gap: 24px;
    flex-wrap: wrap;
    height: 100%;
    width: 100% !important;
  }
}

.no-data {
  padding: 0 !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  .textHeader {
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    margin: 5px 0;
  }
  .subHeaderText {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
  }
}

.search-input {
  width: 16rem;
  @media screen and (max-width: 480px) {
    width: 12rem !important;
  }

  .input {
    height: 36px;

    border-radius: 8px !important;
    padding: 0 12px !important;
    border: 1px solid #e7e5e4 !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);

    .ant-input {
      padding-left: 6px !important;

      &::placeholder {
        color: #57534e !important;
        font-weight: 500;
      }
    }
  }
}

.count {
  height: fit-content !important;
  width: fit-content !important;
  padding: 2px 8px !important;
  display: flex;
  justify-items: center !important;
  align-items: center !important;
  background: #f2f4f1;
  font-size: 12px;
  border-radius: 9999px !important;
  margin-left: 5px;
}

.budget__notice {
  .alert-triangle-container {
    min-width: 20px; /* Adjust the value as needed */
  }
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid #fec84b;
  background: #fffcf5;
  h4 {
    color: #414240;

    /* Text sm/Semibold */
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  p {
    color: #575855;
    // max-width: 75% !important;
    /* Text sm/Regular */
    font-size: 14px;
    line-height: 20px;
  }

  .add-action {
    width: fit-content;
    font-size: 14px !important;
  }
}
