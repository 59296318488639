@import '../../../scss/base/colors.scss';
@import '../../../scss/typography.scss';
@import '../../../scss/abstracts/variables.scss';

.emptystate-wrapper {
  .emptystate-main {
    @include borderStyle(full, 0.6px, solid, $grey-200);
    border-radius: $size-xs;
    .emptystate-main-image {
      width: 505px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 18px;
      background: linear-gradient(180deg, #fff 50%, rgba(235, 201, 154, 0.2) 77.08%);
      border-top-left-radius: $size-xs;
      border-bottom-left-radius: $size-xs;
      // border-radius: 12px;
      img {
        width: 287px;
      }
    }
  }

  .emptystate-body {
    @include padding($margin-sm, 0px, $margin-sm, 56px);
    max-width: 549px;
    border-left: 0.5px solid $grey-200;
    h6 {
      font-size: 20px;
      font-weight: $font-semibold;
    }
    p {
      font-size: $size-sm;
      color: $grey-500;
      width: 398px;
    }
    .emptystate-action {
      margin-top: $margin-sm;
      .empty-add-button,
      .approval-empty-add-button {
        height: 42px !important;
        font-size: 16px !important;
        white-space: nowrap;
      }
      .empty-add-button {
        max-width: 157px !important;
      }
      .approval-empty-add-button {
        max-width: 180px !important;
      }
    }
  }

  .children-wrapper {
    margin-top: $margin-sm;
    gap: 32px;
    .child-empty-items {
      max-width: 412px;
      flex: 1;
      @include padding(16px, 86px, 16px, 24px);
      @include borderStyle(full, 0.6px, solid, $grey-200);
      border-radius: 12px;
      h6 {
        font-size: $text-sm;
        font-weight: $font-semibold;
        margin-bottom: 2px;
        margin-top: 16px;
      }
      p {
        font-size: $text-sm;
        color: $grey-500;
        margin: 0;
      }
      span {
        font-size: $text-xs;
        color: $secondary-500;
        margin-top: 8px;
        cursor: pointer;
        font-weight: $font-medium;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .emptystate-main {
      display: block !important;
      width: auto !important;
    }
    .emptystate-main-image {
      width: auto !important;
    }
    .children-wrapper {
      display: block !important;
    }
    .child-empty-items {
      margin-bottom: 1.5rem;
    }
  }
}
