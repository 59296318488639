.custom-date-picker {
  border-radius: 8px;
  border: 1px solid #d7d3d0;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: #44403c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &.h-32 {
    height: 32px;
    padding: 6px 8px;
  }
}
