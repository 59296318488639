.settlement-empty-state {
  padding-top: 100px;
}

.title {
  color: #586068;
  font-size: 24px;
  margin: 0;
}

.subTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #586068;
}

.verify-account-wrapper {
  display: block;
  width: 100%;
  margin-top: 16px;
  background: #f2f4f1;
  border-radius: 5px;
  padding: 15px;
}

.button-wrapper {
  width: 120px;
}
.settlement-table-wrapper .customButton {
  font-size: 14px !important;
  height: 45px !important;
  padding: 10px 16px !important;
}

.settlement-table-wrapper header {
  margin-bottom: 40px;
}

.settlement-table-wrapper header h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #242628;
  margin: 0;
}
.settlement-table-wrapper td {
  padding-top: 12px !important;
}

.settlement-table-wrapper td span {
  display: flex;
  padding-top: inherit;
}

.remove {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  color: #f04438;
  cursor: pointer;
}
.remove svg {
  margin-right: 6px;
}

.user-bank-info {
  display: flex;
  flex-direction: column;
}
.user-bank-info span:first-child {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #586068;
}

.user-bank-info span:last-child {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #586068;
}

.otp-email {
  font-weight: 600;
  color: #d28b28;
}

.custom-settlement-button {
  padding: 14px !important;
}
