@import '../../scss/abstracts/variables.scss';

.top-bar {
  // background: #f2f4f1;
  padding: 0 !important;
  margin-top: 20px;

  .toggle-view {
    padding: 0 !important;
    margin-right: 12px;
    display: flex;
    align-items: center;

    span {
      height: 38px;
      display: flex;
      align-items: center;
      padding: 0px 12px;
      border-radius: 0px 8px 8px 0px;
      cursor: pointer;

      &:nth-child(1) {
        border-right: 1px solid #d7d3d0;
        border-radius: 8px 0px 0px 8px;
      }
    }
    .active {
      background: #fafaf9;
      svg path {
        stroke: #292524;
      }
    }
  }

  .ant-breadcrumb .anticon {
    font-size: 16px;
    line-height: 0;
    position: relative;
    top: -3px;
  }

  .headerText {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #1c1917 !important;

    //text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    &.large {
      padding-top: 10px;
      font-size: 35px;
    }
  }

  .subText {
    color: #79716b;
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #79716b;
    }
  }

  .ant-breadcrumb-separator {
    margin: 0 15px;

    .anticon {
      font-size: 13px;
    }
  }

  .textNumber {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    margin-left: 28px !important;
    position: relative;
    color: #bfc6b9;

    &:before {
      content: '.';
      position: absolute;
      left: -10px;
      top: -8px;
    }
  }

  .logo-link {
    position: relative;
    top: -3.5px;
  }

  .line {
    margin: 0 15px;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .right-side {
    .search {
    }

    .user {
      .user-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  @media screen {
    margin-top: 0px !important;
  }
}

.dialog-filter-wrapper {
  z-index: 99 !important;
}

.filter-action-list {
  padding: 15px;
  max-height: 200px;
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f6f6f6;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  label {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 16px;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #d28b28 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #d28b28;
    border-color: #d28b28;

    &:hover {
      border-color: #d28b28;
      background-color: #f9f9f9;
    }
  }

  .ant-checkbox-checked::after {
    border-color: #d28b28 !important;
  }
}

.dialog-filter-wrapper {
  .customButton,
  .customButton:focus {
    height: inherit !important;
    font-size: inherit !important;
  }
}

.add-button,
.add-button:focus {
  max-width: 222px !important;
  font-size: 0.875rem !important;
  white-space: nowrap;
  font-weight: 500;
  border-radius: 8px !important;
  @media screen {
    max-width: 100% !important;
  }
}

.add-button {
  position: relative;
  outline: none !important;

  &:hover {
    .arrow-icon-wrapper:not(.is-static) {
      .first-svg {
        &.top {
          transform: translateY(-100%) !important;
        }

        &.bottom {
          transform: translateY(100%) !important;
        }

        &.left {
          transform: translateX(100%) !important;
        }
      }
    }

    .second-svg {
      &.top,
      &.bottom {
        transform: translateY(0) !important;
      }

      &.left {
        transform: translateX(0) !important;
      }
    }
  }

  &:active {
    transform: scale(0.9);
  }
}

.arrow-icon-wrapper {
  justify-content: center;
  display: flex;
  position: relative;
  height: 20px;
  width: 20px;
  overflow: hidden;

  svg {
    position: absolute;
    transition: all 0.3s ease;
  }

  .second-svg {
    &.top {
      transform: translateY(100%) !important;
    }

    &.bottom {
      transform: translateY(-100%) !important;
    }

    &.left {
      transform: translateX(-100%) !important;
    }
  }
}

.filter-button-wrapper {
  border: 1px solid #e8e8e7;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  background: inherit;
  padding: 0 1rem;
  border-radius: 8px;
  font-size: 14px;
  height: 40px;

  &:hover {
    background-color: $hover-color;
    box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);
  }
}

.ml-4 {
  margin-left: 14px;
}

.display-amount-text {
  font-weight: 500;
  font-size: 14px;
}

.retry-failed {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    margin-left: 8px;
  }
}

.add-action {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0px 14px;
  background: transparent !important;
  box-shadow: none !important;
  color: #171721 !important;
  height: 42px !important;
  padding-bottom: 0 !important;
  border: 2px solid #f2f4f1 !important;
  cursor: pointer;
}

.retry-failed {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    margin-left: 8px;
  }
}

.h-45 {
  height: 45px !important;
}

.tab-hover-effect:hover {
  background-color: #fafaf7;
  color: #2b2c2b;
}

.tab-hover-effect.active {
  background-color: #fafaf7;
  color: #2b2c2b;
}

.flexEnd div {
  justify-content: flex-end !important;
  align-items: flex-end !important;
}
