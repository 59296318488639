.transactions-table {
  width: 100%;

  .ant-table {
    font-size: 14px;
    line-height: 12px;
  }

  .ant-table table {
    border-spacing: 0px 2px;
  }

  .ant-table {
    background: transparent;
  }

  .ant-table-thead {
    th:first-child {
      border-radius: 0 0 0 10px;
      padding-left: 15px;
    }

    th:last-child {
      border-radius: 0 0 10px 0;
      padding-right: 15px;
    }

    & > tr > th {
      background-color: #171721;
      cursor: pointer;
    }
  }

  .ant-table-row {
    height: 50px;
    background-color: transparent;

    td {
      background-color: #171721;
    }

    td.ant-table-cell.ant-table-cell-row-hover {
      background: #292a33 !important;
      border-top: 1px solid #171721;
      border-bottom: 1px solid #171721;

      margin: 2px 0 2px 0;
      cursor: pointer;

      &:first-child {
        border-left: 1px solid #171721;
      }

      &:last-child {
        border-right: 1px solid #171721;
      }
    }

    td:first-child {
      border-radius: 10px 0 0 10px;
      padding-left: 15px;
    }

    td:last-child {
      border-radius: 0 10px 10px 0;
      padding-right: 15px;
    }
  }

  .user-render {
    display: flex;
    align-items: center;
    width: 100%;

    .name {
      margin-left: 5px;
      display: block;
      flex: 1;
    }
  }
}

.card-title {
  border-bottom: none !important;
}
