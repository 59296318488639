.fixed-alert {
  // position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: #d92d20;
  padding: 12px;
  padding-right: 12px;
  width: 100%;
  z-index: 9999999999;
  display: flex;
  align-items: center;

  .fixed-alert-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .fixed-alert-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      p {
        color: #feee95;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin: 0px !important;

        span {
          color: #fff;
          font-weight: 400;
        }
      }
    }
    .close-icon {
      position: absolute;
      right: 0;
      transform: translateX(-15px);
    }
  }
}
