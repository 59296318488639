.rule-styles {
  padding-top: 20px;
  h6 {
    color: #212120;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 4px;
  }
  span {
    color: #7f7f7d;
    font-size: 0.875rem;
    margin-bottom: 6px;
  }
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

.conditions-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #a0a09e;
  display: flex;

  .conditions-labelRemove-text {
    cursor: pointer;
    font-size: 13px;
    color: #d28b28;
  }
}

#approval-schedule > .popover-body > .actions-dialog {
  right: 230px;
  position: relative;
  min-width: 240px;
}

.condition-cta {
  gap: 4px !important;
  display: flex;
  align-items: center;
  padding: 2px 9px;
  max-width: fit-content !important;
  height: 24px !important;
  font-size: 14px;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scaleY(0.95);
  }

  & > span {
    color: #7f7f7d;
  }
}

.content {
  margin-bottom: -5px;
  .date {
    color: #586068;
    font-size: 14px;
  }
}

.requester-wrapper {
  gap: 12px;
  border: 1px solid #d9ddd5;
  border-radius: 12px;
  padding: 16px;
  .logo {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #9e40fd;
  }
  .left-section {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      background-color: #f2f4f1;
      color: #586068;
      font-weight: 600;
      font-size: 18px;
    }
  }
  .right-section {
    h4 {
      color: '#242628';
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
    span {
      color: #586068;
      letter-spacing: 0.01em;
      font-size: 14px;
    }
    .price {
      color: #d28b28;
      font-weight: 600;
    }
  }
}

.approval-wrapper {
  .connector-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
    height: 80px;
    .connector {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      width: 58px;
      color: #a0a09e;
    }
    .connector-wrapper-item {
      display: flex;
      justify-content: center;
    }
  }
  .connector-wrapper:last-child {
    display: none;
  }
}

.connector-wrapper-item::before {
  content: '';
  display: flex;
  height: 16px;
  width: 1px;
  background-color: #e8e8e7;
  position: absolute;
  top: 10px;
}

.connector-wrapper-item::after {
  content: '';
  display: flex;
  height: 16px;
  width: 1px;
  background-color: #e8e8e7;
  position: absolute;
  bottom: 10px;
}

.approval-amount .amount-icon {
  left: 10px;
}

.approval-history {
  .title {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
    margin: 0;
    font-weight: 400;
  }
}

.conditions-wrapper {
  position: relative;
  // display: flex;
  // align-items: center;
  .amount-icon {
    .amount-sign {
      margin-top: -5px !important;
    }
  }
}

.approval-condition {
  width: 30%;

  &:nth-child(3) {
    width: 40%;
  }

  .amount-icon {
    position: absolute;
    left: 12px;
  }

  &.is-multiple {
    width: 48.5%;
  }
}

.condition-information-wrapper {
  display: flex;
  align-items: center;
}

.information-icon {
  right: -36px;
  cursor: help;
  position: absolute;
}

.ps-ab {
  position: absolute;
  top: -30px;
  right: 0;
  cursor: pointer;
  font-size: 13px;
  color: #d28b28;
}

.approval-rules-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  row-gap: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e7;

  h2 {
    font-weight: 600;
    color: #212120;
    font-size: 1.875rem;
  }
}

.approval-header {
  .back-click {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #d28b28;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
}

.approval-page-body {
  form {
    width: 50%;

    input {
      font-weight: 500 !important;
    }

    .form-label {
      color: #79716b !important;
    }
  }
}

.approval-table {
  .table-wrapper {
    .table-responsive {
      max-height: calc(100vh - 19rem) !important;
    }
  }
}

.form-wrapper__2 {
  background-color: #fff;

  .ant-checkbox-checked::after {
    border-color: #d28b28 !important;
  }

  .ant-checkbox-wrapper:focus .ant-checkbox-inner {
    border-color: #d28b28 !important;
    box-shadow: none !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #d28b28 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #d28b28 !important;
    border-color: #d28b28 !important;
    box-shadow: none !important;

    &:hover {
      border-color: #d28b28;
      background-color: #f9f9f9;
    }
  }

  .ant-checkbox-wrapper-disabled:hover .ant-checkbox-inner {
    border-color: #d9d9d9 !important;
  }

  .ant-checkbox.ant-checkbox-disabled .ant-checkbox-inner {
    &:hover {
      border-color: #d9d9d9;
    }
  }

  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    &:hover {
      border-color: transparent;
      background-color: transparent;
    }
  }

  .ant-checkbox-radio:focus .ant-radio-inner {
    border-color: #d28b28 !important;
    box-shadow: none !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #d28b28 !important;
    // color: #000 !important;
    &:hover {
      border-color: #d28b28;
      background-color: #f9f9f9;
    }
  }

  .ant-radio-checked.ant-radio-disabled .ant-radio-inner {
    border-color: #d7d3d0 !important;
    background-color: #f5f5f5 !important;
    // color: #000 !important;
    &:hover {
      border-color: transparent;
    }
  }

  .ant-radio-inner::after {
    background-color: #d28b28 !important;
  }

  .ant-radio-disabled .ant-radio-inner::after {
    background-color: #d7d3d0 !important;
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #d28b28 !important;
  }

  .ant-radio-wrapper-disabled:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #d7d3d0 !important;
  }

  .ant-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.85);
    cursor: not-allowed;
  }

  .ant-radio-disabled + span > span {
    color: rgba(0, 0, 0, 0.85) !important;
    cursor: not-allowed;
  }

  textarea.form-control:disabled {
    background-color: #f5f5f4 !important;
    border: 1px solid #e7e5e4 !important;
    color: #a9a29d;
  }
  .form-control:disabled {
    background-color: #f5f5f4 !important;
    border: 1px solid #e7e5e4 !important;
    color: #a9a29d;
  }
}

@media screen and (max-width: 1200px) {
  .approval-page-body {
    width: 80%;
  }
}

@media screen and (max-width: 992px) {
  .approval-page-body {
    width: 100%;
  }
}

.dark-button {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
}
