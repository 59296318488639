.budget__balance-notice {
  .alert-triangle-container {
    min-width: 20px;
  }
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid #fec84b;
  background: #fffcf5;
  h4 {
    color: #414240;

    /* Text sm/Semibold */
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  p {
    color: #575855;
    font-size: 14px;
    line-height: 20px;
  }

  .action-button {
    color: rgb(135, 36, 0);
    font-size: 14px !important;
    cursor: pointer;
  }

  .border-orange {
    border: 1px solid #fec84b !important;
  }
}
