@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.bjt-analytics {
  border-radius: 12px;
  border: 1px solid #e8e8e7;
  padding: 1rem;
  min-height: 86px;
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0px 1px 2px 0px #1c19170d;

  @include mobile {
    padding: 0.75rem;
  }

  .d-flex {
    position: relative;
    height: 24px;
  }

  .extra-text {
    color: #a0a09e;
    font-weight: 500;
    font-size: 0.875rem;
    transform: translateX(-10px);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
  }

  p {
    margin-bottom: 0;
  }

  .caption {
    color: #7f7f7d;
    font-weight: 500;
    font-size: 0.875rem;
    margin: 0;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(0);
    position: absolute;
    left: 0;
    top: 0;

    &.pseudo {
      position: absolute;
      left: 0;
    }
  }

  .value {
    color: #212120;
    font-weight: 600;
    font-size: 1.25rem;
  }

  &.plain {
    cursor: auto;

    &.pointer {
      cursor: pointer;
    }

    .caption {
      opacity: 1;
      transform: none;
      transition: none;

      @include mobile {
        width: max-content;
      }

      &.pseudo {
        display: none;
      }
    }

    &:hover {
      .caption {
        transform: none;

        &.pseudo {
          display: none;
        }
      }
    }
  }

  .value {
    color: #212120;
    font-weight: 600;
    font-size: 1.25rem;
  }

  &:hover,
  &.is-active {
    background-color: #f5f5f4;
    box-shadow: 0px 1px 1px 0px #0000001a, 0px 2px 2px 0px #00000017;

    .extra-text {
      transform: translateX(0);
      opacity: 1;
    }

    .caption {
      transform: translateX(60px);

      &.pseudo {
        opacity: 0;
      }
    }
  }

  &:active {
    transform: scale(0.95);
  }
}
