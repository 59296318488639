.retry {
  display: table;
  height: 100%;
  width: 100%;
  pointer-events: none;
  .modal-content {
    width: 1119px !important;
    height: 636px !important;
    overflow-y: scroll;
    padding: 44px 44px;

    .cancel-modal-wrapper {
      padding: 24px;
      header {
        display: flex;
      }
    }
    .cancel {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: 32px;
        font-weight: 400;
        line-height: 50px;
        color: #000000;
      }
      .action {
        width: 161px;
        font-size: 15px !important;
        height: 45px;
      }
    }
  }
  .no-selected {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  .failed-checkbox {
    appearance: none;
    background-color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1.5px solid currentColor;
    transform: translateY(-0.13em);
    display: flex;
    place-content: center;
    justify-content: center;
    align-items: center;
  }
  .failed-checkbox::before {
    content: '';
    width: 12px;
    height: 12px;
    clippath: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
  .failed-checkbox:checked::before {
    transform: scale(1);
  }
  .retry-table {
    td,
    th {
      padding: 20px 0 !important;
    }
  }
}
