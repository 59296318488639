@import '../../../scss/base/colors.scss';

.fileUpload {
  position: relative;
  .fileUpload-input {
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: -1;
  }
  .loader-line {
    background: #242628;
    // position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
    border-radius: 0 0 40px 44px;
  }
  .upload-container {
    text-align: center;
    background: transparent;
    border: 1px dashed #e5e5e5;
    box-sizing: border-box;
    height: 160px;
    border-radius: 12px;
    padding: 12px 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #606060;
    position: relative;
  }
  .arrow {
    position: absolute;
    right: 13px;
    top: 12px;
  }
  .add-more {
    // margin-top: -10px;
    border: 1px dashed $grey-300;
    display: flex;
    justify-content: center;
    align-items: center;

    &.sm {
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }

    &.lg {
      width: 130px;
      height: 195px;
      border-radius: 12px;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-in-hover {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.parent-add-img-hover {
  position: relative;

  &:hover {
    .add-img-hover {
      background-color: rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s ease;
      border-radius: 12px;
    }

    .slide-in-hover {
      opacity: 1;
      animation: bounce 0.2s ease forwards;
      transition: transform 0.2s ease;
      cursor: pointer;
    }
  }
}
