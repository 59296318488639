// .dialog-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 9999;
//   // background: #000000a6;
// }

.confirm-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  position: relative;

  .confirm-modal-body {
    background: #ffffff;
    box-shadow: 0 12px 40px rgba(16, 24, 64, 0.24);
    border-radius: 8px;
    width: 380px;
    padding: 32px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .cancel-icon {
      position: absolute;
      top: 32px;
      right: 32px;
      cursor: pointer;
    }

    .delete-icon {
      width: 48px;
      height: 48px;
      // background: #fee4e2;
      // border: 8px solid #fef3f2;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 18px;
      line-height: 35px;
      margin: 0 0 5px;
      text-align: center;
      color: #242628;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      text-align: center;
      color: #586068;
      padding-bottom: 0.5rem;
    }

    .remove-btn {
      background: #dc2626 !important;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06) !important;
      border-radius: 10px !important;
      height: 38px !important;
      color: #fff !important;
      font-size: 14px !important;
    }

    .confirm-btn {
      background: #242628 !important;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06) !important;
      border-radius: 10px !important;
      height: 38px !important;
      color: #fff !important;
      font-size: 14px !important;

      .success {
        background-color: transparent !important;
        border: 2px solid #171721 !important;
        color: #171721 !important;
      }

      .retry {
        background-color: transparent !important;
        border: 2px solid #dc2626 !important;
        color: #dc2626 !important;
      }

      .pending {
        background-color: transparent !important;
        border: 2px solid #bfff0b !important;
        color: #bfff0b !important;
      }
    }

    .confirm-btn:hover,
    .confirm-btn:active {
      color: #bfff0b !important;
      background: #242628 !important;
    }

    .cancel-btn {
      background: transparent !important;
      box-shadow: none !important;
      color: #171721 !important;
      height: 38px !important;
      padding-bottom: 0 !important;
      font-size: 14px !important;
    }

    .border-btn {
      background: transparent !important;
      border: 2px solid #f2f4f1 !important;
      box-shadow: none !important;
      color: #586068 !important;
      height: 40px !important;
      font-size: 14px !important;
    }

    .onboarding-access {
      background-image: url('.../../../../assets/images/notice-pattern.png');
      background-size: cover;
      background-position: center;
      h2 {
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 20px;
      }
      .features {
        .list {
          display: flex;
          gap: 8px;
          align-items: center;
          margin-bottom: 8px;

          span {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
