.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(0.5rem * calc(1 - 0));
  margin-bottom: calc(0.5rem * 0);
}
.teamlist-card-subname {
  display: flex;
  align-items: center;
  .teamlist-squres {
    height: 8px;
    width: 8px;
    background: #e5e8e3;
    margin-left: 6px;
  }
}
