.custom-days {
  display: flex;
  align-items: center !important;

  .days {
    height: 40px;
    width: 40px;
    display: flex;
    justify-items: center !important;
    align-items: center !important;
    background: #f2f4f1;
    font-size: 14px;
    border-radius: 100%;
    margin-right: 10px;
    cursor: pointer;
  }
  .active-day {
    background: #000;
    color: #fff;
  }
}
