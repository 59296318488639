.plan-checkout-page {
  .header-icon {
    background: #f5f5f4;
    border: 1px solid rgba(0, 0, 0, 0.06);

    display: flex;
    align-items: center;
    justify-content: center;

    &.bg-green {
      background: #eeffbd;
    }

    &.bg-bright-green {
      background: #d8ff62 !important;
    }

    &.lg {
      height: 64px;
      width: 64px;
      box-shadow: 0px -2.13px 2.13px 0px #00000033 inset, 0px 1.07px 1.07px 0px #00000014,
        0px 2.67px 5.33px 0px #0000001f !important;
      border-radius: 100%;
    }

    &.md {
      height: 40px;
      width: 40px;
      box-shadow: 0px -0.8px 0.8px 0px #00000033 inset, 0px 0.4px 0.4px 0px #00000014,
        0px 1px 2px 0px #0000001f;
      border-radius: 100%;
    }

    &.sm {
      height: 24px;
      width: 24px;
      box-shadow: 0px -0.8px 0.8px 0px #00000033 inset, 0px 0.4px 0.4px 0px #00000014,
        0px 1px 2px 0px #0000001f;
      border-radius: 100%;
    }
  }

  .pl-4 {
    padding-left: 32px;
  }
  .payment-section {
    .left-side {
      background: #ffffff;
    }

    .right-side {
      background: #fafaf9;
    }
  }

  ///

  .payment-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #161615;
    margin: 0;
  }
  .details-wrapper {
    border-top: 1px solid #e8ebe6;
    border-bottom: 1px solid #e8ebe6;
    padding: 24px 0;
    margin: 24px 0;
  }
  .order-summary {
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #414240;
    }
    .body {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #575855;
    }
    .total {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #414240;
    }
  }

  .payment-options {
    padding-bottom: 18px;
  }
  .payment-item {
    border: 1px solid #e8ebe6;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 12px;
    cursor: pointer;
    h6 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
      color: #242628;
    }
  }
  .active {
    background: #fbf5ec;
    border: 2px solid #d28b28;
    h6 {
      color: #885a1a;
    }
  }
  .radio {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
  }
  .unchecked {
    border: 1px solid #e1e4dd;
  }
  .checked {
    background-color: #d28b28;
  }
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e8ebe6;
    width: 34px;
    height: 24px;
    box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
    border-radius: 4px;
    margin-top: 8px;
    background-color: white;
  }
  .border-right {
    border-right: 1px solid #eaecf0;
  }

  .billing-footer-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6d6f6b;
    a {
      color: #6d6f6b;
      font-weight: 600er;
    }
  }
  .payment-subtext {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6d6f6b;
  }

  .selections {
    .selections-options {
      border: 1.2px solid #f5f5f4;
      background: #f5f5f4;
      border-radius: 8px;
      transition: background 0.3s ease, border 0.3s ease;
      padding: 12px;
      cursor: pointer;
      height: fit-content !important;

      &.active-tab {
        background: white;
        border: 1.2px solid black;
      }
      box-shadow: 0px 1px 1px 0px #0000001a, 0px 2px 2px 0px #00000017,
        0px 5px 3px 0px #0000000d, 0px 10px 4px 0px #00000003, 0px 15px 4px 0px #00000000;

      &:hover {
        background: white;
        border: 1.2px solid black;
      }
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bujeti-balance {
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
          }
          h6 {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            margin: 0;
          }
        }
      }
    }
  }

  .order-summary-wrapper {
    // padding: 32px !important;

    .summary-container {
      padding: 16px;
      gap: 16px;
      border-radius: 12px;
      background: #ffffff;
      border: 1px solid #e8e8e7;
      box-shadow: 0px -0.8px 0.8px 0px #00000033 inset, 0px 0.4px 0.4px 0px #00000014,
        0px 1px 2px 0px #0000001f;

      .summary-first-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        align-items: center;
        padding-bottom: 10px;

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #5c5c5a;
        }

        .interval {
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          color: #d28b28;
        }
      }

      .summary-items {
        .items {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #7f7f7d;
            margin: 0px;
          }
        }

        .total-items {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p:first-of-type {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #0d0d0c;
            margin: 0px;
          }

          p:nth-of-type(2) {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color: #0d0d0c;
            margin: 0px;
          }
        }
      }
    }
  }
}
