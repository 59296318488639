@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.virtual,
.physical {
  cursor: pointer;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 400px !important;
  transition: transform 0.4s ease;

  &:hover {
    transform: scale(1.03);
  }
}

.virtual {
  background-color: #bfff0b !important;
  background-image: url('../../assets/images/card-pattern.png');
}

.flash {
  cursor: pointer;
  background-color: #f2f4f1 !important;
  background-image: url('../../assets/images/bujeti-icon.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 250px !important;
  transition: transform 0.4s ease;

  &:hover {
    transform: scale(1.03);
  }
}

.physical {
  background-color: #000 !important;
  background-image: url('../../assets/images/black-card-pattern.png');
  color: #fff !important;

  h2 {
    color: #fff !important;
  }
}

.budget {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 25px;
  position: absolute;
  top: 0;
  border-radius: 0px 0px 20px 20px;
  color: white;
  left: 50%;
  transform: translate(-50%, 0%);
  min-width: 153px;
  max-width: 200px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-card {
  .status-card {
    border-radius: 8px;
    padding: 6px 12px;
    font-weight: 600;
    transform: translate(10%, 220%);
    position: absolute;
    right: -5%;
    margin: auto 0;
  }

  .active {
    background: #d1fadf;
    color: #039855;
  }

  .inactive {
    background: #fee4e2;
    color: #f79009;
  }

  .blocked {
    background-color: #fee4e2;
    color: #ff0b0b;
  }
}

.pan {
  font-size: 16px;
  font-weight: 530 !important;
  letter-spacing: 0.07em;

  &.small {
    font-family: 'Manrope', sans-serif;
    font-size: 1rem;
    font-weight: 600;
  }
}

.cvv {
  padding-left: 67px;
}

.budget-link a {
  color: #004eeb;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
}

iframe {
  height: 25px;
  width: 100%;
}

.pill-holder {
  height: 24px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #1c1917;
  font-size: 0.875rem;
  box-shadow: 0px 1px 2px 0px #1c19170d;
  border: 1px solid #44403c;
  padding: 0 8px;
  border-radius: 6px;

  &.is-physical {
    background: #1c1917;
  }

  .text-gray {
    color: #d7d3d0 !important;
  }

  .pill-dot {
    background-color: #12b76a;
    height: 6px;
    width: 6px;
    border-radius: 100%;
  }
}

.user-holder {
  display: flex;
  align-items: center;
  padding-bottom: 0.25rem;
  width: 70%;
  font-family: 'Manrope', sans-serif;

  .name {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
  }
}
