.scroll {
  padding-bottom: 20px;
}

.user-view-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border: 1px solid #e5e5e5;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;

  .userIcon {
    background: #bfff0b;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .useRole {
    background: #ecf3f9;
    border-radius: 35px;
    padding: 3px 15px 2px;
  }
}

.balance {
  .subHeaderText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #586068;
  }

  .cardWrapper {
    margin-top: 50px;

    .active-card-bg {
      box-shadow: 0 3px 3px #e7e7e7;
      background: #e6e8e34f;
    }

    .balance-card-wrapper {
      border: 1px solid #e5e5e5;
      border-radius: 0.25rem;
      margin-bottom: 20px;
      padding: 20px 15px;
      align-items: center;
      display: flex;
      cursor: pointer;

      .balance-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }

      .balance-tag {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #ffffff;
        background: #d28b28;
        border-radius: 50px;
        padding: 3px 8px;
        margin-left: 12px;
      }

      .dark-bg {
        background: #586068 !important;
      }

      .balance-sub-title {
        color: #586068;
        font-size: 15px;
      }
    }
  }
}

.InfoBox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e8e3;
  padding: 20px 5px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 20px;
  }

  .information-title-wrapper {
    .information-title {
      font-size: 15px;
      line-height: 20px;
      color: #586068;
      margin: 0;
    }

    .information-value {
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      margin: 0;
    }
  }

  .copy-box {
    background: #f2f4f1;
    border-radius: 30px;
    padding: 5px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
  }
}

body .badgeLable {
  font-size: 15px !important;
  line-height: 20px !important;
  background-color: #f2f4f1 !important;
  color: #000 !important;
  border-radius: 24px !important;
}

body .badgeLable {
  font-size: 15px !important;
  line-height: 20px !important;
  background-color: #f2f4f1 !important;
  color: #000 !important;
  border-radius: 24px !important;
}

.budget__goal--label {
  margin-bottom: 8px;

  span {
    font-size: 14px;
    padding: 0px;
  }
}

.fund__budget-modal {
  .fund__budget-subtext {
    font-size: 14px;
    line-height: 20px !important;
  }

  .fund__budget-options {
    .active {
      cursor: pointer;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      padding: 12px;
      background: #fbf5ec;
      border: 2px #d28b28 solid;
      border-radius: 12px;
      margin-bottom: 10px;

      .icon-wrap {
        margin-right: 16px;
      }

      .text-wrap {
        h3 {
          font-size: 16px;
          color: #885a1a;
          font-weight: 600;
          margin-bottom: 12px;
          margin-top: 0px;
        }

        p {
          font-size: 16px;
          color: #d28b28;
          font-weight: 400;
          line-height: 0px;
          margin: 0px;
        }
      }

      .check-wrap {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 14px;
        margin-top: 10px;
      }
    }

    .inactive {
      cursor: pointer;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      padding: 12px;
      background: #ffffff;
      border: 2px #eff0ed solid;
      border-radius: 12px;
      margin-bottom: 10px;

      .icon-wrap {
        margin-right: 16px;
      }

      .text-wrap {
        h3 {
          font-size: 16px;
          color: #414240;
          font-weight: 600;
          margin-bottom: 12px;
          margin-top: 0px;
        }

        p {
          font-size: 16px;
          color: #414240;
          font-weight: 400;
          line-height: 0px;
          margin: 0px;
        }
      }

      .check-wrap {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 14px;
        margin-top: 10px;
      }
    }
  }
}

.budget__payment--InfoBox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;

  .information-title-wrapper {
    .information-title {
      font-size: 15px;
      line-height: 20px;
      color: #586068;
      margin: 0;
    }

    .information-value {
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      margin: 0;
    }
  }

  .copy-box {
    background: #f2f4f1;
    border-radius: 30px;
    padding: 5px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;

    svg {
      margin-left: 10px;
      margin-top: 3px;
    }
  }
}

.owners-tooltip {
  h6 {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  span {
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: #79716b;
  }
}

.buffer,
.expiration,
.splitting-rules {
  span {
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: #79716b;
  }
}

.buffer,
.expiration {
  h6 {
    padding-top: 2px;
  }
}

.buffer {
  padding-left: 8px;

  .buffer-wrapper {
    display: flex;

    h6 {
      color: #44403c;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  }

  .buffer-limit-text {
    color: #57534e;
    font-size: 12px;
    margin-top: 6px;
    padding-left: 10px;
  }
}

.expiration,
.splitting-rules {
  h6 {
    color: #44403c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }

  .date-input {
    padding: 14px !important;
  }
}

.splitting-rules {
  .ant-checkbox {
    margin-left: 8px;
  }

  .ant-checkbox + span {
    color: #44403c;
  }
}

.expiration {
  padding-left: 8px;
}

.format-recurrence-option {
  display: flex;
  align-items: center;
  gap: 8px;

  h1 {
    font-size: 16px;
    color: #1c1917;
    font-weight: 600;
    margin: 0;
  }

  span {
    color: #b4a7a7;
  }
}

.split-users {
  padding-left: 8px;

  span {
    color: #44403c;
    font-size: 14px;
  }

  .amount {
    color: #57534e;
  }

  .amount-icon {
    left: 18px !important;
  }
}

.budget-recurrence {
  .css-9gakcf-option {
    background-color: #fafaf9 !important;
  }
}

.information-wrapper,
.beneficiaries {
  .modal-footer {
    background-color: #fff;
    bottom: 0px !important;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding: 16px 32px;
    position: fixed !important;
    width: 520px;
    right: 0px;
    border-top: 1px solid #d7d3d0;

    .custom-button {
      height: 40px !important;
      padding: 10px !important;
      width: 87px;
      min-width: 100px;
      font-size: 14px !important;
    }

    .ghost-button {
      background-color: #fff !important;
      border: 1px solid #e1e4dd !important;
      box-shadow: 0 1px 2px rgba(22, 22, 21, 0.05) !important;
      color: #242628 !important;
    }

    .primary-button {
      height: 40px !important;
      width: max-content;
    }
  }
}

.budget-recurrence div[aria-disabled='false'] {
  cursor: pointer !important;
}

.budget-recurrence div[aria-disabled='true'] {
  cursor: not-allowed !important;
}

.splitting-rules {
  .ant-checkbox-wrapper {
    span:last-child {
      padding-left: 8px;
    }
  }
}
