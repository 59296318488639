.mandate-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  position: relative;

  .mandate-modal-body {
    background: #ffffff;
    box-shadow: 0 12px 40px rgba(16, 24, 64, 0.24);
    border-radius: 8px;
    width: 500px;
    padding: 32px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &.invoice {
      padding: 0;
      width: 642px;
      display: block;
      border-radius: 12px;
      box-shadow: 0px 8px 8px -4px #10182808;

      &.schedule {
        width: 400px !important;
        box-shadow: 0px 20px 24px -4px #10182814;

        .cancel-icon {
          right: 18px;
          top: 16px;
        }

        .header {
          color: #1c1917;
          font-weight: 600;
          font-size: 1rem;
          margin-bottom: 0 !important;
        }

        .date-holder {
          border-top: 1px solid #e7e5e4;
          border-bottom: 1px solid #e7e5e4;
        }

        button {
          height: 36px !important;
          font-size: 0.875rem !important;
          padding: 0 16px !important;
          border-radius: 8px !important;
          cursor: pointer;

          &.cancel-button {
            background: inherit !important;
            color: #57534e !important;
            font-weight: 500 !important;
            border: 1px solid #d7d3d0 !important;
            box-shadow: none !important;
          }

          &.confirm-button {
            background: #000000;
            color: white;
            border: none;
          }

          &:hover {
            opacity: 0.75;
          }

          &:active {
            opacity: 0.6;
          }

          &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }
        }

        .date-label {
          padding-bottom: 4px;
          color: #44403c;
          font-weight: 500;
          text-align: left !important;
        }
      }

      .customer-modal-header {
        display: flex;
        padding: 24px;
        border-bottom: 1px solid #e7e5e4;

        h5 {
          font-size: 1rem;
          color: #1c1917;
          font-weight: 600;
        }

        span {
          color: #79716b;
          font-size: 0.875rem;
        }
      }

      .customer-form-holder {
        padding: 0 24px;

        .label {
          display: block;
          color: #44403c;
          font-weight: 500;
          font-size: 0.875rem;
          margin-bottom: 0.5rem !important;
        }
      }
    }

    .cancel-icon {
      position: absolute;
      top: 32px;
      right: 32px;
      cursor: pointer;
    }

    .back-icon {
      position: absolute;
      top: 32px;
      left: 32px;
      cursor: pointer;
    }

    .delete-icon {
      width: 48px;
      height: 48px;
      // background: #fee4e2;
      // border: 8px solid #fef3f2;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 20px;
      line-height: 35px;
      margin: 0 0 5px;
      text-align: center;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      text-align: center;
    }

    .remove-btn {
      background: #dc2626 !important;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06) !important;
      border-radius: 10px !important;
      height: 40px !important;
      color: #fff !important;
    }

    .confirm-btn {
      background: #242628 !important;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06) !important;
      border-radius: 10px !important;
      height: 40px !important;
      color: #fff !important;

      .success {
        background-color: transparent !important;
        border: 2px solid #171721 !important;
        color: #171721 !important;
      }

      .retry {
        background-color: transparent !important;
        border: 2px solid #dc2626 !important;
        color: #dc2626 !important;
      }

      .pending {
        background-color: transparent !important;
        border: 2px solid #bfff0b !important;
        color: #bfff0b !important;
      }
    }

    .confirm-btn:hover,
    .confirm-btn:active {
      color: #bfff0b !important;
      background: #242628 !important;
    }

    .cancel-btn {
      background: transparent !important;
      box-shadow: none !important;
      color: #171721 !important;
      height: 50px !important;
      padding-bottom: 0 !important;
    }

    .border-btn {
      background: transparent !important;
      border: 2px solid #f2f4f1 !important;
      box-shadow: none !important;
      color: #586068 !important;
      height: 42px !important;
    }
  }
}
.swtich-wrapper {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #f5f5f4;
  background: #fafaf9;

  .swtich-text {
    width: 50%;
    cursor: pointer;
    display: flex;
    padding: 4px 2px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    display: flex;
    border-radius: 6px;
    background: #fafaf9;
  }

  .active-switch {
    border-radius: 6px;
    background: #fff !important;

    box-shadow: 0px 1px 3px 0px rgba(28, 25, 23, 0.1),
      0px 1px 2px 0px rgba(28, 25, 23, 0.06);
  }
}

.mandate-wrapper {
  h4 {
    color: #1c1917;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }

  .desc {
    color: #79716b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  ul {
    list-style: inside;
    list-style-type: decimal;
    color: #44403c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
