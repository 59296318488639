.actionsIcon {
  font-size: 20px;
  padding-right: 5px;
  position: relative;
  top: -4px;
}

.budget-overview-amount {
  .currency-amount {
    font-weight: 500;
  }

  * {
    color: #586068;
  }

  &.dark {
    * {
      color: #000000;
    }
  }

  p {
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  h2 {
    font-size: 20px;
    margin: 0;
  }

  .tiny-square {
    width: 6px;
    height: 6px;
    display: block;
    margin-right: 10px;
  }

  .budget-goal {
    margin-top: 8px;
    align-items: center;

    div {
      font-size: 18px;
      color: #d28b28;
      margin-left: 12px;
    }

    .goal-amount {
      font-size: 16px !important;
      color: #1c1917;
      margin: 0;

      span {
        font-weight: 500;
        margin: 0;
      }
    }

    .bufferamount {
      font-size: 16px !important;
      display: flex;
      margin: 0;
      color: #79716b !important;

      span {
        color: #79716b !important;
        font-size: 16px;
        font-weight: 500;
        margin-right: 4px;
      }
    }

    .cursor {
      font-size: 14px;
    }

    .distance-between {
      padding: 0 4px;
    }
  }

  .expire-label {
    font-size: 14px;
    gap: 12px;

    span {
      color: #d28b28;
    }
  }
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    font-size: 10px !important;
    h2 {
      font-size: 16px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .budget-overview-amount.mb-4 {
    margin-bottom: 0.5rem !important;
  }
}

.mt-200 {
  margin-top: 200px;
}

.black-button {
  background-color: #121212 !important;
  color: #fff !important;
}

.add-custom {
  min-width: 90px;
  border-radius: 8px !important;
  padding: 9px 6px 9px 6px !important;
  font-size: 0.875rem !important;
  background: inherit !important;
  border: 1px solid #e8e8e7 !important;
  transition-duration: 300ms !important;
  height: 36px !important;
  box-shadow: 0px 0.4px 0.4px 0px #00000014, 0px 1px 2px 0px #0000001f !important;

  &.min-w-fit {
    padding: 0 0.75rem !important;
    min-width: fit-content !important;
  }

  &.approval-btn {
    color: #7f7f7d;
    font-weight: 500;
  }

  &:disabled {
    pointer-events: none;
    background: transparent !important;
  }

  &:hover {
    background-color: #f5f5f4;
  }
}

.separator {
  border-left: 1px solid #d7d3d0;
  padding: 10px 8px;
  margin-left: 8px;
}

.budget-analytics {
  .analytics-card {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .insights-title {
    padding: 22px 0;
    border-bottom: 1px solid #e7e5e4;

    .text {
      color: #1c1917;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .small-text {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .card-list__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin-bottom: 0;
    transition-duration: 300ms;

    &:hover {
      padding: 0 6px;
      transform: scale(1.03);
      background-color: #fafaf9;
      border-radius: 12px !important;
    }
  }

  .border-bottom {
    border-bottom: 1px solid #e7e5e4;
  }

  .card-list__content {
    display: flex;
    align-items: center;
    gap: 6px;

    .card-list__text {
      font-size: 14px;
      font-weight: 500;
      color: #1c1917;
    }

    .card-list__subtext {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .num-wrapper {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px !important;
      width: 40px !important;
    }
  }

  .user-list {
    p {
      font-weight: 500;
      font-size: 14px;
      color: #79716b;
      line-height: 0;
    }

    .user-text {
      color: #1c1917;
      font-size: 14px;
    }

    .text-orange {
      color: #d28b28;
    }
  }
}

.recurring-tag {
  border-radius: 16px !important;
  border: 1px solid #e7e5e4 !important;
  background: #f5f5f4 !important;
  mix-blend-mode: multiply !important;
  color: #44403c;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 0px 10px;
  text-transform: capitalize;
  white-space: nowrap;
}

.selected-budget-table {
  .table-wrapper {
    .table-responsive {
      max-height: calc(100vh - 25.7rem) !important;
    }
  }
}

.selected-budget-beneficiaries-table {
  .table-wrapper {
    .table-responsive {
      max-height: calc(100vh - 24.7rem) !important;
    }
  }
}
