.show-all-font {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #d28b28;
  cursor: pointer;
}
.apply-btn {
  height: 40px;
  font-size: 15px !important;
}
.set-height-filter {
  max-height: max-content !important;
}
.slider-wrapper {
  padding: 0 5px;
  .amount-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 16px;
    color: #586068;
  }
}

.add-beneficiary-filter {
  background-color: #fff;
  border-top: 2px solid #eee !important;
  border: none;
  color: #d28b28;
  padding: 20px 10px 10px 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 10px;
  cursor: pointer;
}

.export-wrap {
  z-index: 11111;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 5px 0;
  margin-top: 10px;
  border: 1px solid gainsboro;
  max-height: fit-content;
  overflow-y: scroll;
  min-width: 200px;
  width: 10px;
  transform: translateX(-30%);

  .download-file {
    color: gray;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    font-size: 12px;
    padding: 10px 8px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    border: 1.4px #d9ddd5 solid;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: #d9ddd5;
      color: gray;
    }
  }
}
