.card-modal-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  .modal-pagination {
    color: #898989;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      margin: 0 3px;
    }
    .active-text {
      font-weight: 600;
      color: #000;
    }
  }
}

.frequency-label {
  h3 {
    font-size: 15px;
  }
  p {
    font-size: 12px;
    line-height: 10px;
  }
}

.edit-policy-notice {
  font-size: 14px;
  color: #dc6803;
}

.custom-dialog {
  .modal-content {
    background: transparent !important;
    border: none !important;
  }
}

.policy-detail-wrapper {
  background: #f2f4f1;
  border-radius: 12px;
  padding: 24px;
  .policy-detail-text {
    h3 {
      font-size: 18px;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
    }
  }
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(1rem * calc(1 - 0));
  margin-bottom: calc(1rem * 0);
}

// quick action
.create__vendor-banner {
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  padding: 16px;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  text-align: start !important;
  display: flex;
  flex-direction: row;
  background: #f5f5f4;
  box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);
  .banner-text {
    h1 {
      color: #1c1917;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    p {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: start !important;
    }
  }

  .actions {
    button {
      &.btn {
        color: #d28b28 !important;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    .btn {
      padding: 0px !important;
      width: fit-content !important;
      background: transparent !important;
      border: none !important;
    }
  }
}

.completed-text {
  h1 {
    color: #101828;
    text-align: center;

    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
  p {
    color: var(--Gray-500, #79716b);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
  }
}
