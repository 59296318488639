@import '../../scss/base/colors.scss';
@import '../../scss/typography.scss';

.referral__metric-card {
  width: 100%;

  @media (min-width: 1024px) {
    width: 31.333333%;
  }
}

.metric-card {
  width: 100%;
  padding: 4px;

  &:last-child {
    padding-right: 0px;
  }

  &:first-child {
    padding-left: 0px;
  }

  @media (min-width: 1024px) {
    min-width: 20%;
    max-width: 25%;
  }
}

.referral__how {
  padding: 20px 0;
  border-bottom: 1px solid $grey-200;

  h6 {
    color: $grey-900;
    font-size: $text-lg;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  p {
    color: $grey-500;
    font-size: $text-sm;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.referral__how-list {
  display: grid;
  gap: 1rem;
  padding: 24px 0;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .space > :not([hidden]) ~ :not([hidden]) {
    margin-top: calc(2rem * calc(1 - 0));
    margin-bottom: calc(2rem * 0);
  }
}

.referral__share {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid $grey-200;
  background: $base-white;
  box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);

  .share-link {
    p {
      color: $grey-500;
      font-size: $text-sm;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .forms {
      display: flex;
      align-items: center !important;
      flex-direction: row;
      gap: 10px;

      .svg {
        svg {
          path {
            transition: fill 0.3s;
          }
        }

        svg:hover {
          path {
            fill: $grey-500;
          }
        }
      }

      .share-input {
        height: 100%;
      }
    }
  }

  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 1rem 0;

    &::before,
    &::after {
      content: '';
      flex-grow: 1;
      height: 1px;
      background-color: $grey-200;
    }

    .divider-text {
      padding: 0.5rem;
      font-weight: 600;
      color: #333;
      background-color: $base-white;
    }
  }
}

.referral__table-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  width: 100%;
  margin: 12px 0;

  .referral__table-header {
    width: 100%;
    border: 1px solid $grey-200;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 0px;
    padding: 16px;

    h5 {
      color: $grey-900;
      font-size: $text-lg;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin: 0px;
    }
  }

  .referral__table-body {
    border-left: 1px solid $grey-200;
    border-right: 1px solid $grey-200;
    border-bottom: 1px solid $grey-200;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
  }

  .table-wrapper__border {
    border-radius: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;

    th {
      border-radius: 0px !important;

      &:first-child {
        padding-left: 24px !important;
      }
      &:last-child {
        padding-left: 16px !important;
      }
    }

    td {
      &:first-child {
        padding-left: 24px !important;
      }

      &:last-child {
        padding-right: 16px !important;
      }
    }
  }
}
