.integrations {
  .header {
    margin-top: 32px;
    h6 {
      // font-weight: 600;
      // font-size: 18px;
      line-height: 28px;
      color: #161615;
      margin: 0px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #575855;
    }
  }
  .integrations__list {
    margin-top: 24px;
  }
  .items {
    background: #ffffff;
    border: 1px solid #eff0ed;
    border-radius: 12px;
    padding: 27px 27px 16px 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    min-height: 227px;
    .body {
      padding-top: 15px;
    }
    button {
      width: 100%;
      padding: 10px 0;
      background: #ffffff;
      border: 1px solid #d1d3cf;
      box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
      border-radius: 8px;
      font-size: 14px;
    }
    .is-disabled {
      color: #d1d3cf;
      background-color: white;
      border: 1px solid #eff0ed;
    }
    .is-disconnect {
      border: 1px solid #fda29b;
      color: #b42318;
    }
    .connected {
      padding: 2px 8px;
      background: #ecfdf3;
      mix-blend-mode: multiply;
      border-radius: 16px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #027a48;
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
}
.details__wrapper {
  width: 100%;
  z-index: 10px;
  padding: 32px;
  .goBack {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #d28b28;
    cursor: pointer;
  }
}

.connection__description {
  width: 588px;
  margin-top: 32px;
  background: #ffffff;
  border: 1px solid #eff0ed;
  box-shadow: 0px 1px 3px rgba(22, 22, 21, 0.1), 0px 1px 2px rgba(22, 22, 21, 0.06);
  border-radius: 12px;
  header {
    padding: 24px 32px;
  }
  .logo__container {
    gap: 20px;

    .option-logo {
      display: flex;
      svg {
        width: 56px;
        height: 56px;
      }
    }
  }
  .logo__container--item {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eff0ed;
    border-radius: 99px;
  }
  .title {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #161615;
    margin: 0;
    padding-top: 24px;
  }
  main {
    padding: 32px;
    .values {
      display: flex;
      align-items: start;
      gap: 16px;
      span {
        width: 40px;
        height: 40px;
        background: #f7f7f6;
        border-radius: 20px;
      }
      .details {
        flex: 2;
        padding-top: 0;
        h6 {
          font-weight: 600;
          font-size: 18px;
          line-height: 16px;
          color: #161615;
          margin-bottom: 4px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #575855;
          margin: 0px;
        }
      }
    }
  }

  footer {
    padding: 24px 0;
    border-top: 1px solid #eff0ed;
    button {
      padding: 10px 18px;
      gap: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      background: #000000;
      border: 1px solid #000000;
      border-radius: 8px;
      color: #fff;
      text-transform: capitalize;
      &:disabled {
        background-color: #575855;
      }
    }
  }
}
.category__selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .full__width {
    width: 100%;
  }
  .category__selector--list {
    gap: 21px;
  }
}
.connector__wrapper {
  width: 800px;
  margin-top: 32px;
  header {
    display: flex;
    justify-content: space-between;
    div:first-child {
      width: 70%;
    }
    .title {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #161615;
    }
    .connected-title {
      text-transform: capitalize !important;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #575855;
    }
    .action__btn {
      display: flex;
      align-items: center;
      gap: 12px;
      button {
        padding: 10px 16px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        &:first-child {
          background: #ffffff;
          border: 1px solid #d1d3cf;
          color: #414240;
        }
        &:last-child {
          background: #000000;
          border: 1px solid#000;
          color: #fff;
        }
      }
    }
  }
  main {
    margin-top: 34px;
    .category-header {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #161615;
      text-transform: capitalize;
      div {
        display: flex;
        gap: 10px;
      }
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.category__selector--item {
  flex: 1;
}

.integrations__deleteModal {
  padding: 24px;
  header {
    display: flex;
    .cancel {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 1px solid #eff0ed;
      box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
      border-radius: 10px;
    }
  }
  main {
    padding-top: 16px;
    h1 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #161615;
      margin-bottom: 0;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #575855;
    }
  }
  footer {
    gap: 8px;
    display: flex;
    margin-top: 32px;
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 18px;
      background: #ffffff;
      border: 1px solid #e1e4dd;
      box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
      border-radius: 8px;
      flex: 1;
      &:last-child {
        background-color: black;
        border: 1px solid black;
        color: #fff;
      }
    }
  }
}

.category__optionsWrapper {
  footer {
    margin-top: 24px;
    border-top: 1px solid #eff0ed;
    width: 100%;

    div {
      width: 100%;
      justify-content: flex-end;
    }
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 18px;
      background: #ffffff;
      border: 1px solid #e1e4dd;
      box-shadow: 0px 1px 2px rgba(22, 22, 21, 0.05);
      border-radius: 8px;
    }
    button:last-child {
      background-color: black;
      color: white;
      border: 1px solid #000;
    }
  }
  .logo__container--item {
    border: 1px solid #d1d3cf;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }
}
