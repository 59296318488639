.kyc_tab-wrapper {
  width: 100%;

  .tab-list {
    display: flex;
    flex-direction: column;

    .tab-items {
      width: 242px;
      padding: 8px;
      gap: 2px;
      border-radius: 8px;
      border: none;
      display: flex;
      flex-direction: row;
      // justify-content: center;
      align-items: center;
      background: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #79716b;
    }

    .tab-items:hover {
      background: #f5f5f4;
      color: #1c1917;
    }

    .tab-items-active {
      background: #f5f5f4;
      color: #1c1917;
    }
  }
}

// quick action
.quickaction_banner-wrapper {
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  padding: 24px;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: #fbf5ec;
  box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.2);
  .banner-text {
    h1 {
      color: #1c1917;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
    p {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 558px;
    }
  }
  .image-banner {
    height: 100%;
    object-fit: scale-down;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: 1224px) {
    .image-banner {
      display: none;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 12px;

    button {
      color: #57534e;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    // button:nth-child(2) {
    //   &:hover {
    //     background: #fff;
    //     color: #000;
    //   }
    // }

    .btn-border {
      border-radius: 8px;
      border: 1px solid #d7d3d0;
      background: #fff;
      &:hover {
        background: #f5f5f4;
      }
    }

    @media (max-width: 640px) {
      display: flex !important;
      flex-direction: column !important;
      align-items: start !important;

      button {
        display: flex !important;
        width: 100%;
        justify-content: center !important;
      }
    }
  }
}

//todoItems styles
.todo-wrapper {
  padding-top: 32px;
  padding-bottom: 32px;
  .setup-title {
    h1 {
      color: #1c1917;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    p {
      color: #79716b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .todo-items {
    display: flex;
    min-width: 560px;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #e7e5e4;

    .border-b {
      border-bottom: 1px solid #e7e5e4;
    }

    .todo-list {
      padding: 16px 0;

      .btn-border {
        color: #57534e;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        display: flex;
        width: 118px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #d7d3d0;
        background: #fff;

        &:hover {
          background: #f5f5f4;
        }
      }

      .active-btn {
        border: 1px solid #000 !important;
        background: #000 !important;
        color: #fff !important;

        &:hover {
          background: #292524 !important;
        }
      }

      @media (max-width: 640px) {
        display: flex !important;
        flex-direction: column !important;
        align-items: start !important;

        .ms-auto {
          margin: 0px !important;
          margin-top: 12px !important;
          width: 100% !important;
          button {
            width: 132px !important;
            display: flex;
            transform: translateX(50px);
          }
        }

        .completed {
          transform: translateX(50px);
        }
      }

      .completed {
        font-size: 14px;
        font-weight: 300;
        margin: 0;
        padding: 0;
        color: #16b364;
      }
    }

    @media (max-width: 640px) {
      min-width: 100%;
    }
  }
  .list-title {
    h4 {
      color: #1c1917;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding: 0;
      margin: 0;
    }

    .text-gray {
      color: #7f7f7d;
    }

    p {
      color: #79716b;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      padding: 0;
      margin: 0;
    }
  }
}

.notification-banner-wrap {
  display: flex;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;

  p {
    color: #1c1917;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &.notice {
    background: #eff4ff;
  }

  &.error {
    background: #fee4e2;
  }
}
