@import '../../../scss/abstracts/variables.scss';
@import '../../../scss/base/colors.scss';
@import '../../../scss/typography.scss';

.custom-drawer {
  background-color: rgba(31, 32, 41, 0.75);
  position: fixed;
  display: none;
  flex-wrap: wrap;
  justify-content: end;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000000000;

  &.bg-none {
    background-color: unset;
  }

  .content {
    width: 520px;
    background-color: white;
    height: 100vh;
    overflow-y: scroll;
  }

  .drawer-content-wrapper {
    width: 100vw;
    display: flex;
    justify-content: flex-end;
  }

  .card-badge {
    background: $base-white !important;
    border: 1px solid $grey-100 !important;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;

    img {
      width: 22.36px;
      height: 13.4px;
    }
  }

  .card-modal-footer {
    @include fixedTo(bottom, 0px);
    @include borderStyle(top, 1px, solid, $grey-200);
    width: $width-drawer;
    @include padding(10px, 16px, 10px, 16px);
    background-color: $base-white !important;
    display: flex;
    gap: 12px;
    z-index: 1;

    @media screen and (max-width: 480px) {
      display: grid;
      justify-content: unset !important;
      .justify-content-end {
        justify-content: unset !important;
      }
    }
  }

  .card-modal-header {
    @include padding(24px, 32px, 0, 32px);
    padding-bottom: 0px !important;

    h2 {
      margin-bottom: 0px;
    }
  }

  .card-modal-body {
    @include padding(24px, 32px, 125px, 32px);
    background-color: $grey-50;
    margin-top: 24px;
  }

  .detail-thumbnail,
  .timeline-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }

  .thumbnail-medium {
    width: 56px;
    height: 56px;
    font-size: $text-xl !important;
  }

  .thumbnail-primary {
    background-color: $primary !important;
  }

  .thumbnail-small {
    width: 24px;
    height: 24px;
  }

  .text-error {
    color: $error-700 !important;
  }

  .danger-button {
    @include borderStyle(full, 1px, solid, $error-300);
    background-color: transparent !important;
    @include shadow(xs);
    color: $error-700;

    &:hover {
      background-color: $error-700;
      color: white !important;
    }
  }

  .black-transparent {
    border: 1px solid $grey-300 !important;
    background: $base-white !important;
    color: $grey-700 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .textareaLabel,
  .uploadFileLabel,
  .groupWrapper label {
    color: $grey-500 !important;
    font-size: $text-sm;
    font-weight: $font-medium;
    margin-bottom: 6px;
    margin-top: 0px;
  }

  .form-control {
    font-size: $text-sm !important;
  }

  .uploadFileBorder {
    border: 1px dashed $grey-300 !important;
    background-color: white;
  }

  .css-b62m3t-container {
    background-color: $base-white !important;
  }

  .decline-request-container {
    padding: 24px 32px 125px 32px;
  }
}

.show-drawer {
  display: flex;
}

.underline span {
  color: $secondary-500 !important;
  text-decoration: underline;
}

.danger-outline-button {
  @include borderStyle(full, 1px, solid, $error-300);
  background-color: transparent !important;
  @include shadow(xs);
  color: $error-700;

  &:hover {
    background-color: $error-700;
    color: white !important;
  }
}

.dark-outline-button {
  border: 1px solid $grey-300 !important;
  background: $base-white !important;
  color: $grey-700 !important;

  &:hover {
    background-color: #fafaf7 !important;
    color: #2b2c2b !important;
  }
}

.recipient-link {
  text-decoration: none;
  color: rgb(33, 37, 41);
  &:hover {
    color: rgba(33, 37, 41, 0.8);
  }
}
