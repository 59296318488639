.people-wrapper {
  width: 100%;
  .infinite-scroll-component {
    display: flex;
    row-gap: 24px;
    column-gap: 24px;
    flex-wrap: wrap;
    height: 100%;
    width: 100% !important;
  }
  .nav-item {
    .active {
      padding-bottom: 11px;
    }
  }
}

.count {
  height: 20px !important;
  display: flex;
  justify-items: center !important;
  align-items: center !important;
  background: #f2f4f1;
  font-size: 0.625rem;
  border-radius: 100%;
  padding: 0 8px !important;
  margin-left: 5px;

  span {
    width: inherit;
  }
}

.upload-member-dialog {
  .uploadFileBorder {
    border: 1px solid #e5e8e3 !important;
    background-color: white;
    height: auto;
    border-radius: 12px;
  }
}
