@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}
.batch_payment-container {
  .back-click {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #d28b28;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

  .headerText {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color: black !important;

    //text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    &.large {
      padding-top: 10px;
      font-size: 35px;
    }
  }

  .subtext {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .transaction_review-header {
    font-weight: 600;
    font-size: 18px;
    margin-top: 46px;
  }

  .download-item {
    border: gainsboro solid 2px;
    border-radius: 8px;
    cursor: pointer;
  }

  .text-download {
    h5 {
      font-size: 14px;
      font-weight: 600;
    }

    p {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .review-batch-table {
    width: 100%;
    .review-batch-table_header {
      padding: 16px;
      border: 1px #d7d3d0 solid;
      border-radius: 12px 12px 0px 0px;

      h6 {
        font-size: 18px;
        font-weight: 600;
        color: #1c1917;
      }

      p {
        color: #79716b;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .review-batch-filter {
      padding: 8px 16px 16px 16px;
      border-bottom: 1px #d7d3d0 solid;
      border-left: 1px #d7d3d0 solid;
      border-right: 1px #d7d3d0 solid;
    }

    .table-wrapper {
      .total-amount-wrapper {
        margin-top: -16px;
        padding: 0 16px !important;
        border-radius: 12px !important;
        border-top: 0px !important;
        border-bottom: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
        background-color: #fafaf9;
        width: 100%;
        position: sticky;
        bottom: 0;
        td {
          background-color: #fafaf9 !important;
          border-bottom: 0px !important;
        }
      }

      .table-wrapper__border {
        border-radius: 0px 0px 10px 10px !important;
        border-top: 0px !important;
        // padding-bottom: -10px !important;
        border-bottom: 1px #d7d3d0 solid !important;
        border-left: 1px #d7d3d0 solid !important;
        border-right: 1px #d7d3d0 solid !important;

        th {
          border-radius: 0px !important;

          &:first-child {
            padding-left: 24px !important;
          }
          &:last-child {
            padding-left: 16px !important;
          }
        }

        td {
          &:first-child {
            padding-left: 24px !important;
          }

          &:last-child {
            padding-right: 16px !important;
          }
        }
      }
    }

    .approvals-flow-wrapper {
      padding: 20px 0;
      border-top: 1px solid #d7d3d0;
      border-bottom: 1px solid #d7d3d0;
      h1 {
        font-size: 18px;
        font-weight: 600;
        color: #1c1917;
      }
    }

    .attachment {
      h3 {
        font-size: 18px;
        font-weight: 600;
        color: #1c1917;
      }
    }
  }

  .detail-thumbnail,
  .timeline-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }

  .thumbnail-medium {
    width: 56px;
    height: 56px;
    font-size: 20px !important;
  }

  .thumbnail-primary {
    background-color: #bfff0b !important;
  }

  .thumbnail-small {
    width: 24px;
    height: 24px;
  }
}

.custom-table-dimension {
  tr.position-relative {
    position: relative;
  }

  td.position-sticky {
    position: sticky;
    z-index: 1;
  }

  .cancel.position-sticky {
    right: 0;
    background-color: white;
  }
}

.bulk-payment-table th {
  color: #586068 !important;
  font-weight: 500;
  font-size: 14px;
}

.custon-form-field {
  color: #79716b !important;
  border: 1px solid #79716b;
  font-size: 14px !important;
}

.bulk-payment-page {
  .table-wrapper .table-responsive {
    max-height: calc(100vh - 32rem) !important;
  }

  .subtext {
    color: #586068;
    font-weight: 400;
  }
  .button-dimension-fit-content {
    font-size: 14px !important;
    min-width: fit-content !important;
  }
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #e7e5e4 !important;
  border-radius: 4px;
}

.selected-side-modal {
  .modal-footer {
    background-color: #fff;
    bottom: 0;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding: 16px 12px;
    position: absolute;
    width: 100% !important;
    right: 0px;
    border-top: 1px solid #d7d3d0;

    .custom-button {
      height: 40px !important;
      padding: 10px !important;
      width: 87px;
      min-width: 100px;
      font-size: 14px !important;
    }

    .ghost-button {
      background-color: #fff !important;
      border: 1px solid #e1e4dd !important;
      box-shadow: 0 1px 2px rgba(22, 22, 21, 0.05) !important;
      color: #242628 !important;
    }

    .primary-button {
      height: 40px !important;
      width: max-content;
    }
  }
}

.floating-cta-container {
  min-height: 50px;
  min-width: 300px;
  max-width: fit-content;
  padding: 16px;
  position: sticky;
  bottom: 30px;
  background-color: #ffffff;
  margin-bottom: 32px;

  .cta-divider {
    width: 1px;
    background-color: #e7e5e4;
    margin: 0 1rem;
    flex-shrink: 0;

    @include mobile {
      display: none;
    }
  }
  .selected-information {
    font-size: 14px;
    line-height: 20px;
    padding-right: 12px;
  }
  box-shadow: 0px 8px 8px -4px #1c191708;
  .floating-cta.add-button {
    min-width: 100px;
    max-width: none !important;
    padding: 0 !important;
    padding-bottom: 0 !important;
    font-size: 14px !important;
  }
  .floating-cta.dark-button {
    color: #fff !important;
  }
  .floating-cta.delete-cta {
    color: #d92d20 !important;
    border: 1px solid #fda29b !important;
  }
  .custom-btn-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
  }
}
