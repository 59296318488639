/* modal.module.css */

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.modal__wrap {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99 !important;
  overflow-x: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(3px);
}

.visible {
  pointer-events: auto;
  opacity: 1;
}

.visible .modal {
  width: 520px;
  padding: 24px 34px;
  animation: slideInFromRight 0.3s ease-in-out;
  @media screen and (max-width: 480px) {
    padding: 24px 32px !important;
    width: 100vw;
  }
}

.modal {
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  display: block;
  width: 0;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  margin-left: auto;
  background-color: #fff;
  align-self: center;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.asset__holder {
  width: calc(100vw - 33rem);
}
