.stats__wrapper {
  width: 100%;
  padding-top: 30px;
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.stats__card {
  background: rgba(242, 244, 241, 0.5);
  padding: 24px 24px 24px 24px;
  flex: 1;
  border-radius: 12px;
  .stats__card--charts {
    height: 100%;
    width: 100%;
    .recharts-wrapper {
      position: unset !important;
      display: flex !important;
      flex-direction: row;
      height: auto !important;
      gap: 4px;
      width: 100% !important;
      .recharts-legend-wrapper {
        position: unset !important;
        padding: 0 !important;
        ul {
          width: fit-content !important;
          padding: 0 !important;
        }
      }
    }
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 16px;
      font-weight: 600;
      color: #242628;
      margin-bottom: 0;
    }
    span {
      cursor: pointer;
    }
  }
  .wrapper {
    display: flex;
    gap: 20;
    align-items: center;
    margin-top: 24px;
  }
  .stats__card--data {
    display: flex;
    flex-direction: column;
    span {
      color: #696c80;
      font-size: 12px;
      padding-bottom: 12px;
    }
  }
  .empty-state {
    display: flex;
    align-items: center;
    padding-top: 24px;
    span {
      font-size: 12px;
      color: #000000;
      margin-left: 26px;
    }
  }
}
