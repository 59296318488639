.card-modal-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  .modal-pagination {
    color: #898989;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      margin: 0 3px;
    }
    .active-text {
      font-weight: 600;
      color: #000;
    }
  }
}

.frequency-label {
  h3 {
    font-size: 15px;
  }
  p {
    font-size: 12px;
    line-height: 10px;
  }
}

.edit-policy-notice {
  font-size: 14px;
  color: #dc6803;
}

.custom-dialog {
  .modal-content {
    background: transparent !important;
    border: none !important;
  }
}

.policy-detail-wrapper {
  background: #f2f4f1;
  border-radius: 12px;
  padding: 24px;
  .policy-detail-text {
    h3 {
      font-size: 18px;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
    }
  }
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(1rem * calc(1 - 0));
  margin-bottom: calc(1rem * 0);
}

.budgets-wrapper {
  width: 100%;
  height: 100%;
  .infinite-scroll-component {
    display: flex;
    row-gap: 24px;
    column-gap: 24px;
    flex-wrap: wrap;
    height: 100%;
    width: 100% !important;
  }
}

.no-data {
  padding: 0 !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  .textHeader {
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    margin: 5px 0;
  }
  .subHeaderText {
    font-size: 15px;
    line-height: 20px;
    color: #586068;
  }
}
