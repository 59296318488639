.create-form {
  height: calc(91vh - 6rem);
}

.category-table {
  .table-wrapper {
    .table-responsive {
      max-height: calc(100vh - 17.5rem) !important;
    }
  }
}

.view-category-table {
  .table-wrapper {
    .table-responsive {
      max-height: calc(100vh - 24rem) !important;
    }
  }
}

.modal-dialog {
  max-width: 592px !important;
}

// I gave a width of 100% so that i can give each cell a width that adds up to 100%
.reactgrid {
  width: 100% !important;
}

.reactgrid-content {
  width: 100% !important;
}

.rg-pane {
  border-radius: 20px 0 20px 0;
}

// This is used to style the header of the table (Not an actual table but a bunch of divs)
.reactgrid-content .rg-pane .rg-header-cell {
  color: #79716b !important;
}

.reactgrid-content .rg-pane .rg-header-cell:first-child {
  border-radius: 10px 0px 0px 0px;
}

.reactgrid-content .rg-pane .rg-header-cell:nth-child(4) {
  border-radius: 0px 10px 0px 0px;
}

// This style applies to all cells including the header cells
.reactgrid-content .rg-pane .rg-cell.rg-cell-custom {
  white-space: normal !important;
  padding: 5px 12px;
  font-size: 14px !important;

  .ant-checkbox-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.reactgrid-content .rg-pane .rg-cell {
  cursor: text;
}

// This style applies to all cells including the modify button
.reactgrid-content .rg-pane .rg-cell.rg-modify-cell {
  justify-content: center;
}

.reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #3579f820 !important;
}

// .rg-celleditor {
//   border: none !important;
//   padding: 5px 12px !important;
// }

.rg-celleditor {
  border-color: #3579f820 !important;
}

.rg-celleditor .rg-input {
  font-size: 14px !important;
}

.rg-celleditor .custom-rg-input {
  font-size: 14px !important;
  border: none !important;
  padding: 0 !important; /* Remove padding */
  margin: 0 !important; /* Remove margin */
  line-height: 1.5 !important; /* Adjust line height if needed */
  box-sizing: border-box !important; /* Ensures padding doesn't affect the size */
  overflow: hidden !important;
  resize: none !important;
  display: block !important;

  &:focus {
    border: none !important;
    outline: none !important;
  }
}

.rg-celleditor.rg-customDropdown-celleditor .custom-rg-input {
  width: 85%;
}

.rg-celleditor.rg-customText-celleditor .custom-rg-input {
  width: 100%;
}

.react-grid-custom-container {
  overflow-y: auto; /* or scroll */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.react-grid-custom-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
}
