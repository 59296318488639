.counter {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 20px;
  padding: 4px;
  width: fit-content;

  &__button {
    background: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 24px;
    height: 24px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(0.9);
    }

    &.not-clickable {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.clickable {
      border: 1px solid #d8d8d6;
      box-shadow: 0px -0.8px 0.8px 0px #00000033 inset, 0px 0.4px 0.4px 0px #00000014,
        0px 1px 2px 0px #0000001f;
    }
  }

  &__value {
    margin: 0 15px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }
}
