.auth-form-wrapper {
  margin: 0px auto;
  max-width: 600px;
  min-height: min-content;
  padding-bottom: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  background-color: #fff;
  padding-top: 60px;

  .contents {
    max-width: 380px;
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    .contents {
      margin: 0px 30px !important;
    }
  }
}

.form-header {
  margin-bottom: 26px;

  h1 {
    font-size: 28px !important;
    line-height: 32px !important;
  }

  h4 {
    font-size: 14x !important;
    line-height: 26px !important;
  }
  @media screen and (max-width: 480px) {
    h1 {
      font-size: 22px !important;
      line-height: 28px !important;
    }

    h4 {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
}
