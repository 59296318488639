.select-wrapper {
  width: 100%;
  position: relative;

  label {
    font-size: 0.875rem;
    color: #44403c !important;
    font-weight: 500;
    cursor: pointer;
    margin: unset !important;
    margin-bottom: 0.5rem !important;
  }

  .select {
    width: 100%;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin-top: 5px;
    z-index: 1;
    max-height: 225px;
    overflow-y: scroll;

    .options {
      width: 100%;
      display: flex;
      padding: 0 16px;
      height: 44px;
      justify-content: space-between;
      align-items: center;
      transition: 0.6s ease-in-out all;

      span {
        padding: 8px 0;
      }

      input {
        padding-left: 10px;
        background: #b9d6f1;
        border: none;
        height: 44px;
        width: 150px;
      }

      input:focus {
        outline: none;
      }
    }

    .option-action {
      display: none;
      gap: 20px;

      span {
        cursor: pointer;
        margin: 0;
        padding: 0;
      }
    }

    .options:hover {
      background-color: #f2f4f1;
      cursor: pointer;
    }

    .selected {
      background-color: #f2f4f1;
    }

    .newCategory-bg {
      background-color: #f2f4f1;
    }

    .options:hover .option-action {
      display: flex;
    }
  }
}

.selectInput-wrapper {
  width: 100%;
  position: relative;

  input {
    height: 40px;
    font-size: 0.875rem !important;
    box-shadow: 0px 1px 2px 0px #1c19170d !important;
    border-radius: 8px;

    &:disabled {
      background: #f5f5f4;
      color: #d7d3d0;
      box-shadow: none;
    }

    &::placeholder {
      font-size: 0.875rem;
    }
  }

  svg {
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
  }

  .loading-style {
    font-size: 10px;
    position: absolute;
    top: 20px;
    right: 50px;
  }
}
