$width-drawer: 520px;
$font-semibold: 600;
$font-medium: 500;
$font-bold: 700;

$size-base: 16px;
$size-xs: 12px;
$size-sm: 14px;
$size-lg: 18px;
$size-xl: 20px;
$size-ds-xs: 24px;
$size-ds-sm: 30px;
$size-ds-md: 36px;

$hover-color: #fafaf9;

//this is subject to change
$margin-sm: 32px;
$margin-md: 48px;

@mixin padding($top, $right, $bottom, $left) {
  padding: $top $right $bottom $left;
}

@mixin margin($top, $right, $bottom, $left) {
  margin: $top $right $bottom $left;
}

@mixin fixedTo($position, $value) {
  position: fixed;

  @if $position == 'bottom' {
    bottom: $value;
  }
}

@mixin borderStyle($position, $weight, $style, $color) {
  @if $position == 'top' {
    border-top: $weight $style $color !important;
  }

  @if $position == 'full' {
    border: $weight $style $color !important;
  }
}

@mixin shadow($type) {
  @if $type == 'xs' {
    box-shadow: 0px 1px 2px 0px rgba(28, 25, 23, 0.05);
  }
}
