.chartWrapper {
  border: 1px solid #e5e8e3;
  margin-top: 30px;
  padding: 0 20px 20px;

  .light {
    line {
      stroke: #e9e9e9;
    }
  }

  .relative {
    position: relative;
  }

  .center-data {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;

    span {
      background: #fff;
      padding: 8px 8px;
    }
  }

  .chart-header {
    display: flex;
    justify-content: space-between;

    .chartActon {
      display: flex;
      align-items: center;
      padding-top: 30px;
      padding-right: 20px;
      height: max-content;

      .chartActon-link {
        background: none;
        border: none;
        padding: 2px 12px;
        color: #586068;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:first-child {
          border-right: 1px solid #e5e8e3;
        }

        &.active {
          color: #000;
        }
      }
    }
  }
}

.overview-top {
  .page-title {
    line-height: 38px;
    span {
      color: #d28b28;
    }
  }

  .sub-text {
    color: #7f7f7d;
    margin-bottom: 0;
  }
}

.total-amount-wrapper {
  padding-bottom: 1rem;

  .card-type-title {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }

  .card-disabled-title {
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    color: #586068;
  }

  .card-title-amount {
    font-size: 24px;
    line-height: 35px;
    font-weight: 600;
    margin: 8px 0 5px;
    display: flex;
  }
}

.transactions-wrapper {
  margin-top: 30px;

  .pt-0 {
    padding-top: 0 !important;
  }

  .subtitle {
    font-size: 16px;
    color: #586068;
    position: absolute;
    bottom: -30px;
  }
}

.rdrDefinedRangesWrapper {
  display: none;
}

.card-disabled-link {
  color: #d28b28;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
}

.custom-tooltip {
  padding: 12px;
  border: 1px #5860685f solid;

  .label-data {
    color: #d28b28;
    margin: 0;

    span {
      margin-right: 4px;
    }
  }

  .label {
    margin: 0 0 6px 0;
  }
}

.balance-card {
  border-bottom: 1px solid #e5e8e3 !important;
  @media (min-width: 1000px) {
    border-bottom: 0px solid #e5e8e3 !important;
    border-right: 1px solid #e5e8e3 !important;
  }
}
