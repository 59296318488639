.actionsIcon {
  font-size: 20px;
  padding-right: 5px;
  position: relative;
  top: -4px;
}
.budget-overview-amount {
  * {
    color: #586068;
  }
  &.dark {
    * {
      color: #000000;
    }
  }
  p {
    font-weight: 400;
    font-size: 15px;
    margin: 0;
  }
  h2 {
    font-size: 20px;
    margin: 0;
  }
}

.mt-200 {
  margin-top: 200px;
}
