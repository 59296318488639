$width: 97mm;
$ratio: 1.586;

.card-bg {
  width: 100%;
  height: 218px;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 20px;
  margin: auto;

  :hover {
    cursor: pointer;
  }

  // &.isActive {
  //   background: linear-gradient(180deg, #d7ff66 0%, #bfff0b 100%);
  // }
  &.borderActive {
    border: 2px solid green;
  }

  .card-name {
    text-align: right;

    p {
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
      padding-top: 5px;
      line-height: 0.8;
    }

    span {
      padding: 0 9px 6px 15px;
    }
  }

  small {
    font-size: 12px;
  }

  h2 {
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
  }
}

.close-text {
  color: #57534e;
  font-weight: 500;
  font-size: 0.875rem;
}

.name-holder {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.5rem;
    margin-right: auto;
    color: #1c1917;
    font-weight: 600;
  }
}

.modal-card {
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 55px !important;
    object-fit: contain;
  }
}

.modalBadge {
  .badge-wrapper {
    .badge-inherit {
      font-size: 11px;
    }
  }
}

.ml-2 {
  margin-left: 4px !important;
}

.cards-button {
  padding: 10px 16px !important;
  font-size: 14px !important;
  height: 45px !important;
}

.transaction-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.success {
  background: #d1fadf;
}

.pending {
  background: #fee4e2;

  svg {
    transform: rotate(180deg);

    path {
      stroke: #f79009;
    }
  }
}

.card-footer {
  padding-top: 30px;
  background-color: white;
  justify-content: flex-end;
  padding: 16px 40px;
  position: fixed;
  width: 500px;
  right: 0px;
}

.pin-footer {
  bottom: 20px;
}

.selectedCard {
  bottom: 12px;
}

.footer__action {
  display: flex;
  align-self: flex-end;
  gap: 12px;
  padding: 0 40px;

  .customButton {
    padding: 8px 18px !important;
    height: 48px;
    font-size: 16px;
  }

  .cancel {
    color: #586068 !important;
    border: 1px solid #e5e8e3 !important;
    background-color: white !important;
    box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05) !important;

    &:hover {
      color: #586068 !important;
    }
  }
}

.card-region-holder {
  height: calc(100vh - 10rem);
  overflow: auto;
}

.card-holder {
  border-radius: 12px;
  background: #fafaf9;
  padding: 1rem;
  border: 1px solid #e7e5e4;
  box-shadow: 0px 1px 2px 0px #1c19170d;

  .budget-region {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.875rem;

    .text {
      color: #79716b;
      font-weight: 500;
      margin-right: auto;
    }

    a {
      text-decoration: none;
    }
  }
}

.pan-details {
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  display: flex;
  height: 25px;
  // align-items: center;

  .caption {
    color: #79716b;
  }

  .value {
    color: #1c1917;
    font-weight: 500;
    margin-left: auto;
  }
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e5e4;
  padding-bottom: 1.5rem;
  gap: 4px;
}

.transactions-holder {
  .caption {
    display: block;
    color: #1c1917;
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
  }

  .transaction {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .description {
      display: flex;
      align-items: center;
      margin-right: auto;
      gap: 12px;

      .icon {
        width: 40px;
      }
    }

    .text {
      color: #1c1917;
      font-weight: 500;
      font-size: 0.875rem;
      display: block;
    }

    .sub-text {
      color: #79716b;
      font-size: 0.75rem;
      display: block;
      padding-top: 2px;
    }
  }
  .no-record {
    color: #79716b;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    padding: 1.25rem 0;
  }
}

.details-btn {
  border: 1px solid #d7d3d0;
  background: inherit;
  border-radius: 8px;
  height: 32px;
  width: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  color: #57534e;
  font-weight: 500;
  font-size: 0.875rem;
  gap: 8px;
  transition-duration: 300ms;

  &.large {
    height: 40px;
  }

  &:active {
    opacity: 0.6;
  }
}
