.bvn-request-wrapper {
  height: 100vh;

  header {
    padding: 0 40px;
    height: 70px;
    background: #f2f4f1;
  }

  .rhs span {
    color: #586068;
    font-size: 12px;
  }

  form,
  .reason,
  .otp-container {
    width: 393px;
    margin: 0 auto;
    padding-top: 82px;
  }
  .reason {
    padding-top: 48px;
  }
  .otp-container {
    padding-top: 130px;
  }

  form h4,
  .otp-container h4 {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #000000;
    margin-bottom: 50px;
  }
  .otp-container {
    width: 571px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .otp-container input,
  .otp-container input:not(:focus) {
    width: 64px !important;
    height: 64px !important;
    background: #f2f4f1;
    border: 1px solid #e5e8e3;
    box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05);
    border-radius: 8px;
    font-size: 48px;
    line-height: 60px;
  }

  .otp-container input:active,
  .otp-container input:focus,
  .otp-container input[type='text' i]:focus-visible {
    background: #ffffff !important;
    border: 2px solid #d28b28;
    box-shadow: 0px 1px 2px rgba(36, 38, 40, 0.05);
    border-radius: 8px;
    text-align: center;
    color: #d28b28;
  }
  :focus-visible {
    outline: #d28b28 auto 2px;
  }
  .otp-container input:valid {
    color: #d28b28;
  }
  .otp-container input:not(:placeholder-shown) {
    border: 2px solid #d28b28;
    background: #ffffff !important;
  }
  .otp-container section {
    margin-top: 48px;
    margin-bottom: 60px;
  }
  .otp-container section div {
    gap: 8px;
  }
  .otp-container h4 {
    margin-bottom: 16px;
  }
  .otp-container p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #586068;
  }

  .bvn-btn,
  .bvn-btn:focus {
    height: 40px;
    width: 95px;
  }

  .ghost {
    background-color: transparent !important;
    border: 1px solid #e5e8e3 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #586068 !important;
  }
  .ghost:hover {
    background-color: transparent !important;
    color: #586068 !important;
  }

  form .top {
    margin-top: 20px;
  }

  .text-right {
    float: right;
  }

  .reason {
    margin-top: 96px;
    padding: 20px;
    background: #fff5ea;
    border: 1px solid #e1b878;
    border-radius: 8px;
  }
  .reason h1 {
    font-weight: 600;
    font-size: 17.1375px;
    line-height: 27px;
    color: #242628;
    margin: 0;
    padding: 0;
  }
  .reason p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #586068;
    padding-top: 16px;
  }

  .reasons-list {
    margin-top: 16px;
  }

  .reason-item span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #586068;
  }

  .success-container {
    margin-top: 48px;
  }
  .success-container div div {
    margin-top: 0 !important;
  }

  .success-container p {
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #586068;
  }
}
