@import '../../../scss/typography.scss';
@import '../../../scss//base/colors.scss';
@import '../../../scss//abstracts/variables.scss';

.policy-type {
  width: 100%;
  background-color: $grey-100;
  padding: $size-base;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  .type,
  .value {
    font-size: $size-sm;
    margin: 0;
    padding: 0;
  }
  .value {
    margin-top: 4px;
  }
  .policy-action {
    span {
      font-size: $size-xs;
      cursor: pointer;
    }
    span:first-child {
      color: rgba(88, 96, 104, 1);
    }
    span:last-child {
      color: rgba(210, 40, 40, 1);
    }
  }
}

.policy-types-selector {
  .amount-icon {
    left: 15px;
  }
}

.policy-form {
  .add-more {
    width: 40px;
    height: 40px;
  }
  .amount-icon {
    .amount-sign {
      margin-top: -5px !important;
    }
  }
}

.popover-actions-dialog {
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 5px 0;
  // margin-top: 50px;
  border: 1px solid gainsboro;
  max-height: 300px;
  overflow-y: scroll;
  min-width: 240px;

  &.isInput {
    margin-top: 7px;
    margin-left: -17px;
  }

  .noLink {
    padding: 10px 25px;
    font-size: 14px;
    font-weight: 500;
  }

  .actionLink {
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      background: whitesmoke;
    }

    span {
      padding: 0 10px 0 5px;
    }
  }

  .svg-danger {
    svg {
      margin-right: 8px;
    }

    svg path {
      stroke: red;
    }
  }
}

.policy-fileupload {
  height: auto !important;
  padding: 32px !important;

  .uploadFileText1 {
    font-weight: 500;
    color: #1c1917;
  }

  .uploadFileTextBorder {
    height: auto;
  }
}

.upload-error {
  color: #d92d20;
  font-size: 12px;
  cursor: pointer;
}

.upload-error-border {
  border-color: #d92d20;
}
