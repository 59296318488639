@import '../../../../scss/base/colors.scss';
@import '../../../../scss/typography.scss';
@import '../../../../scss/abstracts/variables.scss';

.withdraw {
  .form-normal {
    margin-top: 32px;
    .amount-icon {
      left: 15px;
    }
  }
  .amount-info {
    font-weight: $font-semibold;
    font-size: $size-sm;
    display: flex;
    gap: 4px;
    margin-top: 8px;
    .total-amount {
      font-style: normal;
      color: $secondary-500;
      cursor: pointer;
    }
  }
}
