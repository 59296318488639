.region-container {
  padding: 0 112px;
}

.back-action {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  gap: 4px;

  a {
    color: #d28b28;
    text-decoration: none;

    &:hover {
      color: #d28b28;
      opacity: 0.75;
    }
  }
}

.entity-header {
  height: 72px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e5e4;

  .contact {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      color: #79716b;
      font-size: 0.875rem;
    }

    a {
      text-decoration: none;
    }

    .contact-btn {
      border: 1px solid #d7d3d0;
      padding: 1rem;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: #fff;
      animation-duration: 300ms;
      font-size: 0.875rem;
      gap: 8px;

      span {
        color: #57534e;
        font-weight: 500;
      }

      &:hover {
        opacity: 0.75;
      }

      &:active {
        opacity: 0.6;
        outline: none;
      }
    }
  }
}

.form-wrapper {
  margin-top: 36px;
  width: 45%;

  .header {
    color: #1c1917;
    font-size: 1.125rem;
    font-weight: 600;
    padding-bottom: 20px;
  }

  form {
    .form-label {
      color: #44403c !important;
      font-weight: 500 !important;
      font-size: 0.875rem !important;
    }

    .inner-holder {
      border: 1px solid #e7e5e4;
      border-width: 1px 0 1px 0;
      padding: 12px 0;
    }

    .submit-btn {
      height: 40px;
      width: 107px;
      font-size: 0.875rem !important;
    }
  }
}

.multi-entity__control {
  min-height: unset !important;
  border-radius: 8px !important;
  padding: 0 3px !important;
  cursor: pointer !important;
  border: 1px solid #d7d3d0 !important;
  height: 40px;
  box-shadow: 0px 1px 2px 0px #1c19170d;

  &.multi-entity__control--is-focused {
    box-shadow: 0 0 0 0.25rem rgba(36, 38, 40, 0.1294117647) !important;
  }

  .multi-entity__indicator {
    transition: transform 0.3s ease-in-out;
  }

  &.multi-entity__control--menu-is-open .multi-entity__indicator {
    transform: rotateX(180deg);
  }

  .multi-entity__single-value {
    color: #1c1917;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .multi-entity__placeholder {
    color: #a9a29d !important;
    font-size: 0.875rem;
  }

  .multi-entity__input {
    color: #1c1917;
    font-size: 0.875rem;
  }
}
