@import '../../scss/abstracts/variables.scss';

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.dashboard-section {
  .dashboard-title-highlight {
    background-color: #bfff0b;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    line-height: 0;
    border-radius: 50%;

    .dashboard-user-later {
      position: relative;
      top: -2px;
    }
  }

  .dashboard-details {
    .active-text {
      font-weight: 600;
      font-size: 45px;
    }

    .sub-active-text {
      font-weight: 400;
      font-size: 45px;
      line-height: 50px;
    }
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    color: #586068;

    span {
      margin-right: 5px;
    }

    .progress__line {
      background: #000;
      height: 8px;
      width: 60%;
      margin-right: 10px;
      background: #e6e8e3;
      position: relative;
      overflow: hidden;
      border-radius: 16px !important;

      div {
        position: absolute;
        height: 8px;
        top: 0;
        left: 0;
        background: #d28b28;
      }
    }
  }
}

.anticon {
  vertical-align: 0;
}

.page-wrapper {
  .overview-action {
    display: flex;
    list-style: none;
    align-items: center;

    li {
      padding: 5px 18px;
      border-right: 1px solid #d9ddd5;
      display: flex;
      align-items: center;

      &:last-child {
        border-right: 0;
        padding-right: 0;
      }
    }
  }

  .border-full.transactions-wrapper {
    table {
      tr:last-child {
        td {
          border-bottom: 0 !important;
        }
      }
    }
  }

  .overview-data {
    display: grid;
    grid-template-columns: 33.32% 33.32% 33.32%;

    .total-amount-wrapper {
      border: 1px solid #e6e8e3;
      border-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:last-child {
        border-right: 1px solid #e6e8e3;
        border-end-end-radius: 12px !important;
        border-top-right-radius: 12px !important;
      }

      &:first-child {
        border-right: 1px solid #e6e8e3;
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
      }
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: 100%;

      .total-amount-wrapper {
        border-right: 1px solid #e6e8e3 !important;
      }
    }
  }
}

.arrow-wrapper.active {
  background: #bfff0b;
}

.arrow-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background: #d9ddd5;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-wrapper-percentage.active {
  background: #ecfdf3;
  border: 1px solid #a6f4c5;
}

.arrow-wrapper-percentage {
  height: 24px;
  border-radius: 16px;
  padding: 0px 8px;
  background: #d9ddd5;
  font-size: 14px;
  line-height: 0px;
  color: #027a48;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions-dialog {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11111;
  background: #ffffff;
  box-shadow: 0px 4px 6px -2px #1c191708, 0px 12px 16px -4px #1c191714 !important;
  border-radius: 12px;
  padding: 5px 0;
  margin-top: 10px;
  border: 1px solid #e8e8e7 !important;
  max-height: 420px;
  overflow-y: scroll;
  min-width: 240px;

  &.isInput {
    margin-top: 7px;
    margin-left: -17px;
  }

  &.overview {
    top: -36px;
  }

  .actionLink {
    padding: 10px;
    margin: 0 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-family: 'Inter', sans-serif !important;

    &:hover {
      background: whitesmoke;
    }

    span {
      padding: 0 10px 0 5px;
    }
  }

  .svg-danger {
    svg {
      margin-right: 8px;
    }

    svg path {
      stroke: red;
    }
  }
}

.link-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;

  .not-active {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }

  .icon-btn {
    font-size: 15px;
    background: #d9ddd5;
    margin: 4px;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.selectFundsWrapper.send-funds {
  margin-top: unset;

  .caption {
    color: #1c1917;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .sub-text {
    color: #79716b;
    font-size: 0.875rem;
  }

  .select-fund-type {
    padding: 20px 0;
    border-bottom: 1px solid #e5e8e3;
    cursor: pointer;

    &:last-child {
      border: 0px;
    }

    span {
      padding-left: 30px;
    }
  }

  .cardWrapper {
    margin-top: 32px;

    .active-card-bg {
      box-shadow: 0 3px 3px #e7e7e7;
      background: #e6e8e34f;
    }

    .balance-card-wrapper {
      border: 1px solid #e7e5e4;
      border-radius: 12px;
      margin-bottom: 24px;
      padding: 20px;
      align-items: center;
      display: flex;
      cursor: pointer;
      transition: cubic-bezier(0.445, 0.05, 0.55, 0.95), 0.4s ease;

      .chevron {
        transition: 0.3s ease;
      }

      &:hover {
        transform: scale(1.02);

        .chevron {
          transform: translateX(5px);
        }
      }

      .balance-title {
        font-weight: 600;
        font-size: 0.875rem;
        color: #0d0d0c;
        line-height: unset;
      }

      .balance-tag {
        font-weight: 400;
        font-size: 8px;
        line-height: 12px;
        text-align: center;
        color: #ffffff;
        background: #d28b28;
        border-radius: 50px;
        padding: 3px 8px;
        margin-left: 12px;
      }

      .dark-bg {
        background: #586068 !important;
      }

      .balance-sub-title {
        color: #7f7f7d;
        font-size: 0.875rem;
      }

      &:hover {
        background-color: $hover-color;
      }
    }
  }
}

.line-text {
  position: relative;

  .text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background: #fff;
    padding: 12px;
    z-index: 5;
    color: #586068;
    font-size: 13px;
    line-height: 15px;
  }
}

.balance-tag.dark-bg {
  background: #586068 !important;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  border-radius: 50px;
  padding: 3px 8px 4px;
  margin-left: 12px;
}

.disabled-link {
  cursor: not-allowed !important;
}

.dialog-fund-action {
  text-align: center;
  margin-top: 40px;

  .select-fund-type {
    cursor: pointer;

    .icon-style {
      width: 30px;
    }

    span {
      padding-left: 15px;
    }
  }
}

.cardInfo-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
  }
}

.overlaying-icons-wrapper {
  padding: 0;
  display: flex;
  align-items: center;

  .counter {
    background: #e5e8e3 !important;

    .icon-name {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .icon-wrappers {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 3px solid #fff;
    display: flex;
    align-items: center;
    background: #bfff0b;
    justify-content: center;
    margin: 0 -5px;
  }
}

.totalbalance-step {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 15px;
  font-size: 12px;
  color: #586068;
  // position-absolute top-0
}

.open-box-announcement {
  background-color: #121212;
  background-image: url('../../assets/images/b-lines.png');
  background-repeat: no-repeat;
  background-position: right;
  color: white;
}

.pinky {
  background-color: #fbf5ec !important;
  color: black !important;
}

.black-lines {
  background-image: url('../../assets/images/lines-black.png') !important;
}

.card-title {
  border: none;
}

.bjt-col-md-4 {
  @media screen and (min-width: 520px) {
    width: 31.5% !important;
  }
  margin-right: 1.8333333%;
  margin-bottom: 1.833333%;

  :nth-child(3n) {
    margin-right: 0px;
  }
}

.decimal-part {
  color: #a9a29d;
}

.account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e7e5e4;
  border-radius: 12px;
  padding: 24px 20px !important;
  cursor: pointer;
  height: 88px;
  // width: 48.5% !important;
  transition: ease 500ms;

  &.is-expanded {
    border-bottom: none;
    border-radius: 12px 12px 0 0;
  }

  &:hover:not(.is-expanded) {
    margin: 0px 6px;
    transform: scale(1.01);
    border: 1px solid #edff9d;
    background-color: #faffec;
    box-shadow: 0px 0.59px 1.44px 0px #0000002e, 0px 3.3px 4.45px 0px #0000001f,
      0px 8.66px 10.64px 0px #00000019;
    border-radius: 8px;

    .icon-slide {
      opacity: 1;
      transform: translateX(0);
    }

    @include mobile {
      margin: unset;
      transform: scale(1);
    }

    .account-number {
      color: #7f7f7d;
    }

    .account-badge {
      width: 88px;
      padding: 4px 4px;

      span:not(.dd-dot) {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .icon-slide {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0.2s;
    }

    .card-info > article > .account-name > .account-actions {
      display: flex !important;
    }
  }

  .account-badge {
    display: flex;
    align-items: center;
    gap: 4px;
    background: #eff8ff;
    border-radius: 16px;
    border: 1px solid #b2ddff;
    padding: 4px;
    overflow: hidden;
    width: 16px;
    height: 16px;
    color: #175cd3;
    font-size: 0.75rem;
    font-weight: 500;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    .dd-dot {
      min-width: 6px;
      height: 6px;
      background: #2e90fa;
      border-radius: 100%;
    }

    span:not(.dd-dot) {
      white-space: nowrap;
      opacity: 0;
      transform: translateX(-10px);
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  .icon-slide {
    opacity: 0;
    width: 30px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    bottom: 1px;
    transform: translateX(-10px);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .account-balance {
    color: #1c1917;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 20px;

    &.has-custom-icon {
      margin-right: 10px;
    }
  }

  .account-name {
    color: #1c1917;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;

    @include mobile {
      flex-wrap: wrap;

      .name {
        max-width: 100% !important;
      }
    }

    .name {
      width: fit-content;
    }
  }

  .account-number {
    color: #a9a29d;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .account-logo {
    padding: 4.5px;
    height: inherit;
    width: inherit;
    object-fit: contain;
    border-radius: 100%;
  }

  .card-info {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }

  @media (max-width: 768px) {
    padding: 12px !important;
    width: 100% !important;
    height: fit-content !important;
    flex-direction: column !important;
    align-items: start;

    .card-info {
      flex-direction: column !important;
    }

    .custom-icon {
      position: absolute;
      right: 20px;
      top: 26px;
    }
  }
}

.grid-cols-2 {
  display: grid !important;
  gap: 1.5rem !important;
  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

.grid-cols-3 {
  display: grid !important;
  gap: 1.5rem !important;
  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}
.account-action-item {
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: #f5f5f4;
  margin-right: 12px;
  background-color: #f5f5f4;

  svg {
    color: #79716b;
  }

  &:hover {
    background-color: #e7e5e4;
  }
}

.back-button {
  margin-bottom: 1.5rem;
}
