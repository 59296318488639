.rotate-icon {
  transition: transform 0.5s ease;
}

.rotate-icon-active {
  transform: rotateX(180deg);
}

.rotate-icon-active-plus {
  transform: rotate(180deg);
}

.fade-in {
  animation: fadeInUp 0.5s ease-in-out;
}

.fade-in-up {
  animation: fadeInUp 0.3s ease-in-out;
}

.fade-in-fast {
  animation: fadeIn 0.5s ease-in-out;
}

.fade-out {
  animation: fadeOut 0.75s ease-in-out forwards;
}

.icon-animate {
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scaleY(0.95);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/** FLIP ANIMATIONS **/

@keyframes flip-down {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes flip-up {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0);
  }
}

.flip-down {
  animation: flip-down 0.4s ease forwards;
}

.flip-up {
  animation: flip-up 0.4s ease forwards;
}

// Animated Ellipsis
.dots {
  width: 24px;
  display: inline-block;
}

.dots::after {
  content: '...';
  animation: dots 1.5s steps(4, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    content: '';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%,
  100% {
    content: '...';
  }
}

// ************

.chevron-arrow {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  bottom: -8px;
  transition: 0.3s ease;
  transform: rotate(45deg);

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 7px;
    height: 1.25px;
    border-radius: 24px;
    background-color: #79716b;
    transition: 0.3s ease;
  }

  &:before {
    left: 0px;
  }

  &:after {
    top: -3.7px;
    left: 3.7px;
    transform: rotate(90deg);
  }
}

.chevron-wrapper {
  right: 2px;
  top: 2px;
  position: relative;
  &.active {
    .chevron-arrow {
      transform: rotate(45deg) translate(-5px, -5px);

      &:before {
        left: 0.75px;
        transform: translate(6.5px, 0);
      }

      &:after {
        top: -3.3px;
        left: 4px;
        transform: rotate(90deg) translate(7.1px, 0);
      }
    }
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-15%);
    opacity: 0;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutToTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(-5%);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    border-width: 0px;
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.1);
    border-color: #e98ba1;
  }
  50% {
    border-width: 1px;
    box-shadow: 0 0 0 7px rgba(255, 0, 0, 0.1);
    border-color: #cd0130;
  }
  75% {
    border-width: 0.6px;
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.1);
    border-color: #f06c89;
  }
  100% {
    border-width: 0.6px;
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.1);
    border-color: #e98ba1;
  }
}

@keyframes float-ball {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(-10px);
  }
}

@keyframes fill-pill {
  from {
    width: 0%;
  }
  to {
    width: var(--percentage-width);
  }
}

@keyframes glideIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  70% {
    transform: translateY(10%);
    opacity: 1;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

//
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  60% {
    transform: translateX(1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  60% {
    transform: translateX(-1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in-left {
  animation: slideInLeft 0.6s ease forwards;
}

.slide-in-right {
  animation: slideInRight 0.6s ease forwards;
}

.animate-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-on-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}

@keyframes springUpBounce {
  0% {
    transform: translateY(20px);
  }
  60% {
    transform: translateY(-5px);
  }
  80% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-in-up {
  animation: slideInUp 0.6s ease forwards;
}

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  60% {
    transform: translateY(-1%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
