.card-title {
  font-size: 24px;
  width: fit-content !important;
}

.user-view-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border: 1px solid #e5e5e5;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;

  .userIcon {
    background: #bfff0b;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .useRole {
    background: #ecf3f9;
    border-radius: 35px;
    padding: 3px 15px 2px;
  }
}

.information-wrapper.fund-deposit {
  .search-box {
    margin-bottom: 12px;
    .input {
      height: 36px;
      border-radius: 12px;
      box-shadow: 0px 1px 2px 0px #1c19170d;

      &::placeholder {
        color: #7f7f7d;
      }
    }
  }

  .list-wrapper {
    overflow-y: auto;
    height: calc(100vh - 14rem);

    .tabinnerWrapper {
      min-height: calc(100vh - 24rem);
    }

    .details-holder {
      overflow: hidden;
      .segment {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0px;
        }

        .header {
          font-size: 0.75rem;
          color: #7f7f7d;
          font-weight: 500;
          margin-bottom: 4px;
        }

        .value {
          font-size: 0.875rem;
          color: #0d0d0c;
          font-weight: 500;
          margin-bottom: 0px;
          text-overflow: ellipsis;
        }

        .copy-item {
          display: flex;
          align-items: center;
          margin-left: auto;
          gap: 4px;

          &.colored {
            font-weight: 600;
            color: #d28b28;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}

.balance {
  .subHeaderText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #586068;
  }

  .cardWrapper {
    margin-top: 50px;

    &:hover {
      box-shadow: 0 3px 3px #e7e7e7;
      background: #e6e8e34f;
    }

    .balance-card-wrapper {
      border: 1px solid #e5e5e5;
      border-radius: 0.25rem;
      margin-bottom: 20px;
      padding: 20px 15px;
      align-items: center;
      display: flex;
      cursor: pointer;

      .balance-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }

      .balance-tag {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #ffffff;
        background: #d28b28;
        border-radius: 50px;
        padding: 3px 8px;
        margin-left: 12px;
      }

      .dark-bg {
        background: #586068 !important;
      }

      .balance-sub-title {
        color: #586068;
        font-size: 15px;
      }
    }
  }
}

.InfoBox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e8e3;
  padding: 20px 5px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 20px;
  }

  .information-title-wrapper {
    .information-title {
      font-size: 15px;
      line-height: 20px;
      color: #586068;
      margin: 0;
    }

    .information-value {
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      margin: 0;
    }
  }

  .copy-box {
    background: #f2f4f1;
    border-radius: 30px;
    padding: 5px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;

    svg {
      margin-left: 10px;
      margin-top: 3px;
    }
  }
}

.ref-id {
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 15px;
  color: #d28b28;
}

.subbusiness-section {
  font-size: 14px;

  .subbusiness-list {
    display: flex;
    align-items: center;

    .subbusiness__check-icon {
      margin-left: 6px;
      font-size: 14px;
    }
  }

  .text-orange {
    color: #d28b28;
  }

  .text-gray {
    color: #586068;
  }

  .cursor {
    cursor: pointer;
  }
}

.mail {
  background: #f2f4f1;
  padding: 4px;
  border-radius: 6px;

  .mail-icon {
    padding: 3px;
  }
}

.business-notice {
  background: #fbf5ec;
  width: 100%;
  padding: 16px;
  border-radius: 10px;

  h6 {
    font-size: 15px;
  }

  p {
    font-size: 15px;
  }

  .text-black:active {
    color: #000 !important;
    font-size: 16px !important;
  }
}

.disabled-tab {
  cursor: not-allowed !important;
  pointer-events: none !important;
}
