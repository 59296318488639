@import '../../styles/style-constants.less';

.page-wrapper {
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;

  &.grey {
    background-color: @backgroundColor;
    min-height: 100vh;
  }

  .buttonStyle {
    margin: 20px auto;
    width: 100% !important;
    max-width: 380px;

    .customButton,
    .customButton:focus {
      width: 100% !important;
      width: auto;
    }
  }

  .subHeader {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #586068;
  }
}

.page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.page-content.with-vit {
  width: calc(100% - 380px);
  margin: 0 auto;
}

.password-request {
  .auth-form-wrapper {
    height: 600px !important;
    .content {
      height: auto;
    }
  }
}

.vitrinesb-col {
  // width: 23%;
  width: 380px;
  background-color: @backgroundColor;

  img {
    margin-left: 40px;
    margin-bottom: 20px;
  }
}

.login-vitrine-img {
  width: 340px;
  margin-left: 0px !important;
}

.signup-vitrine-img {
  width: 70%;
}

.logoSpace {
  height: 150px !important;
}

.auth-header {
  width: 100%;
  height: 110px;
  display: flex;

  .logo {
    transform: scale(1.3);
    margin: 70px auto;
  }
}

.ant-form-item-label > label {
  font-size: 15px;
}

.submit-button {
  width: 100%;
  border-radius: 10px;
}

.back-line {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  width: fit-content;

  a {
    color: #57534e;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none !important;

    &:hover {
      text-decoration: none;
      color: #57534e;
    }
  }
}

.secondary-action {
  margin-top: 30px;
  text-align: center;
  margin-right: 2%;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
  font-weight: 400;

  a {
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    font-weight: 500;
  }
}

.verify-logo {
  margin-bottom: 30px;
}

.agreement {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
  span {
    font-size: 14px;
  }
  input {
    margin-right: 8px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 991px) {
  .vitrinesb-col {
    display: none;
  }

  .page-content.with-vit {
    width: 100%;
  }
}
