.balance-ui {
  line-height: 0;
  right: 0;
  top: 0;
  margin-right: 20px;
  font-size: 15px;
  margin-top: 20px;
}

.mr-3 {
  margin-right: 4px;
}

.mr-4 {
  margin-right: 8px;
}
