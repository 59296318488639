.card-wrapper {
  .card-title {
    font-size: 24px !important;
    width: fit-content !important;
  }
  .body {
    background: rgba(242, 244, 241, 0.5);
    border-radius: 8px;
    padding: 32px 16px;
    margin-top: 11px;
  }
  .divider {
    color: #e6e8e3;
    margin-bottom: 32px;
  }
  .title {
    margin-bottom: 0px;
    font-size: 15px;
    color: '#000';
  }
  .tel-style {
    color: black;
    &:hover {
      color: #d28b28;
    }
  }
}
