.transaction-wrapper {
  .content {
    height: 95vh;
  }
}
.transaction-icon {
  background: #f2f4f1;
  border-radius: 30px;
  padding: 3px 10px;
  cursor: pointer;
}
.removeIconBg {
  background: transparent;
}

.content {
  height: calc(100vh - 12rem);
  position: relative;
}

.edit-category {
  cursor: pointer;
  align-self: flex-start;
  color: #212120;
}
.card-modal-footer {
  margin-top: 32px;
}

.item-title {
  margin-bottom: 0px !important;
}
.item-value {
  display: flex;
  align-items: center;
  text-align: right;
  word-break: break-all;
  svg {
    margin-left: 8px;
  }
}

.item-size {
  font-size: 0.875rem;
}

.item-info {
  // gap: 64px;
  a {
    text-decoration: none;
    color: #212120;
    // text-decoration: underline;
    span {
      color: #212120;
    }
  }
}

.icon-hover {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;

  &:hover {
    opacity: 1; // Fade in
    pointer-events: auto;
  }
}

.parent-hover:hover .icon-hover {
  opacity: 1;
  pointer-events: auto;
}

.transaction-info-box {
  font-size: 12px;
  background-color: #fdead7;
  border-radius: 8px;
  &.box-spacing {
    margin: 24px 32px 0px 32px;
  }
  a {
    // text-decoration: underline !important;
    color: #212120 !important;
  }
}

.warning-info-box {
  font-size: 12px;
  border: solid 1px #e7e5e4;
  border-radius: 8px;
  &.box-spacing {
    margin: 15px 32px 0px 32px;
  }
  a {
    text-decoration: none !important;
    color: #1c1917 !important;
  }
}

.payment-schedule {
  width: 470px !important ;

  button {
    height: 36px !important;
    font-size: 0.875rem !important;
    padding: 0 16px !important;
    border-radius: 8px !important;
    cursor: pointer;

    &.cancel-button {
      background: inherit !important;
      color: #57534e !important;
      font-weight: 500 !important;
      border: 1px solid #d7d3d0 !important;
      box-shadow: none !important;
    }

    &.confirm-button {
      background: #000000;
      color: white;
      border: none;
    }

    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 0.6;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

.transaction-details {
  .details-tab {
    background: #fafaf9;
    // height: 100% !important;

    &.invoice {
      height: calc(100vh - 12rem);
    }

    .details-view {
      p {
        font-size: 14px;
        font-weight: 600;
        color: #5c5c5a;
        margin: 0;
        margin-bottom: 12px;
      }

      .container {
        border: 1px solid #e8e8e7;
        background: #fff;
        padding: 12px 20px;
        border-radius: 12px;
      }
    }
  }

  .account-logo {
    padding: 0.5px;
    height: inherit;
    width: inherit;
    object-fit: contain;
    border-radius: 100%;
  }

  .fixed-header {
    position: fixed;
    top: 50; /* Adjust as necessary */
    translate: 0 -6px;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure it is above other content */
    background: white; /* Match the background color */
  }
}
